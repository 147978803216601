import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useWeb3Modal, useWeb3ModalTheme } from '@web3modal/wagmi/react';

import {
  useAccount, useDisconnect, useSignMessage,
} from 'wagmi';
// import { verifyMessage } from 'ethers/lib/utils.js';
import { useDispatch, useSelector } from 'react-redux';
import { polygon } from 'wagmi/chains';
import { useParams } from 'react-router-dom';
import api from '../../../api.js';
import { getWindowSize } from '../../../helpers/getWindowSize.jsx';
import { setShowToast, setToastMessage, setToastStatus } from '../../../redux/slices/toastSlice';
import {
  selectUserData,
  setIsLogin, setLoginType, setShowLogin, setUserData,
} from '../../../redux/slices/accountSlice';

export function Wallets({
  wallets,
  setUserDatas,
  setSelectedItem,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { open: openWeb3Modal } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { isConnected, address } = useAccount();
  const signMessageText = import.meta.env.VITE_WALLET_MESSAGE;
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { username } = useParams();

  const userData = useSelector(selectUserData);

  const {
    signMessage, isSuccess, data, isError,
  } = useSignMessage();

  const [isUserRequestedAdd, setIsUserRequestedAdd] = useState(false);

  const matches = (windowSize?.innerWidth <= 972);
  const loggedInUserData = useSelector(selectUserData);

  const {
    setThemeMode,
  } = useWeb3ModalTheme();

  setThemeMode('light');

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  useEffect(() => {
    if (isSuccess) {
      const chainId = polygon.id;
      api.post('/profile/add-wallet', {
        address,
        signature: data,
        chainId,
      })
          .then((r) => {
            api.get(`/users/${loggedInUserData?.username}`).then((r) => {
              setUserDatas(r.data);
            });
          })
          .catch(({response}) => {
            disconnect();
            if (!response?.data?.message) {
              dispatch(setShowToast(true));
              dispatch(setToastStatus(0));
              dispatch(setToastMessage(intl.formatMessage({id: 'try_again'})));
            }
          });

    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      disconnect();
    }
  }, [isError]);

  useEffect(() => {
    if (isConnected && isUserRequestedAdd) {
      console.log(address);

      // const address = verifyMessage(variables.message, data);
      // const chainId = chain.id;

      signMessage({message: 'This message is a password'});
    }
  }, [isConnected]);



  // const deleteWallet = (id) => {
  //   api.delete(`/profile/remove-wallet/${id}`)
  //     .then(({ data }) => {
  //       // setWallets(data.wallets);
  //
  //       // setUserDatas(
  //       //     (prevState) => (
  //       //         {
  //       //           ...prevState,
  //       //           user: {
  //       //             ...prevState.user,
  //       //             walletAddresses: r.data.wallets,
  //       //           },
  //       //         }
  //       //     ),
  //       // );
  //     });
  // };

  // const {
  //   signMessage,
  // } = useSignMessage({
  //   onSuccess(data, variables) {
  //     const address = verifyMessage(variables.message, data);
  //     const chainId = chain.id;
  //     api.post('/profile/add-wallet', {
  //       address,
  //       signature: data,
  //       chainId,
  //     })
  //       .then((r) => {
  //         api.get('/profile').then((r) => {
  //           setUserDatas(r.data);
  //         });
  //       })
  //       .catch(({ response }) => {
  //         disconnect();
  //         if (!response?.data?.message) {
  //           dispatch(setShowToast(true));
  //           dispatch(setToastStatus(0));
  //           dispatch(setToastMessage(intl.formatMessage({ id: 'try_again' })));
  //         }
  //       });
  //   },
  //   onError(e, e2) {
  //     disconnect();
  //   },
  // });

  // const {
  //   data, error, isLoading, signMessage,
  // } = useSignMessage({
  //   onSuccess(data, variables) {
  //     console.log('sign variables ', variables);
  //     const chainId = polygon?.id;
  //     api.post('/profile/add-wallet', {
  //       address,
  //       signature: data,
  //       chainId,
  //     })
  //       .then((r) => {
  //         api.get('/profile').then((r) => {
  //           setUserDatas(r.data);
  //         });
  //       })
  //       .catch(({ response }) => {
  //         disconnect();
  //         if (!response?.data?.message) {
  //           dispatch(setShowToast(true));
  //           dispatch(setToastStatus(0));
  //           dispatch(setToastMessage(intl.formatMessage({ id: 'try_again' })));
  //         }
  //       });
  //   },
  //   onError(e, e2) {
  //     // dispatch(setShowCreatedModalAfterLogin(false));
  //     console.log('e: ', e, 'e2: ', e2);
  //     disconnect();
  //   },
  // });

  const connectWallet = async () => {
    await disconnect();
    setIsUserRequestedAdd(true);
    openWeb3Modal();
  };


  return (
    <>
      {wallets.map((adrs) => (
        <div
          key={adrs.id}
          className="walletItem"
          onClick={() => {
            setSelectedItem(adrs);
          }}
        >
          <img
            src={adrs.chain.logo}
            className="chainImg"
            alt=""
          />
          <p>
            {matches ? `${adrs.address?.slice(0, 4)}...${adrs.address?.slice(-4)}` : adrs.address}
          </p>
        </div>
      ))}
      <div className="addWalletButton" onClick={connectWallet}>
        <img className="addWallet" src="/icons/addWallet.svg" alt="" />
        {intl.formatMessage({ id: 'add_new_wallet' })}
      </div>
    </>
  );
}
