import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Gifts from './Gifts';
import api from '../../../../api';
import Skeleton from '../../general/Skeleton';
import ToPersianNumber from '../../../../helpers/toPersianNumber';
import Pagination from '../Pagination';
import { selectLanguage } from '../../../../redux/slices/languageSlice';

export default function GiftTab({
  sort, setTabContentLoading, tabContentLoading, userDatas,
}) {
  // const [sort, setSort] = useState('createdAt,DESC');
  const location = useLocation();
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);

  const [page, setPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [gifts, setGifts] = useState([]);
  const [endOffset, setEndOffset] = useState(0);
  // const [tabContentLoading, setTabContentLoading] = useState(false);

  useEffect(() => {
    setTabContentLoading(true);
    api.get('/profile/gifts', {
      params: {
        page,
        sort,
      },
    }).then(({ data }) => {
      setTabContentLoading(false);
      const newOffset = page !== 1 ? (page - 1) * 15 + 1 : 1;
      setItemOffset(newOffset);
      setGifts(data);
      setEndOffset(newOffset + data.moments.length - 1);
    }).catch(({ response }) => {
    });
  }, [page, sort, location]);

  const handlePageClick = (event) => {
    window.scrollTo(0, 0);
    setPage(event.selected + 1);
  };

  if (tabContentLoading) {
    return (
      <div className="giftTableWrapper">
        <table className="giftTable">
          <thead>
            <tr className="tableHead">
              <th>
                <div className="nftTh">
                  {intl.formatMessage({ id: 'nft' })}
                </div>
              </th>
              <th>{intl.formatMessage({ id: 'price' })}</th>
              <th>{intl.formatMessage({ id: 'username' })}</th>
              <th>{intl.formatMessage({ id: 'wallet_address' })}</th>
              <th>
                <div className="flexClass align-items-center">
                  <div>
                    {' '}
                    {intl.formatMessage({ id: 'date' })}
                  </div>
                  <img src="/icons/giftArrowDown.svg" alt="" />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="firstTd">
                <div className="nftTd flexClass align-items-center">
                  <Skeleton className="giftNftImage" />
                  <Skeleton style={{ width: 130, height: 21 }} />
                </div>
              </td>

              <td className="secondTd">
                <Skeleton style={{ height: 21, width: 100 }} className="priceTd">
                  0.023 ETH
                </Skeleton>
              </td>

              <td className="thirdTd">
                <Skeleton style={{ height: 21, width: 190 }} className="usernameTd flexClass blueColor align-items-center" />
              </td>

              <td className="forthTd">
                <Skeleton style={{ width: 100, height: 21 }} className="blueColor walletAddressTd" />
              </td>

              <td className="fifthTd">
                <Skeleton style={{ height: 21, width: 100 }} className="dateTd" />
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (gifts?.moments?.length === 0) {
    return (
      <div className="favoriteEmptySec">
        <div className="emptyCreatedSec">
          <div className="haveNotCreatedYet">
            {intl.formatMessage({ id: 'no_nft_to_show' })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Gifts gifts={gifts} pageLoading={tabContentLoading} />
      {
              tabContentLoading
                ? (
                  <div className="paginationSec">

                    <Skeleton style={{ height: 16, width: 160 }} className="showTitle" />
                    <Skeleton style={{ height: 28, width: 160 }} className="explorePagination" />
                  </div>
                )
                : gifts?.pages > 1
                  && (
                  <div className="paginationSec">
                    <div className="showTitle">
                      { `${intl.formatMessage({ id: 'showing' })} 
                             ${languageRedux === 'fa' ? ToPersianNumber(itemOffset) : itemOffset} 
                              ${intl.formatMessage({ id: 'to' })} 
                              ${languageRedux === 'fa' ? ToPersianNumber(endOffset) : endOffset} 
                               ${intl.formatMessage({ id: 'of' })} 
                               ${languageRedux === 'fa' ? ToPersianNumber(gifts.momentsCount) : gifts.momentsCount} 
                                ${intl.formatMessage({ id: 'entries' })}`}
                    </div>
                    <Pagination
                      handlePageClick={handlePageClick}
                      data={gifts}
                      page={page}
                    />
                  </div>
                  )
            }
    </>
  );
}
