import React, { useState } from 'react';
import SendOtpRequestLevel from './SendOtpRequestLevel';
import VerificationLevel from './VerificationLevel';

export default function EditValidation({
  show, setShow, inputs,
  setInputs,
  inputHandler,
  isPhone,
  user,
}) {
  const [isFirstLevel, setIsFirstLevel] = useState(true);

  const [timer, setTimer] = useState(120);
  const [isTimer, setIsTimer] = useState(false);

  return (
    <div className={show ? 'feed' : 'hideFeed'} style={{ width: '100%' }}>
      {
        isFirstLevel
          ? (
            <SendOtpRequestLevel
              setShow={setShow}
              isPhone={isPhone}
              inputs={inputs}
              setInputs={setInputs}
              inputHandler={inputHandler}
              setTimer={setTimer}
              setIsTimer={setIsTimer}
              setIsFirstLevel={setIsFirstLevel}
              user={user}
            />
          )
          : (
            <VerificationLevel
              setShow={setShow}
              isPhone={isPhone}
              inputs={inputs}
              timer={timer}
              setTimer={setTimer}
              setIsTimer={setIsTimer}
              isTimer={isTimer}
              setIsFirstLevel={setIsFirstLevel}
            />
          )
      }
    </div>
  );
}
