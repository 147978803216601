import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../api.js';
import { setEmailOrPhoneValidated } from '../../../../redux/slices/accountSlice';
import { selectLanguage } from '../../../../redux/slices/languageSlice';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import {setShowToast, setToastMessage, setToastStatus} from "../../../../redux/slices/toastSlice";

function TimerHandler({ timer, setTimer, setIsTimer }) {
  const languageRedux = useSelector(selectLanguage);
  if (timer !== 0) {
    setTimeout(() => {
      setTimer(() => timer - 1);
      if (timer === 1) {
        setIsTimer(false);
      }
    }, 1000);
    const minute = Math.floor(timer / 60);
    const second = Math.floor(timer % 60);
    return (
      <div className="timer">
        <img src="/icons/sportCategory/timer.svg" alt="" className="timerImg" />
        <div>
          {minute.toString().length === 1 ? `${languageRedux === 'fa' ? ToPersianNumber(`0${minute}`) : `0${minute}`}` : languageRedux === 'fa' ? ToPersianNumber(minute) : minute}
          :
          {second.toString().length === 1 ? `${languageRedux === 'fa' ? ToPersianNumber(`0${second}`) : `0${second}`}` : languageRedux === 'fa' ? ToPersianNumber(second) : second}
        </div>
      </div>
    );
  }
}

export default function VerificationLevel({
  setShow, isPhone, inputs, timer, setTimer,
  isTimer, setIsTimer, setIsFirstLevel,
}) {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);

  const [otpCode, setOtpCode] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
  });
  const [backSpace, setBackSpace] = useState(false);
  const [error, setError] = useState({
    hasError: false,
    errorText: '',
  });
  const [otpState, setOtpState] = useState('');

  const dispatch = useDispatch();

  const otpInputHandler = (e) => {
    if (error.hasError) {
      setError({
        hasError: false,
        errorText: '',
      });
    }
    if (e.target.name === '1') {
      if (!(isNaN(e.target.value)) && e.target.value.length <= 1 && e.target.nextSibling) {
        setOtpCode({
          ...otpCode,
          1: e.target.value,
        });
        setOtpState(otpCode['1']);
        if (!backSpace) {
          e.target.nextSibling.focus();
        }
      }
    } else if (e.target.name === '2') {
      if (!(isNaN(e.target.value)) && e.target.value.length <= 1 && e.target.nextSibling) {
        setOtpCode({
          ...otpCode,
          2: e.target.value,
        });
        setOtpState(otpCode['2']);
        if (backSpace && e.target.value.length > 0) {
          setOtpCode({
            ...otpCode,
            2: '',
          });
        }
        if (!backSpace) {
          e.target.nextSibling.focus();
        }
      }
    } else if (e.target.name === '3') {
      if (!(isNaN(e.target.value)) && e.target.value.length <= 1 && e.target.nextSibling) {
        setOtpCode({
          ...otpCode,
          3: e.target.value,
        });
        setOtpState(otpCode['3']);
        if (backSpace && e.target.value.length > 0) {
          setOtpCode({
            ...otpCode,
            3: '',
          });
        }
        if (!backSpace) {
          e.target.nextSibling.focus();
        }
      }
    } else if (e.target.name === '4') {
      if (!(isNaN(e.target.value)) && e.target.value.length <= 1 && e.target.nextSibling) {
        setOtpCode({
          ...otpCode,
          4: e.target.value,
        });
        setOtpState(otpCode['4']);
        if (backSpace && e.target.value.length > 0) {
          setOtpCode({
            ...otpCode,
            4: '',
          });
        }
        if (!backSpace) {
          e.target.nextSibling.focus();
        }
      }
    } else if (e.target.name === '5') {
      if (!(isNaN(e.target.value)) && e.target.value.length <= 1 && e.target.nextSibling) {
        setOtpCode({
          ...otpCode,
          5: e.target.value,
        });
        setOtpState(otpCode['5']);
        if (backSpace && e.target.value.length > 0) {
          setOtpCode({
            ...otpCode,
            5: '',
          });
        }
        if (!backSpace) {
          e.target.nextSibling.focus();
        }
      }
    } else if (e.target.name === '6') {
      if (!(isNaN(e.target.value)) && e.target.value.length <= 1) {
        setOtpCode({
          ...otpCode,
          6: e.target.value,
        });
        setOtpState(otpCode['1'] + otpCode['2'] + otpCode['3'] + otpCode['4'] + otpCode['5'] + e.target.value);
        if (backSpace && e.target.value.length > 0) {
          setOtpCode({
            ...otpCode,
            6: '',
          });
        }
      }
    }
  };

  const handlePaste = (e) => {
    let pastedData;
    if (!(isNaN(e.clipboardData.getData('text').slice(0, 6)))) {
      pastedData = e.clipboardData.getData('text').slice(0, 6);
      setOtpCode({
        1: pastedData[0],
        2: pastedData[1],
        3: pastedData[2],
        4: pastedData[3],
        5: pastedData[4],
        6: pastedData[5],
      });
      setOtpState(pastedData[0] + pastedData[1] + pastedData[2] + pastedData[3] + pastedData[4] + pastedData[5]);
    }
  };

  const backSpaceHandler = (e) => {
    if (e.key === 'Backspace' && e.target.name !== '1') {
      if (e.target.value.length > 0) {
        const idP = parseInt(e.target.name, 10);
        setOtpCode({
          ...otpCode,
          [idP]: '',
        });
      } else {
        setBackSpace(true);
        e.target.previousSibling.focus();
      }
    } else {
      setBackSpace(false);
    }
    if (e.key === 'Enter') {
      e.target.nextSibling.focus();
    }
  };

  const verification = (event) => {
    event.preventDefault();
    api.post('/profile/validate-update', {
      otp: otpState,
    }).then(({ data }) => {
      setShow(false);
      dispatch(setEmailOrPhoneValidated(true));
      setIsFirstLevel(true);
      setOtpState({
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
      });
    }).catch(({ response }) => {
      if (response.data) {
        setError({
          hasError: true,
          errorText: response.data?.message,
        });
        setOtpCode({
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        });
      }
    });
  };

  const cancel = (event) => {
    event.preventDefault();
    setShow(false);
    setIsFirstLevel(true);
    setIsTimer(false);
    setTimer(120);
    setError({
      hasError: false,
      errorText: '',
    });
  };


  const resendVerificationCode = (event) => {
    event.preventDefault();
    if (isPhone) {
      api.post('/profile/update-mobile', {
        mobile: inputs?.mobile?.value,
      }).then(({ data }) => {
        setIsTimer(true);
        setTimer(120);
        setIsFirstLevel(false);
        setIsFirstLevel(false);
      }).catch(({ response }) => {
        if (response?.data?.mobile) {
          dispatch(setToastStatus(0));
          dispatch(setToastMessage(response?.data?.mobile?.msg));
          dispatch(setShowToast(true));
        }
      });
    } else {
      api.post('/profile/update-email', {
        email: inputs?.email?.value,
      }).then(({ data }) => {
        setIsTimer(true);
        setTimer(120);
        setIsFirstLevel(false);
      }).catch(({ response }) => {
        if (response?.data?.email) {
          dispatch(setToastStatus(0));
          dispatch(setToastMessage(response?.data?.email?.msg));
          dispatch(setShowToast(true));
        }
      });
    }
  };

  return (
    <>
      <div className="enterCodeText">
        {intl.formatMessage({ id: 'enter_the_code' })}
      </div>
      <div className="phoneInEmail">
        {isPhone
          ? languageRedux === 'fa' ? `${ToPersianNumber(inputs?.mobile.value)}+` : `+${inputs?.mobile.value}`
          : inputs?.email?.value}
      </div>

      <div className="verificationOtpSec">
        <input
          type="number"
          onPaste={handlePaste}
          autoFocus
          id="first-input"
          value={otpCode['1']}
          onChange={otpInputHandler}
          onKeyDown={backSpaceHandler}
          className={error.hasError ? 'otpNumberError' : 'otpNumber'}
          name="1"
        />
        <input
          type="number"
          onPaste={handlePaste}
          value={otpCode['2']}
          id={2}
          onChange={otpInputHandler}
          onKeyDown={backSpaceHandler}
          className={error.hasError ? 'otpNumberError' : 'otpNumber'}
          name="2"
        />

        <input
          type="number"
          onPaste={handlePaste}
          value={otpCode['3']}
          id={3}
          onChange={otpInputHandler}
          onKeyDown={backSpaceHandler}
          className={error.hasError ? 'otpNumberError' : 'otpNumber'}
          name="3"
        />

        <input
          type="number"
          onPaste={handlePaste}
          value={otpCode['4']}
          id={4}
          onChange={otpInputHandler}
          onKeyDown={backSpaceHandler}
          className={error.hasError ? 'otpNumberError' : 'otpNumber'}
          name="4"
        />
        <input
          type="number"
          onPaste={handlePaste}
          value={otpCode['5']}
          id={5}
          onChange={otpInputHandler}
          onKeyDown={backSpaceHandler}
          className={error.hasError ? 'otpNumberError' : 'otpNumber'}
          name="5"
        />
        <input
          type="number"
          onPaste={handlePaste}
          value={otpCode['6']}
          id={6}
          onChange={otpInputHandler}
          onKeyDown={backSpaceHandler}
          className={error.hasError ? 'otpNumberError' : 'otpNumber'}
          name="6"
        />
        {/* {text && text} */}
      </div>
      <div className="verificationErrorTimerSec">
        <div className="errorSec">
          {
              error.hasError
              && (
              <strong>{error.errorText}</strong>
              )
          }
        </div>
        <div className="timerSec">
          {
            isTimer
              ? <TimerHandler timer={timer} setTimer={setTimer} setIsTimer={setIsTimer} />
              : (
                <u className="resendText" onClick={resendVerificationCode}>
                  {intl.formatMessage({ id: 'resend_otp' })}
                </u>
              )
           }
        </div>

      </div>

      <div className="flexClass justify-content-center">
        <button
          type="button"
          className="btn cancelBtn"
          onClick={cancel}
        >
          {intl.formatMessage({ id: 'cancel' })}
        </button>
        <button
          type="button"
          className="btn confirmBtn"
          disabled={otpState?.length < 6}
          onClick={verification}
        >
          {intl.formatMessage({ id: 'confirm' })}
        </button>
      </div>

    </>
  );
}
