import Avatar from 'boring-avatars';
import React, { useEffect } from 'react';

export default function ProfilePicture({ size, colors }) {
  return (
    <Avatar
      size={size}
      name="Maria Mitchell"
      variant="marble"
      colors={colors}
      square
    />
  );
}
