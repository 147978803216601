import React, {useEffect, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router';
import NetworkStatus from '../helpers/NetworkStatus.jsx';

import Header from "./sport/layout/header/Header";
import Footer from "./sport/layout/footer/Footer"

const HeaderDrawer = React.lazy(() => import('./sport/layout/header/HeaderDrawer'));
const Toast = React.lazy(() => import('./sport/other/Toast.jsx'));

export default function Layout({children}) {
    const intl = useIntl();
    const {pathname} = useLocation();

    const headerProps = {
        color: '#00ABBC',
        type: {id: 0, title: 'moment'},
        links: [
            {id: 1, title: "صفحه اصلی", url: '/', name: 'exploreNavItem'},
            {id: 2, title: intl.formatMessage({id: 'explore'}), url: '/explore', name: 'exploreNavItem'},
            {id: 3, title: intl.formatMessage({id: 'clubs'}), url: '/clubs'},
            {id: 4, title: intl.formatMessage({id: 'faq'}), url: '/faq'},
            // {
            //   id: 4, title: intl.formatMessage({ id: 'about_us' }), url: '/about-us', name: 'aboutUsNavItem',
            // },
            // {
            //   id: 5, title: intl.formatMessage({ id: 'contact_us' }), url: '/contact-us', name: 'aboutUsNavItem',
            // },
            // {
            //   id: 6, title: intl.formatMessage({ id: 'what_is_NFT?' }), url: '/about-nft', name: 'aboutUsNavItem',
            // },
            // {
            //   id: 7, title: intl.formatMessage({ id: 'cooperation_request' }), url: '/collab', name: 'aboutUsNavItem',
            // },
            // {
            //   id: 8, title: intl.formatMessage({ id: 'learning' }), url: '/learning', name: 'aboutUsNavItem',
            // },

        ],
    };

    const headerDrawerProps = {
        color: '#00ABBC',
        type: {id: 0, title: 'moment'},
        links: [
            {
                id: 2, title: intl.formatMessage({id: 'explore'}), url: '/explore', icon: '/icons/explore.svg',
            },
            {
                id: 4, title: intl.formatMessage({id: 'clubs'}), url: '/clubs', icon: '/icons/explore.svg',
            },
            {
                id: 3, title: intl.formatMessage({id: 'faq'}), url: '/faq', icon: '/icons/comment.svg',
            },
            // {
            //   id: 6,
            //   title: intl.formatMessage({ id: 'what_is_NFT?' }),
            //   url: '/about-nft',
            //   icon: '/icons/blackPolygon.svg',
            // },
            // {
            //   id: 7,
            //   title: intl.formatMessage({ id: 'cooperation_request' }),
            //   url: '/collab',
            //   name: 'aboutUsNavItem',
            //   icon: '/icons/collabRequest.svg',
            // },
            // {
            //   id: 6, title: intl.formatMessage({ id: 'contact_us' }), url: '/contact-us', icon: '/icons/call.svg',
            // },
            // {
            //   id: 7, title: intl.formatMessage({ id: 'contact_us' }), url: '/about-nft', icon: '/icons/call.svg',
            // },
        ],
    };

    const momentTypeProps = {
        color: '#16B24B',
        type: {id: 0, title: 'moment'},
    };

    const footerProps = {
        color: '#16B24B',
        type: {id: 0, title: 'moment'},
        links: [
            {id: 3, title: intl.formatMessage({id: 'explore'}), url: '/explore'},
            {id: 4, title: intl.formatMessage({id: 'clubs'}), url: '/clubs'},
        ],
        support: [
            {id: 7, title: intl.formatMessage({id: 'what_is_NFT?'}), url: '/about-nft'},
            {id: 5, title: intl.formatMessage({id: 'faq'}), url: '/faq'},
            {id: 6, title: intl.formatMessage({id: 'terms-of-service'}), url: '/terms-of-service'},
            {id: 4, title: intl.formatMessage({id: 'about_us'}), url: '/about-us'},
            {id: 8, title: intl.formatMessage({id: 'contact_us'}), url: '/contact-us'},
            {id: 7, title: intl.formatMessage({id: 'cooperation_request'}), url: '/collab'},
        ],
    };

    return (
        <div style={{backgroundColor: '#FFFFFF'}}>
            <NetworkStatus/>

            <Suspense fallback={<div>در حال بارگزاری...</div>}>
                <Toast/>
                {
                    pathname?.includes('catalog-qrcode-voice') || pathname?.includes('moment-certificate')
                        ? (
                            <Outlet/>
                        )
                        : (
                            <>

                                {
                                    !pathname.includes('/waitlist')
                                    && <Header headerProps={headerProps} momentTypeProps={momentTypeProps}/>
                                }

                                <Outlet/>
                                <HeaderDrawer headerProps={headerProps} headerDrawerProps={headerDrawerProps}/>

                                {
                                    !pathname.includes('/waitlist')
                                    && <Footer footerProps={footerProps}/>
                                }
                            </>
                        )

                }
            </Suspense>
        </div>
    );
}
