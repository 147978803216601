import React from 'react';

export default function FullScreenLoadingBackDrop({ isTransferTokenLoading }) {
  return (
    <div className={isTransferTokenLoading ? 'nftBackdrop' : 'hideBackdrop'}>
      <div className="fullScreenLoadingWrapper">
        <span className="indicator-progress" style={{ display: 'block' }}>
          <span className="spinner-border spinner-border-sm align-middle ms-2   fullScreenLoading" />
        </span>
      </div>
    </div>
  );
}
