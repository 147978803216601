import React from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import ToPersianNumber from '../../../helpers/toPersianNumber';
import { selectLanguage } from '../../../redux/slices/languageSlice';

export default function Pagination({ handlePageClick, data, page }) {
  const languageRedux = useSelector(selectLanguage);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<img src="/icons/arrowLeft.svg" alt="" />}
      onPageChange={handlePageClick}
      pageLabelBuilder={((page) => {
        if (languageRedux === 'fa') {
          return ToPersianNumber(page);
        } if (languageRedux !== 'fa') {
          return page;
        }
      })}
      forcePage={page - 1}
      pageRangeDisplayed={5}
      pageCount={data?.pages}
      // pageCount={momentType === 3 ? transactions?.pages
      //   : momentType === 4 ? transactions?.pages : momentsData.pages}
      className="explorePagination"
      activeClassName="activePage"
      previousLabel={<img src="/icons/arrowRight.svg" alt="" />}
      renderOnZeroPageCount={null}
    />
  );
}
