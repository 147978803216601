import React, {
    useEffect, useState, useRef, memo,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import {
    Button,
    Col, Collapse, Container, Form, Row, Spinner,
} from 'react-bootstrap';

import {
    arbitrum, mainnet, polygon, chiliz,
} from 'wagmi/chains';
import {useWeb3Modal, useWeb3ModalTheme} from '@web3modal/wagmi/react';
import {
    useAccount, useDisconnect, useSignMessage,
} from 'wagmi';

// import { verifyMessage } from 'ethers/lib/utils';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import api from '../../../api';
import ProfilePicture from '../other/ProfilePicture';
import {selectUserData, setEmailOrPhoneValidated, setIsLogin, setUserData} from '../../../redux/slices/accountSlice';
import EditValidation from './EditValidation/index.jsx';
import {selectLanguage} from '../../../redux/slices/languageSlice';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import {setShowToast, setToastMessage, setToastStatus} from '../../../redux/slices/toastSlice';

function Wallets({
                     wallets,
                     setUserDatas,
                 }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {open: openWeb3Modal} = useWeb3Modal();

    const {disconnect} = useDisconnect();
    const {isConnected, address} = useAccount();
    // const {
    //   chain,
    // } = useNetwork();
    const chains = [mainnet, arbitrum, polygon, chiliz];

    const signMessageText = import.meta.env.VITE_WALLET_MESSAGE;

    const [isUserRequestedAdd, setIsUserRequestedAdd] = useState(false);

    const loggedInUserData = useSelector(selectUserData);

    const {
        signMessage, isSuccess, data, isError,
    } = useSignMessage();

    const {
        setThemeMode,
    } = useWeb3ModalTheme();

    setThemeMode('light');

    const connectWallet = async () => {
        // await disconnect();
        setIsUserRequestedAdd(true);
        openWeb3Modal();
    };

    useEffect(() => {
        if (isSuccess) {
            const chainId = polygon.id;
            api.post('/profile/add-wallet', {
                address,
                signature: data,
                chainId,
            })
                .then((r) => {
                    api.get(`/users/${loggedInUserData?.username}`).then((r) => {
                        setUserDatas(r.data);
                    });
                })
                .catch(({response}) => {
                    disconnect();
                    if (!response?.data?.message) {
                        dispatch(setShowToast(true));
                        dispatch(setToastStatus(0));
                        dispatch(setToastMessage(intl.formatMessage({id: 'try_again'})));
                    }
                });

        }
    }, [isSuccess])

    useEffect(() => {
        if (isError) {
            disconnect();
        }
    }, [isError]);

    useEffect(() => {
        if (isConnected && isUserRequestedAdd) {
            console.log(address);

            // const address = verifyMessage(variables.message, data);
            // const chainId = chain.id;

            signMessage({message: 'This message is a password'});
        }
    }, [isConnected]);


    const deleteWallet = (id) => {
        api.delete(`/profile/remove-wallet/${id}`)
            .then((r) => {
                setUserDatas(
                    (prevState) => (
                        {
                            ...prevState,
                            user: {
                                ...prevState.user,
                                walletAddresses: r.data.wallets,
                            },
                        }
                    ),
                );
            });
    };
    return (
        <>
            {wallets.map((adrs) => (
                <div key={adrs.id} className="walletItem">
                    <img src={adrs.chain.logo} className="chainImg" alt=""/>
                    <p>
                        {adrs.address}
                    </p>
                    {/*{wallets.length > 1*/}
                    {/*    && (*/}
                    {/*        <img*/}
                    {/*            src="/icons/trash.svg"*/}
                    {/*            onClick={() => deleteWallet(adrs.id)}*/}
                    {/*            className="trashImg"*/}
                    {/*            alt=""*/}
                    {/*        />*/}
                    {/*    )}*/}
                </div>
            ))}
            <div className="addWalletButton" onClick={connectWallet}>
                <img className="addWallet" src="/icons/sportCategory/add.svg" alt=""/>
                {intl.formatMessage({id: 'add_new_wallet'})}
            </div>
        </>
    );
}

const EditModal = memo((props) => {
    const {
        show,
        setShow,
        user,
        setUserDatas,
        setPageLoading,
    } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const languageRedux = useSelector(selectLanguage);

    const location = useLocation();

    const upload = useRef();
    const [loading, setLoading] = useState(false);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const [usernameStatus, setUsernameStatus] = useState({
        error: undefined,
        success: undefined,
    });
    const containPersianRegex = new RegExp('^[\u0600-\u06FF]+$');

    const onlyEnglishLetters = (/^[A-Za-z]+$/);

    const [inputs, setInputs] = useState({
        username: {
            value: '',
            error: undefined,
            success: undefined,
        },
        bio: {
            value: ' ',
            error: undefined,
            success: undefined,
        },
        email: {
            value: '',
            error: undefined,
            success: undefined,
        },
        mobile: {
            value: '',
            error: undefined,
            success: undefined,
        },
        firstName: {
            value: '',
            error: undefined,
            success: undefined,
        },
        lastName: {
            value: '',
            error: undefined,
            success: undefined,
        },
    });

    const [showMobileEditFeed, setShowMobileEditFeed] = useState(false);
    const [showEmailEditFeed, setShowEmailEditFeed] = useState(false);

    const [isSpacePressedInUserName, setIsSpacePressedInUserName] = useState(false);

    useEffect(() => {
        setInputs({
            username: {
                value: user.username,
            },
            bio: {
                value: user.bio,
            },
            email: {
                value: user.email,
            },
            mobile: {
                value: user.mobile?.slice(2),
            },
            firstName: {
                value: user?.firstName,
            },
            lastName: {
                value: user?.lastName,
            },
        });
        setUsernameStatus({
            error: undefined,
            success: undefined,
        });
        if (show) {
            setSelectedImage(null);
        }
    }, [show]);

    useEffect(() => {
        dispatch(setEmailOrPhoneValidated(false));
    }, []);

    window.addEventListener('storage', () => {
        if (localStorage.getItem('accessToken')) {
        } else {
            setShow(false);
        }
    });

    let profileSvgDiv;
    useEffect(() => {
        if (show) {
            if (document.getElementById('profileSvg')) {
                profileSvgDiv = document.getElementById('profileSvg');
                const gTag = profileSvgDiv.getElementsByTagName('g')[0];
                gTag?.setAttribute('mask', 'none');
            }
        }
    }, [show]);

    useEffect(() => {
        if (show === true) {
            setShow(false);
        }
    }, [location]);

    const handlerUserNameKeyDown = (e) => {
        console.log(e, ' handle keydown ');
        if (e.keyCode === 32) {
            //   user clicked space
            setIsSpacePressedInUserName(true);
            dispatch(setToastStatus(0));
            dispatch(setShowToast(true));
            dispatch(setToastMessage(intl.formatMessage({id: 'do_not_enter_space'})));
        } else {
            setIsSpacePressedInUserName(false);
        }
    };

    const handleUsernameChange = async (e) => {
        const val = e.target.value;

        // console.log(e, ' handle change');

        console.log("username length ", val?.length)

        if (val?.length < 6 && !containPersianRegex.test(val) && !isSpacePressedInUserName) {
            setUsernameStatus({
                error: intl.formatMessage({id: 'username_character_limitation_error'}),
            });
            setInputs({
                ...inputs,
                username: {
                    value: val,
                },
            });
        }
        if (val?.length >= 6 && val?.length <= 25 && !containPersianRegex.test(val) && !isSpacePressedInUserName) {
            setInputs({
                ...inputs,
                username: {value: val},
            });

            try {
                const response = await api.post(
                    '/profile/check-username',
                    {username: val},
                    {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}},
                );
                setUsernameStatus({
                    success: response.data.message,
                });
            } catch (e) {
                setUsernameStatus({
                    error: e.response?.data.message,
                });
            }
        }
    };

    const handleFirstAndLastNameHandler = (e) => {
        document.getElementById('firstName').maxLength = 20;
        document.getElementById('lastName').maxLength = 20;

        if (e.target.name === 'firstName') {
            if (e.target.value?.length <= 20) {
                setInputs({...inputs, firstName: {value: e.target.value}});
            } else {
                setInputs({...inputs, firstName: {error: 'ggg'}});
            }
        }
        if (e.target.name === 'lastName') {
            if (e.target.value?.length <= 20) {
                setInputs({...inputs, lastName: {value: e.target.value}});
            } else {
                setInputs({...inputs, lastName: {error: 'ggg'}});
            }
        }
    };
    const saveInfo = async (e) => {
        setLoading(true);
        e.preventDefault();
        let imgPath;
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            const {data: {path}} = await api.post('/media/upload-image', formData);
            imgPath = path;
        }
        // username: inputs.username.value ? inputs.username.value : undefined,
        // username: inputs.username.value?.length > 0 ? inputs.username.value.replace(/\s/g, '') : undefined,

        console.log(inputs.username?.value);

        api.put('/profile', {
            username: inputs.username.value?.length > 0 ? inputs.username.value.replace(/\s/g, '') : undefined,
            bio: inputs.bio.value ?? undefined,
            mobile: inputs.mobile.value ?? undefined,
            email: inputs.email.value ?? undefined,
            image: imgPath ?? undefined,
            firstName: inputs.firstName?.value ? inputs.firstName?.value : '',
            lastName: inputs.lastName?.value ? inputs.lastName?.value : '',
        }).then((r) => {
            setPageLoading(true);
            const keys = Object.keys(inputs);
            console.log('your username: ', inputs.username.value);

            api.get(`/users/${inputs.username.value.replace(/\s/g, '')}`).then((r) => {
                setUserDatas(r.data);
                setPageLoading(false);
                localStorage.setItem('username', r.data.user?.username);
                dispatch(setUserData(r.data.user));
                navigate('/profile');
            });
            const successObj = {};
            for (let i = 0; i < keys.length; i++) {
                successObj[keys[i]] = {
                    error: undefined,
                    success: undefined,
                    ...inputs[keys[i]],
                };
            }
            setLoading(false);
            setShow(false);
        }).catch((e) => {
            const keys = Object.keys(e.response?.data);

            console.log(e.response?.data?.username && e.response?.data?.username?.msg, ' res');

            // user-122898

            const errObj = {};
            for (let i = 0; i < keys.length; i++) {
                errObj[keys[i]] = {
                    error: e.response?.data[keys[i]].msg,
                    ...inputs[keys[i]],
                };
            }
            if (e.response?.data?.username && e.response?.data?.username?.msg) {
                dispatch(setShowToast(true));
                dispatch(setToastStatus(0));
                dispatch(setToastMessage(e.response?.data?.username?.msg));
            } else {
                dispatch(setShowToast(true));
                dispatch(setToastStatus(0));
                dispatch(setToastMessage(intl.formatMessage({id: 'try_again'})));
            }

            setLoading(false);
            setInputs({...inputs, ...errObj});
        });
    };

    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setSelectedImage(URL.createObjectURL(e.target.files[0]));
    };

    const phoneInputHandler = (phone) => {
        setInputs({...inputs, mobile: {value: phone}});
    };

    const handleInputChange = (e) => {
        const val = e.target.value;
        const {name} = e.target;

        setInputs({
            ...inputs,
            [name]: {value: val},
        });
    };

    return (
        <div className="editProfileModal">
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="editProfileModal"
            >
                <Modal.Header closeButton style={{borderBottom: 'none'}}/>
                <Modal.Body className="show-grid body">
                    <Container>
                        <Form className="edit-form">
                            <input
                                type="file"
                                hidden
                                ref={upload}
                                name="1"
                                onChange={onFileChange}
                                accept=".jpeg, .jpg, .png, .svg"
                            />
                            <Form.Group>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label className="editProfileTitle imageTitle">
                                            {intl.formatMessage({id: 'profile_picture'})}
                                            :
                                        </Form.Label>
                                    </Col>
                                    <Col md className="profileImg">
                                        <div className="profilePicture" id="profileSvg">
                                            {(selectedImage || user.image)
                                                ? (
                                                    <div
                                                        className="selectedImage"
                                                        style={{backgroundImage: `url(${selectedImage || user.image})`}}
                                                        alt=""
                                                    />
                                                )
                                                : <ProfilePicture size={150} colors={user.profileColors}/>}
                                        </div>
                                        <div className="addImgImg">
                                            <img
                                                src="/icons/addImg.svg"
                                                onClick={() => upload.current.click()}
                                                className="addImgSvg"
                                                alt=""
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label className="editProfileTitle">
                                            {intl.formatMessage({id: 'username'})}
                                            :
                                        </Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control
                                            value={inputs.username.value}
                                            className="input"
                                            type="text"
                                            placeholder={intl.formatMessage({id: 'enter_username_with_at_least_6_characters'})}
                                            name="username"
                                            onChange={handleUsernameChange}
                                            onKeyDown={handlerUserNameKeyDown}
                                        />
                                        <p className={usernameStatus?.error ? 'error' : 'success'}>
                                            {(usernameStatus?.success || usernameStatus?.error) ?? ''}
                                        </p>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label className="editProfileTitle">
                                            {intl.formatMessage({id: 'first_name'})}
                                            :
                                        </Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control
                                            value={inputs?.firstName?.value}
                                            className="input"
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            onChange={handleFirstAndLastNameHandler}
                                        />
                                        <p
                                            className={inputs?.firstName?.error ? 'error' : 'success'}
                                        >
                                            {inputs?.firstName?.error ? inputs?.firstName?.error : ''}
                                        </p>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label className="editProfileTitle">
                                            {intl.formatMessage({id: 'last_name'})}
                                            :
                                        </Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control
                                            value={inputs?.lastName?.value}
                                            className="input"
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            onChange={handleFirstAndLastNameHandler}
                                        />
                                        <p className={inputs?.lastName?.error ? 'error' : 'success'}>
                                            {inputs?.lastName?.error ? inputs?.lastName?.error : ''}
                                        </p>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label className="editProfileTitle">
                                            {intl.formatMessage({id: 'bio'})}
                                            :
                                        </Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control
                                            className="input"
                                            as="textarea"
                                            row="3"
                                            type="text"
                                            name="bio"
                                            value={inputs.bio.value}
                                            onChange={handleInputChange}
                                            placeholder={intl.formatMessage({id: 'tell_us_something_about_yourself'})}
                                        />
                                        <p
                                            className={inputs.bio.error ? 'error' : 'success'}
                                        >
                                            {(inputs.bio.success || inputs.bio.error) ?? ''}
                                        </p>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label className="editProfileTitle">
                                            {intl.formatMessage({id: 'wallet_addresses'})}
                                            :
                                        </Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <div className="walletAddresses">
                                            <div
                                                className="walletsHeader"
                                                onClick={() => setOpenCollapse(!openCollapse)}
                                            >
                                                <div>
                                                    {intl.formatMessage({id: 'you_have'})}
                                                    {' '}
                                                    {user.walletAddresses.length}
                                                    {' '}
                                                    {intl.formatMessage({id: 'wallet'})}
                                                    {(user.walletAddresses.length > 1 && languageRedux === 'en') ? 's' : ''}
                                                </div>
                                                <img
                                                    src={openCollapse ? '/icons/vectorUp.svg' : '/icons/vectorDown.svg'}
                                                    className="vector"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="walletsCollapse">
                                                <Collapse in={openCollapse}>
                                                    <div>
                                                        <Wallets
                                                            setUserDatas={setUserDatas}
                                                            wallets={user.walletAddresses}
                                                        />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row className="phoneAndEmailRow">
                                    <Col md={4} style={{height: 24}}>
                                        {/* <Form.Label className="editProfileTitle"> */}
                                        {intl.formatMessage({id: 'mobile_number'})}
                                        :
                                        {/* </Form.Label> */}
                                    </Col>
                                    <Col
                                        md={8}
                                        style={{display: 'flex', alignItems: 'center', height: 24}}
                                        className=""
                                    >
                                        <div
                                            className="flexClass mobileRow"
                                            onClick={() => {
                                                if (!showEmailEditFeed) {
                                                    setShowMobileEditFeed(true);
                                                }
                                            }}
                                        >
                                            {
                                                !user.mobile
                                                    ? (
                                                        <>
                                                            <div className="noNumberText">
                                                                {intl.formatMessage({id: 'no_number_yet'})}
                                                            </div>
                                                            <img src="/icons/sportCategory/add.svg" alt=""
                                                                 className="addSvg"/>
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <div className="phoneText">
                                                                {languageRedux === 'fa' ? `${ToPersianNumber(user.mobile.slice(2))}+` : `+${user.mobile.slice(2)}`}
                                                            </div>
                                                            <img
                                                                src="/icons/sportCategory/edit.svg"
                                                                alt=""
                                                                className="addSvg"
                                                                onClick={() => {
                                                                    if (!showEmailEditFeed) {
                                                                        setShowMobileEditFeed(true);
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                    )

                                            }
                                        </div>
                                    </Col>
                                </Row>

                                <p className={inputs.mobile.error ? 'error' : 'success'} style={{marginBottom: 45}}>
                                    {(inputs.mobile.success || inputs.mobile.error) ?? ''}
                                </p>

                                <EditValidation
                                    show={showMobileEditFeed}
                                    setShow={setShowMobileEditFeed}
                                    inputs={inputs}
                                    inputHandler={phoneInputHandler}
                                    isPhone
                                    user={user}
                                    setInputs={setInputs}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Row className="phoneAndEmailRow">
                                    <Col md={4}>
                                        {/* <Form.Label className="editProfileTitle"> */}
                                        {intl.formatMessage({id: 'email'})}
                                        :
                                        {/* </Form.Label> */}
                                    </Col>
                                    <Col md={8} className="flexClass flex-column justify-content-center">
                                        <div
                                            className="flexClass mobileRow justify-content-between"
                                            onClick={() => {
                                                if (!showMobileEditFeed) {
                                                    setShowEmailEditFeed(true);
                                                }
                                            }}
                                        >
                                            {
                                                !user.email
                                                    ? (
                                                        <>
                                                            <div className="phoneText">
                                                                {!user.email
                                                                    ? intl.formatMessage({id: 'no_email_yet'})
                                                                    : user.email}
                                                            </div>
                                                            <img src="/icons/sportCategory/add.svg" alt=""
                                                                 className="addSvg"/>
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <div className="phoneText">
                                                                {!user.email
                                                                    ? intl.formatMessage({id: 'no_email_yet'})
                                                                    : user.email}
                                                            </div>
                                                            <img
                                                                src="/icons/sportCategory/edit.svg"
                                                                alt=""
                                                                className="addSvg"
                                                                onClick={() => {
                                                                    if (!showMobileEditFeed) {
                                                                        setShowEmailEditFeed(true);
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                    )
                                            }
                                        </div>
                                    </Col>
                                </Row>

                                <p className={inputs.email.error ? 'error' : 'success'} style={{marginBottom: 45}}>
                                    {(inputs.email.success || inputs.email.error) ?? ''}
                                </p>

                                <EditValidation
                                    show={showEmailEditFeed}
                                    setShow={setShowEmailEditFeed}
                                    inputs={inputs}
                                    inputHandler={handleInputChange}
                                    isPhone={false}
                                    setInputs={setInputs}
                                    user={user}
                                />
                            </Form.Group>
                            <Button
                                size="sm"
                                disabled={loading || (inputs.username?.value?.length < 6) || showEmailEditFeed || showMobileEditFeed}
                                onClick={saveInfo}
                                className="saveBtn"
                                id="editProfileBtnInProfile"
                            >
                                {loading ? <Spinner className="saveSpinner" size="sm"
                                                    animation="border"/> : intl.formatMessage({id: 'save'})}
                            </Button>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    );
});

export default EditModal;
