import axios from 'axios';

import { store } from './redux/store';
import { setToastStatus, setShowToast, setToastMessage } from './redux/slices/toastSlice';

// console.log('VITE_API_URL', import.meta.env.VITE_API_URL);

const API = axios.create({
  // baseURL: 'https://api.momentsport.app/',
  // baseURL: 'https://testapi.mymoment.app/',
  baseURL: import.meta.env.VITE_API_URL,
  // headers: {
  //   common: {
  //     'Accept-Language': 'fa_IR',
  //   },
  // },
});

API.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('i18nConfig')) {
      if (localStorage.getItem('i18nConfig').includes('fa')) {
        config.headers.common = {
          'Accept-Language': 'fa_IR',
        };
      } else {
        config.headers.common = {
          'Accept-Language': 'en_US',
        };
      }
    } else {
      localStorage.setItem('i18nConfig', JSON.stringify({ selectedLang: 'en' }));
      config.headers.common = {
        'Accept-Language': 'en_US',
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.request.use(
  (config) => {
    if (config.url === '/auth/otp-verify') {
      const tempToken = localStorage.getItem('temporaryAccessToken');
      config.headers.Authorization = `Bearer ${tempToken}`;
    } else {
      const token = localStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (config) => {
    if (config.data.message) {
      if (config?.config?.url !== '/misc/waitlist/club' && config?.config?.url !== '/forecasts' && config?.config?.url !== '/misc/waitlist') {
        store.dispatch(setShowToast(true));
        store.dispatch(setToastStatus(1));
        store.dispatch(setToastMessage(config.data.message));
      }
    }
    return config;
  },
  (error) => {
    if (error.response.data.message) {
      if (!(error.request.responseURL.includes('/util'))) {
        //  we don't show util api error
        console.log(error, 'api error in interceptor');
        store.dispatch(setShowToast(true));
        store.dispatch(setToastStatus(0));
        store.dispatch(setToastMessage(error.response.data.message));
      }
    }
    return Promise.reject(error);
  },
);

export default API;
