import React, { useEffect, useState } from 'react';
import { FormCheck, Modal, Spinner } from 'react-bootstrap';
import '../../../../assets/styles/Campaign/Campaign.scss';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import api from '../../../../api.js';
import { selectLanguage } from '../../../../redux/slices/languageSlice';
import toPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import toEnglishNumber from '../../../../helpers/toEnglishNumber.jsx';
import {
  selectShowBuyModal,
  selectShowBuyModalAfterLogin,
  setShowBuyModal,
  setShowBuyModalAfterLogin,
} from '../../../../redux/slices/buySlice';
import { selectIsLogin } from '../../../../redux/slices/accountSlice';
import addCommas from '../../../../helpers/addCommas.jsx';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';

export default function FixPriceBuyModal(props) {
  const {
    show, setShow,
    selectedMoment,
    setIsMomentStatusChanged,
    // showAfterLogin,
    // setShowBuyModalAfterLogin,
    selectedWallet,
    selectedEdition,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const { editionId } = useParams();

  const showBuy = useSelector(selectShowBuyModal);
  const isLogin = useSelector(selectIsLogin);

  const [isLoading, setIsLoading] = useState(false);

  const [cryptoPaymentMethods, setCryptoPaymentMethods] = useState([]);
  const [irPaymentMethods, setIrPaymentMethods] = useState([]);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [selectedGate, setSelectedGate] = useState(3);
  const [methodType, setMethodType] = useState(1);

  const [isAgreed, setIsAgreed] = useState(false);

  // const [selectedEdition, setSelectedEdition] = useState(selectedMoment?.momentEditions?.find((item) => item?.id === parseInt(editionId, 10)));
  const myEditionsList = selectedMoment?.momentEditions?.filter((item) => item?.isOwner === true);

  const showAfterLogin = useSelector(selectShowBuyModalAfterLogin);

  const languageRedux = useSelector(selectLanguage);

  const [userCredit, setUserCredit] = useState(null);

  const [isUseCredit, setIsUseCredit] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      console.log(' summary in fixPriceBuyModal');
      api.get('/profile/summary').then(({ data }) => {
        setUserCredit(data?.user?.credit);
      }).catch(({ response }) => {
        console.log(response);
      });
    }
  }, [isLogin]);

  useEffect(() => {
    if (show === false) {
      setIsAgreed(false);
    }
  }, [show]);

  useEffect(() => {
    api.get('/campaigns/payment-methods').then(({ data }) => {
      // setPaymentMethods(data);
      setCryptoPaymentMethods(data.filter((item) => item.isCrypto === true));
      setIrPaymentMethods(data.filter((item) => item.isCrypto === false));
    });
  }, []);

  useEffect(() => {
    setMethodType(1);
  }, [show, showBuy, showAfterLogin]);

  useEffect(() => {
    if (Object.keys(cryptoPaymentMethods).length > 0 && Object.keys(irPaymentMethods).length > 0) {
      if (methodType === 0) {
        setSelectedGate(cryptoPaymentMethods[0].id);
      }
      if (methodType === 1) {
        setSelectedGate(irPaymentMethods[0].id);
      }
    }
  }, [methodType, show, showBuy, showAfterLogin]);

  const handleClose = () => {
    setShow(false);
    // setShowBuyModalAfterLogin(false);
    dispatch(setShowBuyModalAfterLogin(false));
  };

  const methodHandler = (event) => {
    setMethodType(parseInt(event.target.value, 10));
  };

  const buyNFT = () => {
    setIsLoading(true);
    // setIsMomentStatusChanged(true);

    console.log('selected wallet ', selectedWallet?.id);

    if (selectedMoment?.type === 'single') {
      api.post(`/moments/${selectedMoment?.id}/buy`, {
        paymentMethodId: selectedGate,
        walletAddressId: selectedWallet ? selectedWallet?.id : null,
        useCredit: isUseCredit,
      }).then(({ data: response }) => {
        setIsLoading(false);
        window.location.replace(response?.paymentUrl);
      }).catch(({ response }) => {
        setIsLoading(false);
      });
    }
    if (selectedMoment?.type === 'edition') {
      api.post(`/moments/editions/${editionId}/buy`, {
        paymentMethodId: selectedGate,
        walletAddressId: selectedWallet ? selectedWallet?.id : null,
        useCredit: isUseCredit,
      }).then(({ data: response }) => {
        setIsLoading(false);
        window.location.replace(response?.paymentUrl);
      }).catch(({ response }) => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (show) {
      console.log(selectedMoment);
      console.log(selectedEdition);
    }
  }, [selectedMoment, show]);

  function ShowPrice() {
    if (selectedMoment?.type === 'edition') {
      if (selectedEdition?.isForSale) {
        console.log(selectedEdition , "selected edition")
        if (languageRedux === 'fa') {
          // return 'xvbxclmbmnckbj';
          return toPersianNumber(addCommas(selectedEdition?.listingPrice));
        }
        return addCommas(selectedEdition?.listingPrice);
      }

      return '-';
    }
    if (selectedMoment?.isForSale) {
      if (languageRedux === 'fa') {
        return toPersianNumber(addCommas(selectedMoment?.listingPrice));
      }

      return addCommas(selectedMoment?.listingPrice);
    }

    return '-';
  }

  function ShowPriceToPay() {
    if (isUseCredit) {
      if (selectedMoment?.type === 'edition') {
        if (parseInt(selectedEdition?.listingPrice, 10) - parseInt(userCredit?.amount, 10) <= 0) {
          if (languageRedux === 'fa') {
            return ToPersianNumber(0);
          }
          return 0;
        }

        if (languageRedux === 'fa') {
          return (selectedEdition?.isForSale)
              && ToPersianNumber(addCommas(selectedEdition?.listingPrice - parseInt(userCredit?.amount, 10)));
        }
        return (selectedEdition?.isForSale)
            && addCommas(selectedEdition?.listingPrice - parseInt(userCredit?.amount, 10));
      }

      if (parseInt(selectedMoment?.listingPrice) - parseInt(userCredit?.amount, 10) <= 0) {
        if (languageRedux === 'fa') {
          return ToPersianNumber(0);
        }
        return 0;
      }

      if (languageRedux === 'fa') {
        return (selectedMoment?.isForSale)
            && ToPersianNumber(addCommas(selectedMoment?.listingPrice - parseInt(userCredit?.amount, 10)));
      }

      return (selectedMoment?.isForSale)
          && addCommas(selectedMoment?.listingPrice - parseInt(userCredit?.amount, 10));
    }
  }

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="buyModal  modal-dialog-centered"
      show={show || (isLogin && showAfterLogin)}
            // show
      backdrop
    >
      <div className="modalHeader">
        <img
          src="/icons/close.svg"
          alt=""
          onClick={handleClose}
          className="close"
        />
      </div>
      <div className="buyModalBody">
        <div className="buyModalImageAndPriceASec">

          <div className="imageSec">
            <div
              style={{ backgroundImage: `url(${selectedMoment?.image ? selectedMoment.image : selectedMoment?.campaign?.image})` }}
              className="image"
            />
          </div>

          <div className="fixPriceSec">
            <strong className="fixPriceTitle">
              {intl.formatMessage({ id: 'price' })}
            </strong>

            {
              selectedMoment?.type === 'edition' ? (
                <div className="priceWrapper">
                  {
                            isUseCredit
                            && <div className="priceBeforeUsingCreditLine" />
                        }

                  <div className="fixPrice">
                    <ShowPrice />
                    تومان
                  </div>
                </div>
              )
                : (
                  <div className="priceWrapper">

                    {
                            isUseCredit
                            && <div className="priceBeforeUsingCreditLine" />
                        }

                    <div className="fixPrice">
                      <ShowPrice />
                      تومان
                    </div>
                  </div>
                )
            }

            <div className="priceAfterUsingCredit">
              <ShowPriceToPay />
              {isUseCredit && 'تومان'}
            </div>

          </div>

        </div>

        <div className="buyCampaignInventory">
          <img src="/icons/wallet.svg" alt="" />
          {intl.formatMessage({ id: 'wallet_balance' })}
          :
          {' '}
          {languageRedux === 'fa' ? ToPersianNumber(addCommas(userCredit?.amount > 0 ? userCredit?.amount : 0)) : addCommas(userCredit?.amount > 0 ? userCredit?.amount : 0)}
          {' '}
          {intl.formatMessage({ id: 'toman' })}
        </div>

        <div className="buyUseCreditWrapper">
          <div className="flex">
            <div>
              {intl.formatMessage({ id: 'using_wallet_balance' })}
              :
            </div>
            <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
              <input
                disabled={!(userCredit?.amount > 0)}
                className=" form-check-input creditSwitch"
                type="checkbox"
                aria-label="useCredit FormElement"
                value={isUseCredit}
                name="notifications"
                checked={isUseCredit}
                onChange={(event) => {
                  setIsUseCredit(!isUseCredit);
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="paymentMethodTitle">
            {intl.formatMessage({ id: 'payment_method' })}
          </div>

          <div className="paymentSec">

            <div className="item">

              <div className="form-check paymentRadioButton">
                <input
                  className="form-check-input"
                  type="radio"
                  defaultChecked
                  onClick={methodHandler}
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value={1}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  {intl.formatMessage({ id: 'by_rial' })}
                </label>
              </div>
            </div>

            {
                            Object.keys(cryptoPaymentMethods).length > 0
                            && (
                            <div className="item">
                              <div className="form-check paymentRadioButton">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  onClick={methodHandler}
                                  value={0}
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                  {intl.formatMessage({ id: 'by_crypto' })}
                                </label>
                              </div>
                            </div>
                            )
                        }

          </div>

          <div className="paymentGate">

            {
                            parseInt(methodType, 10) === 0
                              ? cryptoPaymentMethods?.map((paymentMethod, idx) => {
                                if (idx === 0) {
                                  // setSelectedGate(paymentMethod.id);
                                }
                                return (
                                  <div
                                    className={paymentMethod.id === selectedGate ? 'paymentItem selected' : 'paymentItem'}
                                    key={paymentMethod.id}
                                    onClick={() => {
                                      setSelectedGate(paymentMethod.id);
                                    }}
                                  >
                                    <img src={paymentMethod.logo} alt="" className="paymentImage" />
                                  </div>
                                );
                              })
                              : irPaymentMethods?.map((paymentMethod) => (
                                <div
                                  className={paymentMethod.id === selectedGate ? 'paymentItem selected' : 'paymentItem'}
                                  key={paymentMethod.id}
                                  onClick={() => {
                                    setSelectedGate(paymentMethod.id);
                                  }}
                                >
                                  <img src={paymentMethod.logo} alt="" className="paymentImage" />
                                </div>
                              ))
                        }

          </div>

          <div className="gatewayDetail">
            {
                methodType === 0
                && intl.formatMessage({ id: 'crypto_gateway_need_vpn' })
            }
          </div>

          <div className="buyAgreedRow">
            <FormCheck
              aria-label="Checkbox for buy NFT"
              onChange={(event) => {
                setIsAgreed(event?.target?.checked);
              }}
              checked={isAgreed}
            />

            <div style={isAgreed ? { fontWeight: 600 } : { fontWeight: 500 }}>

              <Link to="/terms-of-service" target="_blank">
                <strong style={{ textDecoration: 'underline' }}>
                  {intl.formatMessage({ id: 'agreed_buy_attention_1' })}
                </strong>
              </Link>
              &nbsp;&nbsp;&nbsp;
              {intl.formatMessage({ id: 'agreed_buy_attention_2' })}

            </div>
          </div>

          <button
            type="button"
            className="payBtn btn"
            onClick={buyNFT}
            disabled={!isAgreed}
          >
            {
                            isLoading
                              ? <Spinner className="saveSpinner" size="sm" animation="border" />
                              : intl.formatMessage({ id: 'pay' })
                        }
          </button>

        </div>

      </div>
    </Modal>
  );
}
