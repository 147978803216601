import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../api';
import Index from './index';
import { selectIsLogin, selectUserData, setShowLogin } from '../../../../redux/slices/accountSlice';
import MomentCardSkeleton from '../../general/Card/MomentCardSkeleton.jsx';

export default function CollectedTab({
  userDatas, setTabContentLoading, tabContentLoading, sort,
}) {
  const lsUsername = localStorage.getItem('username');
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector(selectIsLogin);
  const loggedInUserData = useSelector(selectUserData);

  const { username } = useParams();

  const [searchParams] = useSearchParams();
  const [momentsData, setMomentsData] = useState({
    moments: [],
  });
  const [itemOffset, setItemOffset] = useState(1);
  const [endOffset, setEndOffset] = useState(itemOffset + momentsData.moments.length - 1);
  const [page, setPage] = useState(searchParams?.get('page') !== null ? searchParams?.get('page') : 1);

  const [transferToWallet, setTransferToWallet] = useState(false);
  const [publishStatusChanged, setPublishStatusChanged] = useState(false);

  const [isMomentStatusChanged, setIsMomentStatusChanged] = useState(false);

  useEffect(() => {
    setTabContentLoading(true);
    // if we have username params, it means it's the users page .  if we don't have it , it means it's profile page
    api.get(`/users/${username || loggedInUserData?.username}/moments`, {
      params: {
        page,
        sort,
      },
    })
      .then((r) => {
        setMomentsData(r.data);
        setTabContentLoading(false);
        const newOffset = page !== 1 ? (page - 1) * 15 + 1 : 1;
        setItemOffset(newOffset);
        setEndOffset(newOffset + r.data.moments.length - 1);
      });
  }, [page, sort, location, transferToWallet, publishStatusChanged, isLogin, isMomentStatusChanged]);

  const goToExplore = () => {
    navigate('/explore');
  };
  if (tabContentLoading) {
    return (
      <div className="nftList">
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
      </div>
    );
  }

  if (lsUsername === loggedInUserData?.username ? momentsData?.moments.length === 0 : momentsData?.moments?.filter((item) => item.isPublic === true)?.length === 0) {
    return (
      <div className="createdEmptySec">
        <div style={{ backgroundImage: 'url(/icons/sportCategory/emptyCreatedSvg.svg)' }} className="EmptySecNFTImage" />
        <div className="emptyCreatedSec">
          <div className="haveNotCreatedYet">
            {
                  lsUsername === loggedInUserData?.username
                    ? intl.formatMessage({ id: 'You_have_not_collected_any_moment_yet' })
                    : intl.formatMessage({ id: 'no_nft_to_show' })
                }
          </div>
          <button type="button" className="btn createNFTBtn" onClick={goToExplore}>
            {intl.formatMessage({ id: 'explore' })}
          </button>
        </div>
      </div>
    );
  }

  return (
    <Index
      sort={sort}
      setTabContentLoading={setTabContentLoading}
      tabContentLoading={tabContentLoading}
      userDatas={userDatas}
      setPublishStatusChanged={setPublishStatusChanged}
      setTransferToWallet={setTransferToWallet}
      setIsMomentStatusChanged={setIsMomentStatusChanged}
      momentsData={momentsData}
      endOffset={endOffset}
      itemOffset={itemOffset}
      setItemOffset={setItemOffset}
      tab="collected"
      page={page}
      setPage={setPage}
    />
  );
}
