import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import api from '../../../../api';
import Index from './index';
import MomentCardSkeleton from '../../general/Card/MomentCardSkeleton.jsx';
import { selectUserData } from '../../../../redux/slices/accountSlice';

export default function FavoritesTab({
  userDatas, setTabContentLoading, tabContentLoading, sort,
}) {
  const lsUsername = localStorage.getItem('username');
  const { username } = useParams();
  const location = useLocation();
  const intl = useIntl();
  const loggedInUserData = useSelector(selectUserData);

  const [searchParams] = useSearchParams();

  // const [tabContentLoading, setTabContentLoading] = useState(false);
  const [momentsData, setMomentsData] = useState({
    moments: [],
  });
  const [itemOffset, setItemOffset] = useState(1);
  const [endOffset, setEndOffset] = useState(itemOffset + momentsData.moments.length - 1);
  const [page, setPage] = useState(searchParams?.get('page') !== null ? searchParams?.get('page') : 1);
  // const [sort, setSort] = useState('createdAt,DESC');

  const [transferToWallet, setTransferToWallet] = useState(false);
  const [publishStatusChanged, setPublishStatusChanged] = useState(false);
  const [isMomentStatusChanged, setIsMomentStatusChanged] = useState(false);

  useEffect(() => {
    setTabContentLoading(true);
    // if we have username params, it means it's the users page .  if we don't have it , it means it's profile page
    api.get(`/users/${username || loggedInUserData?.username}/favorites`, {
      params: {
        page,
        sort,
      },
    })
      .then((r) => {
        setMomentsData(r.data);
        setTabContentLoading(false);
        const newOffset = page !== 1 ? (page - 1) * 15 + 1 : 1;
        setItemOffset(newOffset);
        setEndOffset(newOffset + r.data.moments.length - 1);
      });
  }, [page, sort, location, transferToWallet, publishStatusChanged, isMomentStatusChanged]);

  if (tabContentLoading) {
    return (
      <div className="nftList">
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
        <MomentCardSkeleton />
      </div>
    );
  }
  if (lsUsername === username ? momentsData?.moments.length === 0 : momentsData?.moments?.filter((item) => item.isPublic === true)?.length === 0) {
    return (
      <div className="favoriteEmptySec">
        <div className="emptyCreatedSec">
          <div className="haveNotCreatedYet">
            {intl.formatMessage({ id: 'no_nft_to_show' })}
          </div>
        </div>
      </div>
    );
  }
  return (
    <Index
      sort={sort}
      setTabContentLoading={setTabContentLoading}
      tabContentLoading={tabContentLoading}
      userDatas={userDatas}
      setPublishStatusChanged={setPublishStatusChanged}
      setTransferToWallet={setTransferToWallet}

      setIsMomentStatusChanged={setIsMomentStatusChanged}

      momentsData={momentsData}
      endOffset={endOffset}
      itemOffset={itemOffset}
      setItemOffset={setItemOffset}
      tab="favorites"
      page={page}
      setPage={setPage}
    />
  );
}
