import React, { useState } from 'react';

import '../../../../assets/styles/general/General.scss';
import { useLocation } from 'react-router';
import PresaleCard from './PresaleCard.jsx';
import Card from './Card.jsx';

function ShowCardHandler({ moment }) {
  const [playMode, setPlayMode] = useState(false);

  if (moment.isSold && moment.image !== null) {
    return (
      <Card
        moment={moment}
        // key={moment?.id}
        playMode={playMode}
        setPlayMode={setPlayMode}
      />
    );
  }
  if (!moment.isSold || (moment.isSold && moment.image === null)) {
    return (
      <PresaleCard
        moment={moment}
        playMode={playMode}
        setPlayMode={setPlayMode}
        // key={moment?.id}
      />
    );
  }
}

export default function MomentCard({ moment }) {
  const location = useLocation();

  return (
    <div
      className={`nftCard momentCard ${location.pathname.includes('family') ? 'familyTheme' : 'empathyTheme'}`}
      // key={moment.id}
    >
      <ShowCardHandler
        moment={moment}
      />
    </div>
  );
}
