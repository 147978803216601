import React from 'react';
import '../../../../assets/styles/User/tables.scss';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from '../../general/Skeleton';
import ToPersianNumber from '../../../../helpers/toPersianNumber';
import { selectLanguage } from '../../../../redux/slices/languageSlice';
import PersianDate from '../../general/PersianDate';
import ProfilePicture from '../../other/ProfilePicture';
import { ShowUserName } from '../../general/ShowUserName';

export default function Gifts({ pageLoading, gifts }) {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);

  return (
    <div className="giftTableWrapper">
      <div className="flexClass giftForOther align-items-center">
        <img src="/icons/gift.svg" alt="" className="giftIcon" />
        <div>{intl.formatMessage({ id: 'gift_for_others' })}</div>
      </div>
      {
                pageLoading
                  ? (
                    <table className="giftTable">
                      <thead>
                        <tr className="tableHead">
                          <th>
                            <div className="nftTh">
                              {intl.formatMessage({ id: 'nft' })}
                            </div>
                          </th>
                          <th>{intl.formatMessage({ id: 'price' })}</th>
                          <th>{intl.formatMessage({ id: 'username' })}</th>
                          <th>{intl.formatMessage({ id: 'wallet_address' })}</th>
                          <th>
                            <div className="flexClass align-items-center">
                              <div>
                                {' '}
                                {intl.formatMessage({ id: 'date' })}
                              </div>
                              <img src="/icons/giftArrowDown.svg" alt="" />
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className="firstTd">
                            <div className="nftTd flexClass align-items-center">
                              <Skeleton
                                className="giftNftImage"
                              />
                              <Skeleton style={{ width: 130, height: 21 }} />
                            </div>
                          </td>

                          <td className="secondTd">
                            <Skeleton style={{ height: 21, width: 100 }} className="priceTd">
                              0.023 ETH
                            </Skeleton>
                          </td>

                          <td className="thirdTd">
                            <Skeleton
                              style={{ height: 21, width: 190 }}
                              className="usernameTd flexClass blueColor align-items-center"
                            />
                          </td>

                          <td className="forthTd">
                            <Skeleton style={{ width: 100, height: 21 }} className="blueColor walletAddressTd" />
                          </td>

                          <td className="fifthTd">
                            <Skeleton style={{ height: 21, width: 100 }} className="dateTd" />
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  )

                  : (
                    <table className="giftTable">
                      <thead>
                        <tr className="tableHead">
                          <th>
                            <div className="nftTh">
                              {intl.formatMessage({ id: 'nft' })}
                            </div>
                          </th>
                          <th>{intl.formatMessage({ id: 'price' })}</th>
                          <th>{intl.formatMessage({ id: 'username' })}</th>
                          <th>{intl.formatMessage({ id: 'wallet_address' })}</th>
                          <th>
                            <div className="flexClass align-items-center">
                              <div>
                                {' '}
                                {intl.formatMessage({ id: 'date' })}
                              </div>
                              <img src="/icons/giftArrowDown.svg" alt="" />
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                                gifts?.moments?.map((gift) => {
                                  const persianDate = new PersianDate(gift?.createdAt);
                                  const date = new Date(gift?.createdAt);

                                  const urlHandler = () => {
                                    if (gift?.type === 'single') {
                                      return `/nfts/${gift.id}`;
                                    }
                                    if (gift?.type === 'edition') {
                                      return `/nfts/${gift.id}/editions/${gift?.momentEditions[0]?.id}`;
                                    }
                                  };
                                  const lastItem = gift?.momentEditions?.slice(-1);
                                  return (
                                    <tr>
                                      <td className="firstTd">
                                        <div className="nftTd flexClass align-items-center">
                                          <Link to={urlHandler()}>
                                            <div
                                              className="giftNftImage"
                                              style={{ backgroundImage: `url(${gift?.image ? gift.image : gift?.campaign?.image})` }}
                                            />
                                          </Link>
                                          <Link to={urlHandler()}>
                                            {
                                                            languageRedux === 'fa' ? (
                                                              <div>
                                                                {gift?.type === 'edition' ? `${ToPersianNumber(gift?.momentEditions[0]?.number)} ${intl.formatMessage({ id: 'until' })} ${ToPersianNumber(lastItem[0]?.number)}`
                                                                  : ToPersianNumber(gift?.title)}
                                                              </div>
                                                            )
                                                              : (
                                                                <div>
                                                                  {gift?.type === 'edition' ? `${gift?.momentEditions[0]?.number} ${intl.formatMessage({ id: 'until' })} ${lastItem[0]?.number}`
                                                                    : gift?.title}
                                                                </div>
                                                              )
                                                        }
                                          </Link>
                                        </div>
                                      </td>
                                      <td className="secondTd">
                                        <div className="priceTd">
                                          {`${gift?.price} ${gift?.currency?.symbol} `}
                                        </div>
                                      </td>

                                      <td className="thirdTd">
                                        <div className="usernameTd flexClass blueColor align-items-center">

                                          {
                                                        gift?.momentEditions?.length > 0
                                                          ? gift?.momentEditions[0]?.owner?.image
                                                            ? (
                                                              <Link
                                                                to={`/users/${gift?.momentEditions[0]?.owner?.username}`}
                                                              >
                                                                <div
                                                                  className="userAvatar"
                                                                  style={{ backgroundImage: `url(${gift?.momentEditions[0]?.owner?.image})` }}
                                                                />
                                                              </Link>
                                                            )
                                                            : (
                                                              <Link
                                                                to={`/users/${gift?.momentEditions[0]?.owner?.username}`}
                                                              >
                                                                <div className="userAvatar" style={{ boxShadow: 'none' }}>
                                                                  <ProfilePicture
                                                                    colors={gift?.momentEditions[0]?.owner?.profileColors}
                                                                  />
                                                                </div>
                                                              </Link>
                                                            )
                                                          : gift?.owner?.image
                                                            ? (
                                                              <Link to={`/users/${gift?.owner?.username}`}>
                                                                <div
                                                                  className="userAvatar"
                                                                  style={{ backgroundImage: `url(${gift?.owner?.image})` }}
                                                                />
                                                              </Link>
                                                            )
                                                            : (
                                                              <Link to={`/users/${gift?.owner?.username}`}>
                                                                <div className="userAvatar" style={{ boxShadow: 'none' }}>
                                                                  <ProfilePicture
                                                                    colors={gift?.owner?.profileColors}
                                                                  />
                                                                </div>
                                                              </Link>
                                                            )
                                                    }
                                          {
                                                gift?.momentEditions?.length > 0
                                                  ? (
                                                    <Link to={`/users/${gift?.momentEditions[0]?.owner?.username}`}>
                                                      <strong>
                                                        <ShowUserName user={gift?.momentEditions[0]?.owner} />
                                                      </strong>
                                                    </Link>
                                                  )
                                                  : (
                                                    <Link to={`/users/${gift?.owner?.username}`}>
                                                      <strong>
                                                        <ShowUserName user={gift?.owner} />
                                                      </strong>
                                                    </Link>
                                                  )

                                            }
                                        </div>
                                      </td>

                                      <td className="forthTd">
                                        <strong className="blueColor walletAddressTd">
                                          {/* 0xE45d...a355 */}
                                          {gift?.owner?.walletAddresses.length > 0 ? gift?.owner?.walletAddresses[0] : '-'}
                                        </strong>
                                      </td>

                                      <td className="fifthTd">
                                        <strong className="dateTd">
                                          {/* 05/28/2020 */}
                                          {
                                                        languageRedux === 'fa'
                                                          ? `${ToPersianNumber(persianDate.getDate())} / ${ToPersianNumber(persianDate.getMonth())} / ${ToPersianNumber(persianDate.getYear())}`
                                                          : `${date.getMonth() + 1} / ${date.getDate()} / ${date.getFullYear()}`
                                                    }
                                        </strong>
                                      </td>

                                    </tr>
                                  );
                                })
                            }
                      </tbody>

                    </table>
                  )
            }
    </div>
  );
}
