import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {selectLanguage} from '../../../redux/slices/languageSlice';
import PersianDate from '../general/PersianDate.jsx';
import {ShowUserName} from '../general/ShowUserName.jsx';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import Skeleton from '../general/Skeleton.jsx';
import {getWindowSize} from '../../../helpers/getWindowSize.jsx';

export default function ItemActivity({
                                         openActivity, transfers,
                                         loaderTransferRef,
                                         // loadingTransfer,
                                         // setLoadingTransfer,
                                         loadMoreItems,
                                         transferPage,
                                         setTransferPage,
                                         loadingTransfer,
                                         setLoadingTransfer,
                                         handlePageClick

                                     }) {
    const intl = useIntl();
    const languageRedux = useSelector(selectLanguage);

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const matches = (windowSize?.innerWidth <= 972);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    // const handleObserver = (entities) => {
    //   const target = entities[0];
    //   if (target.isIntersecting && transferPage < transfers?.pages) {
    //     loadMoreItems();
    //   } else {
    //     setLoadingTransfer(false);
    //   }
    // };

    // useEffect(() => {
    //   // const options = {
    //   //   root: null,
    //   //   rootMargin: '20px',
    //   //   threshold: 1.0,
    //   // };
    //
    //   const observer = new IntersectionObserver(handleObserver);
    //   if (loaderTransferRef?.current) {
    //     observer.observe(loaderTransferRef.current);
    //   }
    //
    //   return () => {
    //     if (loaderTransferRef?.current) {
    //       observer.unobserve(loaderTransferRef.current);
    //     }
    //   };
    // }, [handleObserver]);


    // merge transaction to test

    if (openActivity) {
        if (transfers?.transfers?.length > 0) {
            return (
                <div>
                    <div className="accordionDetailsItemActivity">
                        <table className="table">

                            <thead>

                            <tr className="tableHeader">
                                <th>{intl.formatMessage({id: 'event'})}</th>
                                <th>{intl.formatMessage({id: 'status'})}</th>
                                {/* <th>{intl.formatMessage({ id: 'price' })}</th> */}
                                <th>{intl.formatMessage({id: 'from'})}</th>
                                <th>{intl.formatMessage({id: 'history_to'})}</th>
                                <th>{intl.formatMessage({id: 'history_date'})}</th>
                                <th>{intl.formatMessage({id: 'tx_hash'})}</th>
                            </tr>

                            </thead>

                            <tbody>

                            {
                                transfers?.transfers?.map((transfer) => {
                                    const eventDate = new Date(transfer.createdAt);
                                    const persianEventDate = new PersianDate(transfer.createdAt);
                                    // persianMonthNames[persianJoined.getMonth()]} ${ToPersianNumber(persianJoined.getYear())
                                    // Joined ${monthNames[joined.getMonth()]} ${joined.getFullYear()}
                                    return (
                                        <tr>
                                            <td>
                                                <img
                                                    src="/icons/transfer.svg"
                                                    alt=""
                                                    className="tablesTransferLogo"
                                                />
                                                {intl.formatMessage({id: 'transfer'})}
                                            </td>

                                            <td className={transfer?.status === 'success' ? 'successTransferTd' : transfer?.status === 'failed' ? 'failedTransferTd' : 'pendingTransferTd'}>

                                                {
                                                    transfer?.status === 'success'
                                                        ? intl.formatMessage({id: 'success'})
                                                        : transfer?.status === 'failed'
                                                            ? intl.formatMessage({id: 'failed'})
                                                            : intl.formatMessage({id: 'pending'})

                                                }
                                            </td>

                                            {/* <td> */}
                                            {/*  {languageRedux === 'fa' ? ToPersianNumber(listing?.amount) : listing?.amount} */}
                                            {/*  currency */}
                                            {/* </td> */}

                                            <td className="from">
                                                {
                                                    transfer?.fromUser
                                                        ? (
                                                            <Link to={`/users/${transfer?.fromUser?.username}`}>
                                                                <ShowUserName user={transfer?.fromUser}/>
                                                            </Link>
                                                        )
                                                        : `${transfer?.fromAddress?.slice(0, 4)}... ${transfer?.fromAddress?.slice(-4)}`
                                                }
                                            </td>

                                            <td className="to">
                                                {
                                                    transfer?.toUser
                                                        ? (
                                                            <Link to={`/users/${transfer?.toUser?.username}`}>
                                                                <ShowUserName user={transfer?.toUser}/>
                                                            </Link>
                                                        )
                                                        : `${transfer?.destinationAddress?.slice(0, 4)}... ${transfer?.destinationAddress?.slice(-4)}`
                                                }
                                            </td>

                                            <td>
                                                <div className="dateSec">
                                                    {
                                                        languageRedux === 'fa'
                                                            ? `${ToPersianNumber(String(persianEventDate.getDate()))} / ${ToPersianNumber(String(persianEventDate.getMonth() + 1))} / ${ToPersianNumber(String(persianEventDate.getYear()))}`
                                                            : `${eventDate.getFullYear()} / ${eventDate.getMonth() + 1} / ${eventDate.getDate()}`
                                                    }
                                                </div>
                                            </td>

                                            <td className="txHash">
                                                {
                                                    transfer?.txHash
                                                        ? (
                                                            <a
                                                                href={`https://polygonscan.com/tx/${transfer?.txHash}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {`${transfer?.txHash?.slice(0, 4)}... ${transfer?.txHash?.slice(-4)}`}
                                                            </a>
                                                        )
                                                        : '-'

                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            }

                            </tbody>

                            {/* <tr> */}
                            {/*  <td> */}
                            {/*    <img */}
                            {/*      src="/icons/transfer.svg" */}
                            {/*      alt="" */}
                            {/*      className="tablesTransferLogo" */}
                            {/*    /> */}
                            {/*    {intl.formatMessage({ id: 'transfer' })} */}
                            {/*  </td> */}

                            {/*  <td className="successTransferTd"> */}
                            {/*    {intl.formatMessage({ id: 'success' })} */}
                            {/*  </td> */}

                            {/*  <td className="from"> */}
                            {/*    0x00... 0000 */}
                            {/*  </td> */}

                            {/*  <td className="to"> */}
                            {/*    0x00... 0000 */}
                            {/*  </td> */}

                            {/*  <td> */}
                            {/*    <div className="dateSec"> */}
                            {/*      date */}
                            {/*    </div> */}
                            {/*  </td> */}

                            {/*  <td className="txHash"> */}
                            {/*    txHash */}
                            {/*  </td> */}
                            {/* </tr> */}

                        </table>

                        {/* <div ref={loaderTransferRef} className="loadingTransferSec"> */}
                        {/*  /!* { *!/ */}
                        {/*  /!*                    loadingTransfer *!/ */}
                        {/*  /!*                    && ( *!/ */}
                        {/*  /!*                    <span className="indicator-progress mintLoadingProgress" style={{ display: 'block' }}> *!/ */}
                        {/*  /!*                      <span *!/ */}
                        {/*  /!*                        className="spinner-border spinner-border-sm align-middle ms-2" *!/ */}
                        {/*  /!*                      /> *!/ */}
                        {/*  /!*                    </span> *!/ */}
                        {/*  /!*                    ) *!/ */}
                        {/*  /!*                } *!/ */}

                        {/*  /!* <span className="indicator-progress transferLoadingProgress" style={{ display: 'block' }}> *!/ */}
                        {/*  /!* </span> *!/ */}

                        {/*  { */}
                        {/*                    loadingTransfer */}
                        {/*                    && ( */}
                        {/*                    <Skeleton */}
                        {/*                      style={{ borderRadius: 10, height: 73 }} */}
                        {/*                      className="loadingTransferSec" */}
                        {/*                    /> */}
                        {/*                    ) */}
                        {/* } */}
                        {/* </div> */}

                        {
                            transfers?.pages > 1
                            && (
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel={<img src="/icons/arrowLeft.svg" alt=""/>}
                                    onPageChange={handlePageClick}
                                    pageLabelBuilder={((page) => {
                                        if (languageRedux === 'fa') {
                                            return ToPersianNumber(page);
                                        }
                                        if (languageRedux !== 'fa') {
                                            return page;
                                        }
                                    })}
                                    forcePage={transferPage - 1}
                                    pageRangeDisplayed={matches ? 1 : 4} // Adjust based on screen width
                                    marginPagesDisplayed={1}
                                    pageCount={transfers.pages}
                                    className="editionListPagination"
                                    activeClassName="activePage"
                                    previousLabel={<img src="/icons/arrowRight.svg" alt=""/>}
                                    renderOnZeroPageCount={null}
                                />
                            )

                        }

                    </div>
                </div>
            );
        }

        return (
            <div className="activityEmptySec">
                {intl.formatMessage({id: 'there_is_no_activity_to_show'})}
            </div>
        );
    }
}
