const engNumbers = [/0/g, /1/g, /2/g, /3/g, /4/g, /5/g, /6/g, /7/g, /8/g, /9/g];
const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

export default function ToPersianNumber(str) {
  if (typeof str !== 'undefined' && str !== null && str !== undefined) {
    str.toString();
    str = String(str);
    if (typeof str === 'string') {
      for (let i = 0; i < persianNumbers.length; i++) {
        str = str.replace(engNumbers[i], persianNumbers[i]);
      }
    }
  }
  return str;
}
