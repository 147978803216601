import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  const isPop = useBackButton();

  const scrollToTop = () => {
    // document.querySelector('root').scroll(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
    // document.querySelector('.homePage').scrollTo(0, 0);

    document.getElementById('body')?.scrollIntoView({ behavior: 'smooth' });
    // document.getElementById('body')?.scrollIntoView({ behavior: 'smooth' });
  };

  // const scrollToTop = () => window.scrollTo(0, 0);
  // const scrollToTop = () => document.querySelector('body').scrollTo(0, 0);

  useEffect(() => {
    console.log(pathname?.includes('/nfts'));
    if (pathname?.includes('/nfts')) {
      scrollToTop();
    }
  }, [pathname, isPop]);

  useEffect(() => {
    if (pathname?.includes('/nfts')) {
      window.addEventListener('beforeunload', scrollToTop);
    }
    return () => {
      window.removeEventListener('beforeunload', scrollToTop);
    };
  }, []);
};
