import React from 'react';
import '../../../assets/styles/general/General.scss';

import { useSelector } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { selectShowHeaderDrawer, selectShowExploreDrawer, selectShowFollowDrawer } from '../../../redux/slices/showDrawersSlice';
import { selectShowCreateModal, selectShowCreateModalAfterLogin } from '../../../redux/slices/createNFTSlice';
import { selectIsLogin } from '../../../redux/slices/accountSlice';

export default function Drawer({ Content, name, placement }) {
  const isLogin = useSelector(selectIsLogin);

  const showCreateNFTModal = useSelector(selectShowCreateModal);
  const showCreateNFTModalAfterLogin = useSelector(selectShowCreateModalAfterLogin);

  const CreateNFTShowHandler = () => {
    if (showCreateNFTModal || (isLogin && showCreateNFTModalAfterLogin)) {
      useSelector(selectShowCreateModalAfterLogin);
    }
  };

  const show = (name === 'HeaderDrawer')
    ? useSelector(selectShowHeaderDrawer)
    : (name === 'followDrawer')
      ? useSelector(selectShowFollowDrawer)
      : (name === 'createNFTDrawer')
        ? (!!(showCreateNFTModal || (isLogin && showCreateNFTModalAfterLogin)))
        : useSelector(selectShowExploreDrawer);

  // hideDrawer
  return (
  // <div
  //   id=""
  // // change this
  //   className={show ? 'bg-body drawer-on basicDrawer drawer-end' : 'drawer-end hideDrawer'}
  // // className={show ? 'bg-body drawer-on drawer drawer-end' : ' drawer drawer-end'}
  //   data-kt-drawer={!show}
  //   data-kt-drawer-activate="true"
  //   data-kt-drawer-overlay="true"
  //   data-kt-drawer-direction="end"
  // >
  //   {Content}
  // </div>

    <Offcanvas
      className="drawerComponent"
      show={show}
      placement={placement || 'start'}
    >
      {Content}
    </Offcanvas>
  );
}
