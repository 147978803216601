import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocation} from 'react-router';

import {useIntl} from 'react-intl';

import {Helmet} from 'react-helmet';

import '../../../assets/styles/Nft/Nft.scss';
import {useDispatch, useSelector} from 'react-redux';
import DesktopGeneralSec from '../../../components/sport/nft/DesktopGeneralSec.jsx';
import MobileGeneralSec from '../../../components/sport/nft/MobileGeneralSec.jsx';
import {getWindowSize} from '../../../helpers/getWindowSize.jsx';
import api from '../../../api.js';
import {
    selectIsLogin,
    selectShowSharePopover,
    selectUserData,
    setShowLogin,
    setShowSharePopover,
} from '../../../redux/slices/accountSlice';

import Skeleton from '../../../components/sport/general/Skeleton.jsx';
import {selectLanguage} from '../../../redux/slices/languageSlice';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import {setShowToast, setToastMessage, setToastStatus} from '../../../redux/slices/toastSlice';
import UtilityAccordion from '../../../components/sport/nft/UtilityAccordion';

import FullScreenLoadingBackDrop from '../../../components/sport/nft/FullScreenLoadingBackDrop';

import ItemActivity from '../../../components/sport/nft/ItemActivity.jsx';
import EditionsTable from '../../../components/sport/nft/EditionsTable';

const SharePopover = React.lazy(() => import('../../../components/sport/general/SharePopover.jsx'));

export default function Nft() {
    const {id, editionId} = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    const languageRedux = useSelector(selectLanguage);
    const isLogin = useSelector(selectIsLogin);
    const navigate = useNavigate();

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openDes, setOpenDes] = useState(true);
    const [openDetail, setOpenDetail] = useState(false);
    const [openActivity, setOpenActivity] = useState(true);
    const [openEdition, setOpenEdition] = useState(true);
    const [moment, setMoment] = useState({});

    const [utility, setUtility] = useState({});
    const [tags, setTags] = useState([]);
    const [isMomentStatusChanged, setIsMomentStatusChanged] = useState(false);

    const [isFavorite, setIsFavorite] = useState(false);
    const [isCommentFavorite, setIsCommentFavorite] = useState(false);
    const [selectedEdition, setSelectedEdition] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [playMode, setPlayMode] = useState(false);

    const [isUtilityHiddenForOwner, setIsUtilityHiddenForOwner] = useState(true);
    const [isTransferTokenLoading, setIsTransferTokenLoading] = useState(false);

    const [editionsData, setEditionsData] = useState([]);
    const [editionsPage, setEditionsPage] = useState(1);

    const [transfers, setTransfers] = useState({
        pages: null,
        transferCount: null,
        transfers: [],
    });

    const [loadingBackdrop, setLoadingBackdrop] = useState(false);
    const [loadingTransfer, setLoadingTransfer] = useState(false);
    const loaderTransferRef = useRef(null);

    const [transferPage, setTransferPage] = useState(1);

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const matches5 = (windowSize?.innerWidth <= 575);
    const matches4 = (windowSize?.innerWidth <= 920);

    const isMobile = (windowSize?.innerWidth <= 550);

    const userData = useSelector(selectUserData);
    const location = useLocation();
    const showPopover = useSelector(selectShowSharePopover);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(()=>{
    //   if(editionId){
    //     console.log("selected edition changed ", editionId)
    //     setTransferPage(1);
    //   //  reset transfer page to 1th page when user selected another edition
    //   }
    // }, [editionId])


    const callTransfer = async (selectedItem, selectedPage) => {
        console.log("selected itemn to check if it has NFT or not  ", selectedItem)
        if (moment && selectedItem?.nft) {
            setLoadingTransfer(true);
            await api.get(`moments/transfers/${selectedItem?.nft?.id}`, {
                params: {
                    page: selectedPage ? selectedPage : 1,
                },
            }).then(({data}) => {
                // console.log(`transfers for edition ${findSelectedEdition?.nft?.id}`, data?.transfers);
                if (data.transfers) {
                    setTransfers(data);
                    // setTransfers((prevState) => ({
                    //   pages: data?.pages,
                    //   transfersCount: data?.transferCount,
                    //   transfers: [
                    //     ...prevState.transfers,
                    //     ...data.transfers,
                    //   ],
                    // }));
                }
                setLoadingTransfer(false);
            }).catch(({response}) => {
                console.log(response);
            });
        }
    }



    const handleTransferPageClick = (event) => {
        setTransferPage(event.selected + 1);
        callTransfer(moment?.type === "edition" ? selectedEdition : moment, event.selected + 1);
    };


    const callTags = async () => {
        await api.get(`/moments/${id}/tags`).then(({data}) => {
            setTags(data?.tags);
        }).catch(({response}) => {
            console.log(response);
        });
    }


    const callEditionListData = async (selectedPage) => {
        await api.get(`/moments/${id}/editions?page=${selectedPage ? selectedPage : 1}&items=4`).then(({data}) => {
            setLoadingBackdrop(false);
            if ((editionId === null) || (editionId === undefined)) {
                // findSelectedEdition = data?.editions[0];
                setSelectedEdition(data?.editions[0]);
            }
            setEditionsData(data);
        }).catch(({response}) => {
            console.log(response);
            setLoadingBackdrop(false);
        });
    }


    const handleEditionPageClick = (event) => {
        setEditionsPage(event.selected + 1);
        setLoadingBackdrop(true);
        callEditionListData(event.selected + 1);
    };

    useEffect(() => {
        let findSelectedEdition;
        const fetchMoment = async () => {
            console.log("fetch moment base data")
            await api.get(`moments/${id}`).then(({data}) => {
                setMoment(data.moment);
                setPageLoading(false);

                if (data?.moment?.type === 'edition') {
                    callEditionListData();
                }
                // if (data?.moment?.type === 'edition') {
                //     if ((editionId !== null) || (editionId !== undefined)) {
                //         // eslint-disable-next-line no-shadow
                //         api.get(`moments/${id}/${editionId}`).then(({data}) => {
                //             // console.log(data?.moment?.edition, ' the edition in url');
                //             findSelectedEdition = data?.moment?.edition;
                //             setSelectedEdition(data?.moment?.edition);
                //
                //             callUtility("edition", data?.moment?.edition);
                //
                //             if (data?.moment?.edition?.nft) {
                //                 setLoadingTransfer(true);
                //                 console.log("ghv  ", data?.moment?.edition?.nft)
                //                 callTransfer(data?.moment?.edition, 1);
                //             }
                //         });
                //     }
                //     // callEditionListData(findSelectedEdition);
                // } else {
                if (data?.moment?.type == "single") {
                    if (data?.moment?.nft) {
                        // the moment type is "single"
                        setLoadingTransfer(true);
                        // callTransfer(data?.moment, 1);
                    }
                    // callUtility("single", data?.moment);
                }
                // }
            }).catch(({response}) => {
                if (response?.data && response?.data?.message === "Cannot read properties of null (reading 'users')") {
                    navigate('/');
                }

                if (response?.data?.message === 'Moment not found!: EACCESS') {
                    navigate('/');
                }
            });
            await callTags();
        }
        fetchMoment();
    }, [isFavorite, isCommentFavorite, isLogin, isMomentStatusChanged]);


    const callSelectedEdition = async () => {
        let findSelectedEdition;
        if (moment && moment?.type === 'edition') {
            if ((editionId !== null) || (editionId !== undefined)) {
                // eslint-disable-next-line no-shadow
                await api.get(`moments/${id}/${editionId}`).then(({data}) => {
                    // console.log(data?.moment?.edition, ' the edition in url');
                    findSelectedEdition = data?.moment?.edition;
                    setLoadingBackdrop(false);
                    setSelectedEdition(data?.moment?.edition);
                });
            }
        }

        return findSelectedEdition;
        // callEditionListData(findSelectedEdition);
        // }
    }


    useEffect(() => {
        let findSelectedEdition;
        console.log("moment data ", moment)
        console.log(Object?.keys(moment)?.length > 0)
        if (Object?.keys(moment)?.length > 0) {
            // if ((editionId !== null) || (editionId !== undefined)) {
            // eslint-disable-next-line no-shadow
            // api.get(`moments/${id}/${editionId}`).then(({data}) => {
            //     // console.log(data?.moment?.edition, ' the edition in url');
            //     findSelectedEdition = data?.moment?.edition;
            //     setSelectedEdition(data?.moment?.edition);
            //     if (data?.moment?.edition?.nft) {
            //         setLoadingTransfer(true);
            //         console.log("ghv  ", data?.moment?.edition?.nft)
            //     }
            // });

            const callUtility = async (type, selectedItem) => {
                if (type === "edition") {
                    await api.get(`moments/editions/${selectedItem?.id}/util`).then(({data}) => {
                        setUtility(data?.utility);
                        if (data?.utility?.code) {
                            setIsUtilityHiddenForOwner(false);
                        } else {
                            setIsUtilityHiddenForOwner(true);
                        }
                    }).catch(({response}) => {
                        // this moment doesnt have utility
                        setUtility({});
                        setIsUtilityHiddenForOwner(false);
                    });
                } else {
                    await api.get(`/moments/${selectedItem?.id}/util`).then(({data}) => {
                        setUtility(data?.utility);

                        if (data?.utility?.code) {
                            setIsUtilityHiddenForOwner(false);
                        } else {
                            setIsUtilityHiddenForOwner(true);
                        }
                    }).catch(({response}) => {
                        // this moment doesnt have utility
                        setUtility({});
                        setIsUtilityHiddenForOwner(false);
                    });
                }
            }

            const callThisUseEffectFunctions = async () => {
                const selectedEdition = await callSelectedEdition(); // Call the async function to get selectedEdition

                console.log("selected edition ", selectedEdition)

                console.log("type?? ",moment && moment?.type)

               await callUtility(moment && moment?.type, moment?.type === "edition" ? selectedEdition : moment);

                await   callTransfer(moment && moment?.type === "edition" ? selectedEdition : moment);
            }

            callThisUseEffectFunctions()
            // }
        }
    }, [moment, editionId])


    const loadMoreItems = () => {
        if (loadingTransfer) return;
        setLoadingTransfer(true);
        setTimeout(() => {
            //   // setItems((prevItems) => [...prevItems, ...generateData(20, prevItems.length)]);
            setTransferPage(((prevState) => prevState + 1));
            //   // setLoadingTransfer(false);
        }, 1500);
    };
    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            api.get(`/moments/${id}/is-favorite`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }).then(({data}) => {
                setIsFavorite(data.isFav);
            });
        }
    }, [isFavorite]);

    const shareOnInstagram = () => {

    };

    // eslint-disable-next-line consistent-return
    const telegramUrlHandler = () => {
        if (editionsData?.editions?.length > 0) {
            return `https://t.me/share/url?url=${window?.location?.origin}/nfts/${id}/editions/${selectedEdition?.id}`;
        }

        if (editionsData?.editions?.length === 0 || !editionsData?.editions) {
            return `https://t.me/share/url?url=${window?.location?.origin}/nfts/${id}`;
        }
    };

    // eslint-disable-next-line consistent-return
    const shareOnWhatsapp = () => {
        if (editionsData?.editions?.length > 0) {
            return `https://wa.me/?text=${window?.location?.origin}/nfts/${id}/editions/${selectedEdition?.id}`;
        }

        if (editionsData?.editions?.length === 0 || !editionsData?.editions) {
            return `https://wa.me/?text=${window?.location?.origin}/nfts/${id}`;
        }
    };

    const copy = () => {
        if (editionsData?.editions?.length > 0) {
            navigator.clipboard.writeText(`${window?.location?.origin}/nfts/${moment?.id}/editions/${selectedEdition?.id}`);
        } else {
            navigator.clipboard.writeText(`${window?.location?.origin}/nfts/${moment?.id}`);
        }
        dispatch(setShowToast(true));
        dispatch(setToastMessage(`${intl.formatMessage({id: 'copied'})}!`));
        dispatch(setToastStatus(1));
    };

    // eslint-disable-next-line consistent-return
    const twitterUrlHandler = () => {
        if (editionsData?.editions?.length > 0) {
            if (localStorage.getItem('accessToken')) {
                if (userData?.username === selectedEdition?.owner?.username) {
                    return `https://twitter.com/share?text=thats mine edition&url=${window?.location?.origin}/nfts/${id}/editions/${selectedEdition?.id}`;
                }

                return `https://twitter.com/share?text=text goes here&url=${window?.location?.origin}/nfts/${id}/editions/${selectedEdition?.id}`;
            }
            return `https://twitter.com/share?text=text goes here&url=${window?.location?.origin}/nfts/${id}/editions/${selectedEdition?.id}`;
        }
        if (editionsData?.editions?.length === 0 || !editionsData?.editions) {
            if (localStorage.getItem('accessToken')) {
                if (userData.username === moment?.owner?.username) {
                    return `https://twitter.com/share?text=thats mine!&url=${window?.location?.origin}/nfts/${id}`;
                }
                return `https://twitter.com/share?text=text goes here&url=${window?.location?.origin}/nfts/${id}`;
            }
            return `https://twitter.com/share?text=text goes here&url=${window?.location?.origin}/nfts/${id}`;
        }
    };

    const handleClose = () => {
        setOpenBackdrop(false);
    };

    const makeFavorite = useCallback(() => {
        if (localStorage.getItem('accessToken')) {
            api.post(`moments/${moment?.id}/favorite`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }).then(({data}) => {
                setIsFavorite(!isFavorite);
            }).catch(({error}) => {
            });
        } else {
            dispatch(setShowLogin(true));
        }
    }, [moment]);

    return (
        <div className="nftPage" onClick={() => dispatch(setShowSharePopover(false))}>

            {
                tags?.length > 0
                && (
                    <Helmet>
                        <meta name="keywords" content={tags.map((tag) => tag.name)}/>
                    </Helmet>
                )
            }

            <div className={openBackdrop ? 'nftBackdrop' : 'hideBackdrop'} onClick={handleClose}>
                <div className="backdropCloseSec">
                    <img src="/icons/closeVectorGray.svg" alt="" onClick={handleClose}/>
                </div>
                <div className="fullScreenWrapper">
                    <div
                        src={moment?.image ? moment?.image : moment?.campaign?.image}
                        className={`fullScreenImg ${!moment?.image && 'blur'}`}
                        style={{backgroundImage: `url(${moment?.image ? moment?.image : moment?.campaign?.image})`}}
                    />
                    {
                        !moment?.image
                        && (
                            <div className="fullScreenName">
                                {moment?.title}
                            </div>
                        )
                    }
                </div>
            </div>

            <FullScreenLoadingBackDrop isTransferTokenLoading={isTransferTokenLoading}/>

            <div className="pageWrapper container">

                <div className="campaignWrapper">

                    <div className="nftPageTitleSec">
                        <div className="nftNameSec">
                            <div className="nftPagePartsTopSec">
                                <div className="hr"/>
                                <strong className="campaignText">
                                    {intl.formatMessage({id: 'nft'})}
                                </strong>
                            </div>
                            <div className="nftName">
                                {
                                    pageLoading

                                        ? (
                                            <>
                                                <Skeleton style={{minWidth: 150}} className=""/>
                                                {
                                                    editionsData?.edtions?.length > 0
                                                    && <Skeleton style={{}} className="editionSelectDesktop"/>
                                                }
                                            </>
                                        )
                                        : (
                                            <div>
                                                {languageRedux === 'fa' ? ToPersianNumber(moment?.title) : moment?.title}
                                                {editionsData?.editions?.length > 0 && '/'}
                                                {editionsData?.editions?.length > 0 && intl.formatMessage({id: 'edition'})}
                                                {' '}
                                                {
                                                    editionsData?.editions?.length > 0
                                                    && languageRedux === 'fa'
                                                        ? ToPersianNumber(selectedEdition?.number)
                                                        : selectedEdition?.number
                                                }
                                            </div>
                                        )

                                }
                            </div>
                        </div>
                        <div className="nftPageLinkSec">
                            <div className="nameIconSec">

                                <div className="shareSec">
                                    {
                                        pageLoading
                                            ? <Skeleton style={{}} className="redirectImg"/>
                                            : (
                                                <div
                                                    className="shareWrapper"
                                                    onClick={(e) => {
                                                        if (isMobile) {
                                                            if (navigator.share) {
                                                                if (editionsData?.editions?.length > 0) {
                                                                    if (localStorage.getItem('accessToken')) {
                                                                        if (userData.username === selectedEdition.owner.username) {
                                                                            // return `https://twitter.com/share?text=thats mine edition&url=https://mymoment.app/nfts/${id}/editions/${moment?.momentEditions[0]?.id}`;
                                                                            return navigator.share({
                                                                                url: `${window?.location?.origin}/nfts/${id}/editions/${selectedEdition?.id}`,
                                                                                title: '',
                                                                                text: 'Check it out !',
                                                                            });
                                                                        }
                                                                        // return `https://twitter.com/share?text=text goes here&url=https://mymoment.app/nfts/${id}/editions/${moment?.momentEditions[0]?.id}`;

                                                                        return navigator.share({
                                                                            url: `${window?.location?.origin}/nfts/${id}/editions/${selectedEdition?.id}`,
                                                                            title: '',
                                                                            text: 'Check it out !',
                                                                        });
                                                                    }
                                                                    // return `https://twitter.com/share?text=text goes here&url=https://mymoment.app/nfts/${id}/editions/${moment?.momentEditions[0]?.id}`;
                                                                    return navigator.share({
                                                                        url: `${window?.location?.origin}/nfts/${id}/editions/${selectedEdition?.id}`,
                                                                        title: '',
                                                                        text: 'Check it out !',
                                                                    });
                                                                }
                                                                if (editionsData?.editions?.length === 0  || !editionsData?.editions) {
                                                                    if (localStorage.getItem('accessToken')) {
                                                                        if (userData.username === moment.owner?.username) {
                                                                            // return `https://twitter.com/share?text=thats mine!&url=https://mymoment.app/nfts/${id}`;
                                                                            return navigator.share({
                                                                                url: `${window?.location?.origin}/nfts/${id}`,
                                                                                title: '',
                                                                                text: 'Check it out !',
                                                                            });
                                                                        }
                                                                        // return `https://twitter.com/share?text=text goes here&url=https://mymoment.app/nfts/${id}`;
                                                                        return navigator.share({
                                                                            url: `${window?.location?.origin}/nfts/${id}`,
                                                                            title: '',
                                                                            text: 'Check it out !',
                                                                        });
                                                                    }
                                                                    // return `https://twitter.com/share?text=text goes here&url=https://mymoment.app/nfts/${id}`;

                                                                    return navigator.share({
                                                                        url: `${window?.location?.origin}/nfts/${id}`,
                                                                        title: '',
                                                                        text: 'Check it out!',
                                                                    });
                                                                }
                                                            } else {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                dispatch(setShowSharePopover(!showPopover));
                                                            }
                                                        } else {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            dispatch(setShowSharePopover(!showPopover));
                                                        }
                                                    }}
                                                >
                                                    <div className="nftPageShareTitle">
                                                        {intl.formatMessage({id: 'share'})}
                                                    </div>
                                                    <img
                                                        src="/icons/share.svg"
                                                        alt=""
                                                        className="redirectImg"
                                                        id="button"
                                                    />
                                                </div>
                                            )
                                    }
                                    <SharePopover
                                        moment={moment}
                                        twitterUrlHandler={twitterUrlHandler}
                                        telegramUrlHandler={telegramUrlHandler}
                                        shareOnInstagram={shareOnInstagram}
                                        copy={copy}
                                        shareOnWhatsapp={shareOnWhatsapp}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="NftPageCampaignSec">
                        <div className="generalSec" id="generalSec">
                            {
                                (!matches5 && !matches4)
                                    ? (
                                        <DesktopGeneralSec
                                            makeFavorite={makeFavorite}
                                            isFavorite={isFavorite}
                                            moment={moment}
                                            setOpenBackdrop={setOpenBackdrop}
                                            pageLoading={pageLoading}
                                            playMode={playMode}
                                            setPlayMode={setPlayMode}
                                            setIsMomentStatusChanged={setIsMomentStatusChanged}
                                            isTransferTokenLoading={isTransferTokenLoading}
                                            setIsTransferTokenLoading={setIsTransferTokenLoading}
                                            transfers={transfers}
                                            editions={editionsData?.editions}
                                            selectedEdition={selectedEdition}
                                            setSelectedEdition={setSelectedEdition}
                                        />
                                    )
                                    : (
                                        <MobileGeneralSec
                                            makeFavorite={makeFavorite}
                                            isFavorite={isFavorite}
                                            moment={moment}
                                            setOpenBackdrop={setOpenBackdrop}
                                            pageLoading={pageLoading}
                                            playMode={playMode}
                                            setPlayMode={setPlayMode}
                                            setIsMomentStatusChanged={setIsMomentStatusChanged}
                                            isTransferTokenLoading={isTransferTokenLoading}
                                            setIsTransferTokenLoading={setIsTransferTokenLoading}
                                            transfers={transfers}
                                            editions={editionsData?.editions}
                                            selectedEdition={selectedEdition}
                                            setSelectedEdition={setSelectedEdition}
                                        />
                                    )
                            }
                        </div>
                        <div className="detailSec">
                            <div className="detail">

                                {
                                    pageLoading
                                        ? (
                                            <>
                                                <Skeleton style={{height: 63}} className="accordion"/>
                                                <Skeleton style={{height: 63}} className="accordion"/>
                                                <Skeleton style={{height: 63}} className="accordion"/>
                                                <Skeleton style={{height: 63}} className="accordion"/>
                                            </>
                                        )
                                        : (
                                            <>

                                                {
                                                    editionsData?.editions?.length > 0
                                                    && (
                                                        <div className="accordion">
                                                            <div
                                                                className="accordionSummary"
                                                                onClick={() => setOpenEdition(!openEdition)}
                                                            >
                                                                <div className="summeryImageSec">
                                                                    <img
                                                                        src="/icons/detail.svg"
                                                                        alt=""
                                                                        className="accordionImg"
                                                                    />
                                                                    <span>
                                                            نسخه‌های دارایی دیجیتال
                                                                        {' '}
                                                          </span>
                                                                </div>
                                                                <img
                                                                    src="/icons/vectorUp.svg"
                                                                    alt=""
                                                                    className={openEdition ? 'vectorUp' : 'vectorDown'}
                                                                />
                                                            </div>
                                                            <EditionsTable
                                                                openEdition={openEdition}
                                                                editionsData={editionsData}
                                                                setEditionsData={setEditionsData}
                                                                editionsPage={editionsPage}
                                                                setEditionsPage={setEditionsPage}
                                                                setSelectedEdition={setSelectedEdition}
                                                                loadingBackdrop={loadingBackdrop}
                                                                setLoadingBackdrop={setLoadingBackdrop}
                                                                handlePageClick={handleEditionPageClick}
                                                            />
                                                        </div>

                                                    )
                                                }

                                                {
                                                    Object.keys(utility)?.length > 0
                                                    && (
                                                        <UtilityAccordion
                                                            moment={moment}
                                                            utility={utility}
                                                            setUtility={setUtility}
                                                            isUtilityHiddenForOwner={isUtilityHiddenForOwner}
                                                            setIsUtilityHiddenForOwner={setIsUtilityHiddenForOwner}
                                                            selectedEdition={selectedEdition}
                                                        />
                                                    )
                                                }

                                                <div className="accordion">
                                                    <div className="accordionSummary" onClick={() => setOpenDes(!openDes)}>
                                                        <div className="summeryImageSec">
                                                            <img
                                                                src="/icons/description.svg"
                                                                alt=""
                                                                className="accordionImg"
                                                            />
                                                            <span>{intl.formatMessage({id: 'nft_description'})}</span>
                                                        </div>
                                                        <img
                                                            src="/icons/vectorUp.svg"
                                                            alt=""
                                                            className={openDes ? 'vectorUp' : 'vectorDown'}
                                                        />
                                                    </div>

                                                    {
                                                        openDes
                                                        && (
                                                            <div className="accordionDetails">
                                                                <div
                                                                    className="description"
                                                                    dangerouslySetInnerHTML={{__html: moment?.description}}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                <div className="accordion">
                                                    <div
                                                        className="accordionSummary"
                                                        onClick={() => setOpenDetail(!openDetail)}
                                                    >
                                                        <div className="summeryImageSec">
                                                            <img src="/icons/detail.svg" alt="" className="accordionImg"/>
                                                            <span>{intl.formatMessage({id: 'detail'})}</span>
                                                        </div>
                                                        <img
                                                            src="/icons/vectorUp.svg"
                                                            alt=""
                                                            className={openDetail ? 'vectorUp' : 'vectorDown'}
                                                        />
                                                    </div>
                                                    {
                                                        openDetail
                                                        && (
                                                            <div className="accordionDetails">

                                                                <div className="itemsRow">
                                                                    <div className="detailText">Contract Address</div>
                                                                    {
                                                                        matches5
                                                                            ? (
                                                                                <div className="detailText">
                                                                                    {
                                                                                        editionsData?.editions?.length > 0 ? !selectedEdition?.nft?.contractAddress ? '-'
                                                                                                : `${selectedEdition?.nft?.contractAddress.slice(0, 4)}...${selectedEdition?.nft?.contractAddress.slice(-4)}`
                                                                                            : !moment?.nft?.contractAddress ? '-' : `${moment?.nft?.contractAddress.slice(0, 4)}...${moment?.nft?.contractAddress.slice(-4)}`
                                                                                    }
                                                                                </div>
                                                                            )
                                                                            : (
                                                                                <div className="detailText">
                                                                                    {
                                                                                        editionsData?.editions?.length > 0 ? !selectedEdition?.nft?.contractAddress ? '-' : selectedEdition?.nft?.contractAddress
                                                                                            : !moment?.nft?.contractAddress ? '-' : moment?.nft?.contractAddress
                                                                                    }
                                                                                </div>
                                                                            )
                                                                    }
                                                                </div>

                                                                <div className="itemsRow">
                                                                    <div className="detailText">Token ID</div>
                                                                    <div className="detailText">
                                                                        {
                                                                            editionsData?.editions?.length > 0 ? !selectedEdition?.nft?.tokenId ? '-' : selectedEdition?.nft?.tokenId
                                                                                : !moment?.nft?.tokenId ? '-' : moment?.nft?.tokenId
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="itemsRow">
                                                                    <div className="detailText">Standard</div>
                                                                    <div className="detailText">
                                                                        {
                                                                            editionsData?.editions?.length > 0 ? !selectedEdition?.nft?.standard ? ' -' : selectedEdition?.nft?.standard
                                                                                : !moment?.nft?.standard ? '-' : moment?.nft?.standard
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="itemsRow">
                                                                    <div className="detailText">Chain</div>
                                                                    <div className="detailText">
                                                                        {
                                                                            editionsData?.editions?.length > 0
                                                                                ? (selectedEdition?.nft == null || !selectedEdition.nft?.chain?.name)
                                                                                    ? '-' : selectedEdition.nft?.chain?.name
                                                                                : !moment?.chain?.name ? '-' : moment?.chain?.name
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="itemsRow">
                                                                    <div className="detailText">Metadata</div>
                                                                    <div className="detailText">
                                                                        {
                                                                            // eslint-disable-next-line no-nested-ternary
                                                                            editionsData?.editions?.length > 0
                                                                                ? !selectedEdition?.nft
                                                                                    // || !selectedEdition.nft?.metadataState)
                                                                                    ? '-' : selectedEdition.nft?.metadataState
                                                                                : !moment?.nft?.metadataState ? '-' : moment?.nft?.metadataState
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                <div className="accordion">
                                                    <div
                                                        className="accordionSummary"
                                                        onClick={() => setOpenActivity(!openActivity)}
                                                    >
                                                        <div className="summeryImageSec">
                                                            <img src="/icons/transfer.svg" alt="" className="accordionImg"/>
                                                            <span>
                                                  {intl.formatMessage({id: 'item_activity'})}
                                                                {' '}
                                                </span>
                                                        </div>
                                                        <img
                                                            src="/icons/vectorUp.svg"
                                                            alt=""
                                                            className={openActivity ? 'vectorUp' : 'vectorDown'}
                                                        />
                                                    </div>
                                                    <ItemActivity
                                                        openActivity={openActivity}
                                                        transfers={transfers}
                                                        loadMoreItems={loadMoreItems}
                                                        loaderTransferRef={loaderTransferRef}
                                                        transferPage={transferPage}
                                                        setTransferPage={setTransferPage}
                                                        loadingTransfer={loadingTransfer}
                                                        setLoadingTransfer={setLoadingTransfer}
                                                        handlePageClick={handleTransferPageClick}
                                                    />
                                                </div>
                                            </>
                                        )
                                }

                                {/* { */}
                                {/*                    pageLoading */}
                                {/*                      ? <Skeleton style={{ height: 63 }} className="lastAccordion" /> */}
                                {/*                      : ( */}
                                {/*                        <Comment */}
                                {/*                          moment={moment} */}
                                {/*                          setMoment={setMoment} */}
                                {/*                          isCommentFavorite={isCommentFavorite} */}
                                {/*                          setIsCommentFavorite={setIsCommentFavorite} */}
                                {/*                          momentId={id} */}
                                {/*                        /> */}
                                {/*                      ) */}
                                {/*                } */}

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
