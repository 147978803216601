import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {selectLanguage} from '../../../redux/slices/languageSlice';
import PersianDate from '../general/PersianDate.jsx';
import {ShowUserName} from '../general/ShowUserName.jsx';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import Skeleton from '../general/Skeleton.jsx';
import addCommas from '../../../helpers/addCommas.jsx';
import ToEnglishNumber from '../../../helpers/toEnglishNumber.jsx';
import api from '../../../api.js';
import {getWindowSize} from '../../../helpers/getWindowSize.jsx';

export default function EditionsTable({
                                          openEdition,
                                          editionsData,
                                          editionsPage,
                                          setEditionsPage,
                                          setSelectedEdition,
                                          setEditionsData,
                                          loadingBackdrop,
                                          setLoadingBackdrop,
                                          handlePageClick
                                      }) {
    const languageRedux = useSelector(selectLanguage);
    const navigate = useNavigate();
    const {id, editionId} = useParams();


    const [windowSize, setWindowSize] = useState(getWindowSize());
    const matches = (windowSize?.innerWidth <= 972);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    // const handleObserver = (entities) => {
    //   const target = entities[0];
    //   if (target.isIntersecting && transferPage < transfers?.pages) {
    //     loadMoreItems();
    //   } else {
    //     setLoadingTransfer(false);
    //   }
    // };
    //
    // useEffect(() => {
    //   const observer = new IntersectionObserver(handleObserver);
    //   if (loaderTransferRef?.current) {
    //     observer.observe(loaderTransferRef.current);
    //   }
    //   return () => {
    //     if (loaderTransferRef?.current) {
    //       observer.unobserve(loaderTransferRef.current);
    //     }
    //   };
    // }, [handleObserver]);

    const ShowPriceHandler = ({item}) => {
        if (item?.isForSale) {
            if (languageRedux === 'fa') {
                return `${ToPersianNumber(String(addCommas(item?.listingPrice)))} تومان`;
            }

            return `${ToEnglishNumber(String(addCommas(item?.listingPrice)))} تومان`;
        }

        return 'غیر قابل خرید';
    };

    const selectEditionHandler = (item) => {
        console.log("editionId ", typeof editionId)
        console.log("item ", typeof item?.id)
        if (item?.id !== parseInt(editionId)) {
            // if user clicked on the edition that is not already selected
            setLoadingBackdrop(true);
            navigate(`/nfts/${id}/editions/${item?.id}`);
            setSelectedEdition(item);
        }
    };

    function ShowBuyBtnHandler({edition}) {
        if (edition?.isForSale) {
            return (
                <td className="from">
                    <button className="editionsTableBuyBtn">
                        خرید
                    </button>
                </td>
            );
        }
    }

    if (openEdition) {
        // if (transfers?.transfers?.length > 0) {
        return (
            <div>
                <div className="accordionDetailsEditionList">

                    <div className={loadingBackdrop ? 'editionSectionsBackdrop' : 'hideBackdrop'}>

            <span className="editionsLoadingSec">
              <span
                  className="spinner-border spinner-border-md align-middle ms-2"
              />
            </span>

                    </div>

                    <table className="table">
                        <thead>

                        <tr className="tableHeader">
                            <th>شماره نسخه</th>
                            <th>مالک</th>
                            <th>عملیات</th>
                            <th>قیمت</th>
                        </tr>

                        </thead>

                        <tbody>

                        {
                            editionsData?.editions?.map((item) => (
                                <tr style={{cursor: 'pointer'}} onClick={() => selectEditionHandler(item)}>
                                    <td>
                                        {`${languageRedux === 'fa' ? ToPersianNumber(item?.number) : item?.number} `}
                                    </td>

                                    <td className="successTransferTd">
                                        <ShowUserName user={item?.owner}/>
                                    </td>

                                    <ShowBuyBtnHandler edition={item}/>

                                    <td className="from">
                                        <ShowPriceHandler item={item}/>
                                    </td>

                                </tr>
                            ))
                        }

                        </tbody>

                    </table>

                    {/* <div */}
                    {/*  ref={loaderTransferRef} */}
                    {/*  className="loadingTransferSec" */}
                    {/* > */}
                    {/*  { */}
                    {/*                      loadingTransfer */}
                    {/*                      && ( */}
                    {/*                      <Skeleton */}
                    {/*                        style={{ borderRadius: 10, height: 73 }} */}
                    {/*                        className="loadingTransferSec" */}
                    {/*                      /> */}
                    {/*                      ) */}
                    {/*                  } */}

                    {/* </div> */}

                    {
                        editionsData?.pages > 1
                        && (
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<img src="/icons/arrowLeft.svg" alt=""/>}
                                onPageChange={handlePageClick}
                                pageLabelBuilder={((page) => {
                                    if (languageRedux === 'fa') {
                                        return ToPersianNumber(page);
                                    }
                                    if (languageRedux !== 'fa') {
                                        return page;
                                    }
                                })}
                                forcePage={editionsPage - 1}
                                pageRangeDisplayed={matches ? 1 : 4} // Adjust based on screen width
                                marginPagesDisplayed={1}
                                pageCount={editionsData.pages}
                                className="editionListPagination"
                                activeClassName="activePage"
                                previousLabel={<img src="/icons/arrowRight.svg" alt=""/>}
                                renderOnZeroPageCount={null}

                            />
                        )
                    }

                </div>
            </div>
        );

        // }

        // return (
        //   <div className="activityEmptySec">
        //     {intl.formatMessage({ id: 'there_is_no_activity_to_show' })}
        //   </div>
        // );
    }
}
