import React from 'react';

export const urlHandler = (moment) => {
  // if (moment.isSold) {
  if (moment?.type === 'single') {
    return `/nfts/${moment.id}`;
  }
  if (moment?.type === 'edition') {
    const firstEdition = moment?.momentEditions.find(ed => ed.number === 1);
    if(firstEdition){
      return `/nfts/${moment.id}/editions/${firstEdition?.id}`;
    }
    else{
      return `/nfts/${moment.id}/editions/${moment?.momentEditions[0]?.id}`;
    }

  }
  // } else {
  //   return `/campaigns/${moment.campaign.title}/${moment.campaignId}`;
  // }
};
