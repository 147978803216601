import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import '../../../assets/styles/Nft/Nft.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfilePicture from '../other/ProfilePicture';
import EditionSelect from './EditionSelect';
import { ShowUserName } from '../general/ShowUserName';
import { selectLanguage } from '../../../redux/slices/languageSlice';
import PersianDate from '../general/PersianDate';
import Skeleton from '../general/Skeleton.jsx';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import NftImage from './NftImage';
import ActionsButtons from './ActionsButtons.jsx';

export default function MobileGeneralSec({
  setOpenBackdrop,
  moment,
  isFavorite,
  makeFavorite,
  pageLoading,
  playMode,
  setPlayMode,
  setIsMomentStatusChanged,
  setIsTransferTokenLoading,
  isTransferTokenLoading,
  transfers,
  editions,
                                           selectedEdition,
                                           setSelectedEdition}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const languageRedux = useSelector(selectLanguage);

  let date;
  let year;
  let month;
  let day;

  let profileSvgDiv;
  useEffect(() => {
    if (document.getElementById('profileSvg')) {
      profileSvgDiv = document.getElementById('profileSvg');
      const gTag = profileSvgDiv.getElementsByTagName('g')[0];
      gTag?.setAttribute('mask', 'none');
    }
  }, [moment]);

  if (localStorage.getItem('i18nConfig')) {
    if (localStorage.getItem('i18nConfig').includes('fa')) {
      date = new PersianDate(moment?.momentOccurrence);
      year = date.getYear();
      month = date.getMonth();
      day = date.getDate();
    }
    if (localStorage.getItem('i18nConfig').includes('en') || localStorage.getItem('i18nConfig').includes('tr')) {
      date = new Date(moment?.momentOccurrence);
      year = date.getFullYear();
      month = date.getMonth();
      day = date.getDate();
    }
  } else {
    localStorage.setItem('i18nConfig', JSON.stringify({ selectedLang: 'en' }));
    date = new Date(moment?.momentOccurrence);
    year = date.getFullYear();
    month = date.getMonth();
    day = date.getDate();
  }
  return (
    <div className="general">
      {
                pageLoading
                  ? <Skeleton style={{}} className="campaignImg" />
                  : (
                    <NftImage
                      moment={moment}
                      playMode={playMode}
                      setPlayMode={setPlayMode}
                      setOpenBackdrop={setOpenBackdrop}
                    />
                  )
            }
      <div className="likesRow">
        <div style={{ display: 'flex' }}>

          {
                        pageLoading
                          ? <Skeleton style={{ height: 24, width: 84 }} className="" />
                          : (
                            <div style={{ display: 'flex' }}>
                              <img src={moment.chain?.logo} alt="" />
                              <div className="chainName">{moment.chain?.name}</div>
                            </div>
                          )
                    }

        </div>

        {
                    pageLoading
                      ? <Skeleton style={{ height: 28 }} className="likeSec" />
                      : (
                        <div className="likeSec">
                          {/* <div className="likeIcon" onClick={makeFavorite}> */}
                          {/*  <img src={isFavorite ? '/icons/liked.svg' : '/icons/like.svg'} alt="" /> */}
                          {/*  {ToPersianNumber(moment?.favoritesCount)} */}
                          {/* </div> */}

                          {/* <div> */}
                          {/*  <img src="/icons/comment.svg" alt="" /> */}
                          {/*  {ToPersianNumber(String(moment?.comments?.length))} */}
                          {/* </div> */}

                        </div>
                      )
                }
      </div>

      {/* <div> */}
      {/*  { */}
      {/*              moment?.momentEditions?.length > 0 */}
      {/*                ? pageLoading */}
      {/*                  ? <Skeleton style={{ height: 38 }} className="editionSelectMobile" /> */}
      {/*                  : ( */}
      {/*                    <EditionSelect */}
      {/*                      moment={moment} */}
      {/*                      isTop={false} */}
      {/*                      selectedEdition={selectedEdition} */}
      {/*                      setSelectedEdition={setSelectedEdition} */}
      {/*                    /> */}
      {/*                  ) : '' */}
      {/*          } */}
      {/* </div> */}
      {
                pageLoading
                  ? <Skeleton style={{}} className="priceSec" />
                  : (
                    <ActionsButtons
                      moment={moment}
                      setIsMomentStatusChanged={setIsMomentStatusChanged}
                      transfers={transfers}
                      isTransferTokenLoading={isTransferTokenLoading}
                      setIsTransferTokenLoading={setIsTransferTokenLoading}
                      editions={editions}
                      selectedEdition={selectedEdition}
                      setSelectedEdition={setSelectedEdition}
                    />
                  )
            }
      {
                moment?.clubs?.length > 0
                && moment?.clubs?.slice(0, 2).map((club) => (
                  <Link to={`/clubs/${club?.id}`}>
                    <div className="itemsRow" style={{ cursor: 'pointer' }}>
                      {
                                pageLoading
                                  ? (
                                    <>
                                      <Skeleton style={{ height: 24, width: 84 }} className="rowTitle" />
                                      <Skeleton style={{ height: 24 }} className="answer" />
                                    </>
                                  )
                                  : (
                                    <>
                                      <strong className="rowTitle">
                                        {intl.formatMessage({ id: 'club' })}
                                        :
                                      </strong>
                                      <strong
                                        className="answer"
                                      >
                                        {club?.name ? club?.name : '-'}
                                      </strong>
                                    </>
                                  )
                            }
                    </div>

                  </Link>
                ))
            }

      {
                pageLoading
                  ? (
                    <div className="itemsRow">
                      <Skeleton style={{ height: 24, width: 84 }} className="rowTitle" />
                      <Skeleton style={{ height: 24 }} className="ownerAnswer" />
                    </div>
                  )
                  : (
                    <div className="itemsRow">
                      <strong className="rowTitle">
                        {intl.formatMessage({ id: 'owned_by' })}
                        :
                      </strong>
                      <div
                        className="ownerAnswer"
                      >
                        {
                                    moment?.owner === null
                                      ? selectedEdition?.owner?.image
                                        ? (
                                          <div
                                            className="ownerImage"
                                            style={{ backgroundImage: `url(${selectedEdition?.owner?.image})` }}
                                          />
                                        )
                                        : (
                                          <div id="profileSvg" className="profileSvg">
                                            <ProfilePicture colors={selectedEdition?.owner?.profileColors} />
                                          </div>
                                        )
                                      : moment.owner?.image
                                        ? (
                                          <div
                                            className="ownerImage"
                                            style={{ backgroundImage: `url(${moment.owner?.image})` }}
                                          />
                                        )
                                        : (
                                          <div id="profileSvg" className="profileSvg">
                                            <ProfilePicture colors={moment.owner?.profileColors} />
                                          </div>
                                        )
                                }
                        <strong>
                          <ShowUserName
                            user={moment?.owner === null ? selectedEdition?.owner : moment?.owner}
                          />
                        </strong>
                      </div>
                    </div>

                  )
            }

      {
                pageLoading
                  ? (
                    <div className="itemsRow">

                      <Skeleton style={{ height: 24, width: 84 }} className="rowTitle" />
                      <Skeleton style={{ height: 24 }} className="answer" />
                    </div>
                  )
                  : moment?.momentOccurrence && (
                    <div className="itemsRow">
                      <strong className="rowTitle">
                        {intl.formatMessage({ id: 'creation_date' })}
                        :
                      </strong>
                      <strong className="answer">
                        {
                                languageRedux === 'fa'
                                  ? `${ToPersianNumber(String(day))} / ${ToPersianNumber(String(month + 1))} / ${ToPersianNumber(String(year))}`
                                  : `${year} / ${month + 1} / ${day}`
                            }
                      </strong>
                    </div>
                  )
            }

    </div>
  );
}
