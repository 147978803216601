import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import '../../../assets/styles/User/User.scss';
import {
    Route, Routes, useLocation, useParams, useSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import api from '../../../api.js';
import ProfilePicture from '../../../components/sport/other/ProfilePicture';
import EditModal from '../../../components/sport/user/EditModal';
import {ShowUserName} from '../../../components/sport/general/ShowUserName';
import FollowModalAndDrawer from '../../../components/sport/user/followSec/index';
import {
    selectCreditCode,
    selectEmailOrPhoneValidated,
    selectIsLogin, selectShowSharePopover, setCreditCode, setIsLogin, setLoadingSummary,
    setShowLogin,
    setShowSharePopover, setUserData,
} from '../../../redux/slices/accountSlice.tsx';
import PersianDate from '../../../components/sport/general/PersianDate';
import {selectLanguage} from '../../../redux/slices/languageSlice.tsx';
import {setShowFollowDrawer} from '../../../redux/slices/showDrawersSlice.tsx';
import SharePopover from '../../../components/sport/general/SharePopover';
import Skeleton from '../../../components/sport/general/Skeleton';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';

import GiftTab from '../../../components/sport/user/giftTab';

import {
    selectShowToast, setShowToast, setToastMessage, setToastStatus,
} from '../../../redux/slices/toastSlice.tsx';
import UnfollowModal from '../../../components/sport/user/followSec/UnfollowModal';
import TransactionTab from '../../../components/sport/user/transactionTab';
import CollectedTab from '../../../components/sport/user/nftTab/CollectedTab';
import CreatedTab from '../../../components/sport/user/nftTab/CreatedTab';
import FavoritesTab from '../../../components/sport/user/nftTab/FavoritesTab';
import FilterAndSortSec from '../../../components/sport/user/FilterAndSortSec.jsx';
import addCommas from '../../../helpers/addCommas.jsx';
import {getWindowSize} from '../../../helpers/getWindowSize.jsx';
import WithdrawRequestModal from '../../../components/sport/user/WithdrawRequestModal';
import IncreaseCreditPopup from '../../../components/sport/general/IncreaseCreditPopup.jsx';
import {Web3Modal} from "../../../Web3Modal.jsx";

const ShareReferralCodeModal = React.lazy(() => import('../../../components/sport/user/ShareReferralCodeModal'));

export default function User() {
    const intl = useIntl();
    const location = useLocation();
    const {username, tab} = useParams();
    const isEmailOrPhoneValidated = useSelector(selectEmailOrPhoneValidated);
    const lsUsername = localStorage.getItem('username');

    const showPopover = useSelector(selectShowSharePopover);

    const creditCode = useSelector(selectCreditCode);

    const dispatch = useDispatch();
    const showToast = useSelector(selectShowToast);
    const [edit, setEdit] = React.useState(false);
    const [userDatas, setUserDatas] = useState({
        user: {
            walletAddresses: [],
        },
    });
    const [isMyFollowing, setIsMyFollowing] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [showUnFollowModal, setShowUnFollowModal] = useState(false);
    const [followStatusChanged, setFollowStatusChanged] = useState(false);

    const [momentType, setMomentType] = useState(0);
    const [sort, setSort] = useState('createdAt,DESC');

    const [tabContentLoading, setTabContentLoading] = useState(false);

    const [openSort, setOpenSort] = useState(false);

    const [referralUrl, setReferralUrl] = useState('');

    const [isReferralLoading, setIsReferralLoading] = useState('');

    const [showReferralModal, setShowReferralModal] = useState(false);

    const [showWithdrawRequestModal, setShowWithdrawRequestModal] = useState(false);

    const [showInsertCreditPopup, setShowInsertCreditPopup] = useState(false);

    const [userCredit, setUserCredit] = useState([]);

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const isMobile = (windowSize?.innerWidth <= 550);
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    const languageRedux = useSelector(selectLanguage);
    const isLogin = useSelector(selectIsLogin);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (localStorage.getItem('accessToken')) {
            api.get('/profile/summary', {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }).then(({data}) => {
                localStorage.setItem('username', data?.user?.username);
                dispatch(setLoadingSummary(false));
                dispatch(setUserData(data.user));

                setUserCredit(data.user.credit);
            }).catch(({error}) => {
                dispatch(setLoadingSummary(false));
                dispatch(setIsLogin(false));
                localStorage.removeItem('accessToken');
                localStorage.removeItem('user');
                localStorage.removeItem('loginType');
            });
        }

        api.get(`/users/${username}`).then((r) => {
            setUserDatas(r.data);
            setPageLoading(false);
        });

        if (localStorage.getItem('accessToken')) {
            api.get(`/profile/${username}/is-following`).then(({data}) => {
                setIsMyFollowing(data.hasFollowed);
            });
        }
    }, [location, isLogin, followStatusChanged, isEmailOrPhoneValidated]);

    let profileSvgDiv;
    useEffect(() => {
        if (document.getElementById(userDatas?.user.id)) {
            profileSvgDiv = document.getElementById(userDatas?.user.id);
            const gTag = profileSvgDiv.getElementsByTagName('g')[0];
            gTag?.setAttribute('mask', 'none');
        }
    }, [location, userDatas]);

    useEffect(() => {
        if (creditCode) {
            setShowInsertCreditPopup(true);
            dispatch(setCreditCode(''));
        }
    }, []);

    const JoinedDate = () => {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December',
        ];

        const persianMonthNames = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور',
            'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند',
        ];

        const joined = new Date(userDatas.user.createdAt);
        const persianJoined = new PersianDate(userDatas.user.createdAt);

        if (languageRedux === 'en' || languageRedux === 'tr') {
            return `Joined ${monthNames[joined.getMonth()]} ${joined.getFullYear()}`;
        }

        return ` عضو شده در ${persianMonthNames[persianJoined.getMonth()]} ${ToPersianNumber(persianJoined.getYear())}`;
    };

    const followUser = () => {
        if (localStorage.getItem('accessToken')) {
            setIsMyFollowing(!isMyFollowing);
            api.post(`/profile/${username}/follow`).then(({data: response}) => {
                setFollowStatusChanged(!followStatusChanged);
            }).catch(({error}) => {
                setIsMyFollowing(false);
            });
            setShowUnFollowModal(false);
        } else {
            dispatch(setShowLogin(true));
        }
    };

    const followUserHandler = () => {
        if (isMyFollowing) {
            setShowUnFollowModal(true);
        } else {
            followUser();
        }
    };

    const twitterUrlHandler = () => `https://twitter.com/share?url=https://mymoment.app/users/${username}`;

    const telegramUrlHandler = () => `https://t.me/share/url?url=https://mymoment.app/users/${username}`;

    const shareOnInstagram = () => {

    };

    const shareOnWhatsapp = () => `https://wa.me/?text=https://mymoment.app/users/${username}`;

    const copyToShare = () => {
        navigator.clipboard.writeText(`https://mymoment.app/users/${username}`);
        dispatch(setShowToast(true));
        dispatch(setToastMessage(`${intl.formatMessage({id: 'copied'})}!`));
        dispatch(setToastStatus(1));
    };

    const generateReferralCode = () => {
        if (referralUrl) {
            setShowReferralModal(true);
        } else {
            setIsReferralLoading(true);
            api.get('/profile/referral-code').then(({data}) => {
                console.log(data);
                setReferralUrl(data?.url);
                setIsReferralLoading(false);
                setShowReferralModal(true);
            }).catch(({response}) => {
                console.log(response);
                setIsReferralLoading(false);

                if (!response?.data?.message) {
                    dispatch(setShowToast(true));
                    dispatch(setToastMessage(`${intl.formatMessage({id: 'try_again'})}!`));
                    dispatch(setToastStatus(0));
                }
            });
        }
    };

    return (
        // <Web3Modal>
        <div onClick={() => {dispatch(setShowSharePopover(false));}}>
            <div
                className="userProfile container"
            >
                <UnfollowModal
                    show={showUnFollowModal}
                    followUser={() => followUser()}
                    setShow={setShowUnFollowModal}
                    user={userDatas?.user}
                />
                {
                    (userDatas?.user?.username && !pageLoading)
                    && (
                        <FollowModalAndDrawer
                            followStatusChanged={followStatusChanged}
                            setFollowStatusChanged={setFollowStatusChanged}
                        />
                    )
                }

                <div className="partsTopSec">
                    <div className="hr"/>
                    <strong className="profileText">
                        {intl.formatMessage({id: 'profile'})}
                    </strong>
                </div>
                <div className="usernameSec">
                    {
                        pageLoading
                            ? (
                                <>
                                    <Skeleton style={{height: 45, width: 120}} className="userName"/>
                                    <div className="nameIconSec">
                                        <div className="shareSec">
                                            <Skeleton style={{}} className="redirectImg"/>
                                        </div>
                                        <Skeleton style={{}} className="moreImg"/>
                                    </div>

                                </>
                            )
                            : (
                                <>
                                    <div className={`userName ${languageRedux === 'fa' ? 'ltrDirection' : 'rtlDirection'}`}>
                                        <ShowUserName user={userDatas?.user}/>
                                    </div>
                                    <div className="nameIconSec">
                                        <div className="shareSec">
                                            <img
                                                src="/icons/share.svg"
                                                alt=""
                                                onClick={(e) => {
                                                    if (isMobile) {
                                                        if (navigator.share) {
                                                            navigator.share({
                                                                url: `https://mymoment.app/users/${username}`,
                                                                title: 'Sharing Cool things',
                                                                text: 'Check it out !',
                                                            })
                                                                .then(() => {
                                                                })
                                                                .catch((error) => {
                                                                    console.log('Sharing Failed');
                                                                });
                                                        } else {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            dispatch(setShowSharePopover(!showPopover));
                                                        }
                                                    } else {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        dispatch(setShowSharePopover(!showPopover));
                                                    }
                                                }}
                                                className="redirectImg"
                                            />

                                            <SharePopover
                                                twitterUrlHandler={twitterUrlHandler}
                                                telegramUrlHandler={telegramUrlHandler}
                                                shareOnInstagram={shareOnInstagram}
                                                shareOnWhatsapp={shareOnWhatsapp}
                                                copy={copyToShare}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                    }
                </div>
                <div>
                    <div className="userDetailSec">
                        {
                            pageLoading
                                ? (
                                    <Skeleton className="profileImage" style={{}}/>
                                )
                                : userDatas.user.image
                                    ? (
                                        <div
                                            className="profileImg"
                                            style={{backgroundImage: `url(${userDatas.user.image})`}}
                                        />
                                    )
                                    : (
                                        <div className="profileImage" id={userDatas?.user?.id}>
                                            <ProfilePicture
                                                size={150}
                                                colors={userDatas.user.profileColors}
                                            />
                                        </div>
                                    )
                        }
                        <div className="userPageDetailSec">
                            {
                                pageLoading
                                    ? (
                                        <>
                                            <Skeleton style={{height: 26}} className="description"/>
                                            <Skeleton style={{height: 28, maxWidth: 200}} className="date"/>
                                            <div className="followSec">
                                                <div className="userBtnSec">
                                                    <Skeleton className="walletSec"/>
                                                    <Skeleton className="btn followedBtn"/>
                                                </div>
                                                <Skeleton style={{height: 45, width: 170}} className="userBtnSec"/>
                                            </div>
                                        </>
                                    )
                                    : (
                                        <>
                                            <div className="description">
                                                {userDatas.user.bio}
                                            </div>
                                            <div className="date">
                                                <JoinedDate/>
                                            </div>
                                            <div className="followSec">
                                                <div className="userBtnSec">
                                                    {/* {userDatas.user.walletAddresses.length > 0 */}
                                                    {/*              && ( */}
                                                    {/*              <div className="walletSec"> */}
                                                    {/*                <img src="/icons/polygon.svg" alt="" /> */}
                                                    {/*                {userDatas.user?.walletAddresses[0]?.address.slice(0, 4)} */}
                                                    {/*                ... */}
                                                    {/*                {userDatas.user?.walletAddresses[0]?.address.slice(7, 11)} */}
                                                    {/*              </div> */}
                                                    {/*              )} */}
                                                    {
                                                        lsUsername !== username
                                                            ? (
                                                                <button
                                                                    type="button"
                                                                    className={`btn ${isMyFollowing ? 'followedBtn' : 'followBtn'} `}
                                                                    onClick={followUserHandler}
                                                                >
                                                                    {!isMyFollowing && <img src="/icons/add.svg" alt=""/>}
                                                                    {
                                                                        isMyFollowing
                                                                            ? intl.formatMessage({id: 'following'})
                                                                            : intl.formatMessage({id: 'follow'})
                                                                    }
                                                                </button>
                                                            )
                                                            : (
                                                                <button
                                                                    type="button"
                                                                    className="btn followedBtn"
                                                                    onClick={() => setEdit(true)}
                                                                >
                                                                    <img
                                                                        src="/icons/sportCategory/edit.svg"
                                                                        alt=""
                                                                        className="editImg"
                                                                    />
                                                                    {intl.formatMessage({id: 'edit_profile'})}
                                                                </button>
                                                            )

                                                    }

                                                    {
                                                        lsUsername === username && userCredit
                                                        && (
                                                            <button
                                                                type="button"
                                                                className="btn followedBtn"
                                                                onClick={() => setShowWithdrawRequestModal(true)}
                                                            >
                                                                {intl.formatMessage({id: 'withdraw_request'})}
                                                            </button>
                                                        )
                                                    }

                                                    {
                                                        lsUsername === username
                                                        && (
                                                            <button
                                                                type="button"
                                                                className="btn increaseCreditBtn"
                                                                onClick={() => setShowInsertCreditPopup(true)}
                                                            >
                                                                {intl.formatMessage({id: 'increase_credit_btn'})}
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                                <div className="userFollowSec">
                                                    <div
                                                        className="followColumn"
                                                        onClick={() => {
                                                            dispatch(setShowFollowDrawer(true));
                                                        }}
                                                    >
                                                        <div>
                                                            {languageRedux === 'fa' ? ToPersianNumber(userDatas.user.following?.length) : userDatas.user.following?.length}
                                                        </div>
                                                        <div className="followText">
                                                            {intl.formatMessage({id: 'followings'})}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="lastFollowColumn"
                                                        onClick={() => {
                                                            dispatch(setShowFollowDrawer(true));
                                                        }}
                                                    >
                                                        <div>
                                                            {languageRedux === 'fa' ? ToPersianNumber(userDatas.user.followers?.length) : userDatas.user.followers?.length}
                                                        </div>
                                                        <div className="followText">
                                                            {intl.formatMessage({id: 'followers'})}
                                                        </div>
                                                    </div>
                                                    {/* <div */}
                                                    {/*  className="lastFollowColumn" */}
                                                    {/*  onClick={() => { */}
                                                    {/*    dispatch(setShowFollowDrawer(true)); */}
                                                    {/*  }} */}
                                                    {/* > */}
                                                    {/*  <div> */}
                                                    {/*    {languageRedux === 'fa' ? ToPersianNumber(0) : 0} */}
                                                    {/*  </div> */}
                                                    {/*  <div className="followTextCharity"> */}
                                                    {/*    {intl.formatMessage({ id: 'charities' })} */}

                                                    {/*  </div> */}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </>
                                    )
                            }
                            {
                                pageLoading
                                    ? (
                                        <>
                                            <Skeleton
                                                className="inventorySec"
                                                style={{height: 24, width: 100, borderRadius: 6}}
                                            />
                                            <Skeleton
                                                className="inventorySec"
                                                style={{height: 24, width: 100, borderRadius: 6}}
                                            />
                                        </>
                                    )
                                    : (userCredit && lsUsername === username)
                                    && (
                                        <div>
                                            <div className="inventorySec">
                                                <div className="inventoryTitleSec">
                                                    <div>{intl.formatMessage({id: 'your_inventory'})}</div>
                                                    <div>
                                                        :
                                                        {' '}
                                                        {' '}
                                                        {/* ta 18 ragham + comma okeye */}
                                                        {languageRedux === 'fa' ? ToPersianNumber(addCommas(userCredit.amount)) : addCommas(userCredit.amount)}
                                                        {' '}
                                                        {userCredit?.currency?.symbol}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="inventoryTitleSec">
                                                <div>{intl.formatMessage({id: 'withdrawable_amount'})}</div>
                                                <div>
                                                    :
                                                    {' '}
                                                    {' '}
                                                    {/* ta 18 ragham + comma okeye */}
                                                    {languageRedux === 'fa' ? ToPersianNumber(addCommas(userCredit.amount - userCredit.nonWithdrawable)) : addCommas(userCredit.amount - userCredit.nonWithdrawable)}
                                                    {' '}
                                                    {userDatas?.user?.credit?.currency?.symbol}
                                                </div>
                                            </div>
                                            <div className="inventoryTitleSec marginNone">
                                                <div>{intl.formatMessage({id: 'your_score'})}</div>
                                                <div>
                                                    :
                                                    {' '}
                                                    {' '}
                                                    {/* ta 18 ragham + comma okeye */}
                                                    {languageRedux === 'fa' ? ToPersianNumber(addCommas(userCredit.score)) : addCommas(userCredit.score)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    {
                        pageLoading
                            ? (
                                <>
                                    <Skeleton style={{height: 18}} className="dateMob"/>
                                    <Skeleton style={{height: 18}} className="descriptionMob"/>
                                </>
                            ) : (
                                <>
                                    <div className="dateMob">
                                        <JoinedDate/>
                                    </div>
                                    <div className="descriptionMob">
                                        {userDatas.user.bio}
                                    </div>
                                </>
                            )
                    }

                    {
                        pageLoading
                            ? (
                                <div className="inviteFriendsSec">
                                    <Skeleton className="inviteFriendsBtn"/>
                                </div>
                            )
                            : lsUsername === username
                            && (
                                <div className="inviteFriendsSec">
                                    <button
                                        type="button"
                                        className="inviteFriendsBtn"
                                        onClick={generateReferralCode}
                                        disabled={isReferralLoading}
                                    >
                                        {
                                            isReferralLoading
                                                ? (
                                                    <span
                                                        className="indicator-progress referralLoadingProgress"
                                                        style={{display: 'block'}}
                                                    >
                                                  <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                                                </span>
                                                )
                                                : (
                                                    <>
                                                        {intl.formatMessage({id: 'invite-friends'})}
                                                        <img src="/icons/invite-freinds.png" alt=""/>
                                                    </>
                                                )
                                        }

                                    </button>

                                    <ShareReferralCodeModal
                                        show={showReferralModal}
                                        setShow={setShowReferralModal}
                                        referralUrl={referralUrl}
                                    />

                                    {/* { */}
                                    {/*       referralUrl */}
                                    {/*       && ( */}
                                    {/*       <div className="referralCodeCopySec"> */}
                                    {/*         <div className="referralCode"> */}
                                    {/*           /!* https://mymoment.app/?reffer=12345 *!/ */}
                                    {/*           {referralUrl} */}
                                    {/*         </div> */}
                                    {/*         <img */}
                                    {/*           src="/icons/copy.png" */}
                                    {/*           alt="" */}
                                    {/*           onClick={copyReferralUrl} */}
                                    {/*           className="copyRefferalCodeImg" */}
                                    {/*         /> */}
                                    {/*       </div> */}
                                    {/*       ) */}
                                    {/*   } */}

                                </div>
                            )
                    }

                    <FilterAndSortSec
                        tabContentLoading={tabContentLoading}
                        momentType={momentType}
                        setSort={setSort}
                        setMomentType={setMomentType}
                        openSort={openSort}
                        setOpenSort={setOpenSort}
                    />
                    <Routes>
                        <Route>
                            <Route
                                path="/collected"
                                element={(
                                    <CollectedTab
                                        sort={sort}
                                        userDatas={userDatas}
                                        tabContentLoading={tabContentLoading}
                                        setTabContentLoading={setTabContentLoading}
                                    />
                                )}
                            />
                            <Route
                                path="/favorites"
                                element={(
                                    <FavoritesTab
                                        sort={sort}
                                        userDatas={userDatas}
                                        tabContentLoading={tabContentLoading}
                                        setTabContentLoading={setTabContentLoading}
                                    />
                                )}
                            />
                            {
                                lsUsername === username
                                && (
                                    <>
                                        <Route
                                            path="/gifts"
                                            element={(
                                                <GiftTab
                                                    sort={sort}
                                                    userDatas={userDatas}
                                                    tabContentLoading={tabContentLoading}
                                                    setTabContentLoading={setTabContentLoading}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/transaction"
                                            element={(
                                                <TransactionTab
                                                    sort={sort}
                                                    userDatas={userDatas}
                                                    tabContentLoading={tabContentLoading}
                                                    setTabContentLoading={setTabContentLoading}
                                                />
                                            )}
                                        />
                                    </>
                                )
                            }
                            <Route
                                index
                                path="/*"
                                element={(
                                    <CollectedTab
                                        sort={sort}
                                        userDatas={userDatas}
                                        tabContentLoading={tabContentLoading}
                                        setTabContentLoading={setTabContentLoading}
                                    />
                                )}
                            />
                        </Route>
                    </Routes>

                </div>
                {
                    isLogin
                    && (
                        <EditModal
                            setUserDatas={setUserDatas}
                            show={edit}
                            setShow={setEdit}
                            user={userDatas.user}
                            setPageLoading={setPageLoading}
                        />
                    )
                }

                {
                    isLogin && userDatas?.user?.credit
                    && <WithdrawRequestModal show={showWithdrawRequestModal} setShow={setShowWithdrawRequestModal}/>
                }

                <IncreaseCreditPopup
                    show={showInsertCreditPopup}
                    setShow={setShowInsertCreditPopup}
                    setProfileStatusChanged={setFollowStatusChanged}
                />

            </div>
        </div>
            // </Web3Modal>

            );
}
