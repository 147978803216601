import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import '../../../../assets/styles/layout/footer/Footer.scss';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import api from '../../../../api.js';
import {
  selectShowToast, setShowToast, setToastMessage, setToastStatus,
} from '../../../../redux/slices/toastSlice';
import { setShowCreatedModalAfterLogin, setShowCreateModal } from '../../../../redux/slices/createNFTSlice';
import { setShowLogin } from '../../../../redux/slices/accountSlice';

export default function Footer({ footerProps }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const showToast = useSelector(selectShowToast);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const { pathname } = useLocation();

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    api.post('/newsletters', { email }).then(({ data }) => {
      setLoading(false);

      setEmail('');
    }).catch(({ response }) => {
      setLoading(false);

      if (response?.data?.email) {
        dispatch(setShowToast(true));
        dispatch(setToastMessage(response?.data?.email?.msg));
        dispatch(setToastStatus(0));
      }

      setEmail('');
    });
  };

  // const createNftBtnAction = () => {
  //   if (window.localStorage.getItem('accessToken')) {
  //     dispatch(setShowCreateModal(true));
  //   } else {
  //     dispatch(setShowLogin(true));
  //     dispatch(setShowCreatedModalAfterLogin(true));
  //   }
  // };

  console.log(window.location.origin);
  return (
    <footer className={`footerSec footer ${pathname?.includes('/waitlist') ? 'darkModeFooter' : ''}`} id="footer">

      <div className="container">
        <div className="footerFirstSec">

          <div className="logoSec">

            <Link
              to={footerProps?.type?.id === 0 ? '/' : '/family'}
              aria-label="footer link to Home Page"
            >
              {/* <div */}
              {/*  id="footerLogo" */}
              {/*  style={{ */}
              {/*    backgroundImage: `url(${footerProps?.type?.id === 0 */}
              {/*      ? '/logos/sport/footerLogo.png' */}
              {/*      : '/logos/momentFamily.png'})`, */}
              {/*  }} */}
              {/*  className="logo" */}
              {/* /> */}

              <img
                id="footerLogo"
                src={pathname?.includes('/waitlist')
                  ? '/logos/sport/darkFooterLogo.png'
                  : '/logos/sport/footerLogo.png'}
                className="logo"
                loading="lazy"
              />

            </Link>

            <div className="footerDes">
              <div>
                {intl.formatMessage({ id: 'moment_sport_des' })}

              </div>
            </div>

          </div>

          <div className="linkSecDesk">

            <div className="linkColumn">
              <strong className="linksTitle">
                {intl.formatMessage({ id: 'links' })}
              </strong>

              {
                                footerProps?.links?.map((link) => {
                                  if (link?.title === intl.formatMessage({ id: 'blogs' })) {
                                    return (
                                      <strong className="link" key={link?.id}>
                                        <div>
                                          <a
                                            href="https://mag.mymoment.app"
                                            aria-label="link to mag"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {link?.title}
                                          </a>
                                        </div>
                                      </strong>
                                    );
                                  }

                                  return (
                                    <strong className="link" key={link?.id}>
                                      <Link to={link?.url}>
                                        {link?.title}
                                      </Link>
                                    </strong>
                                  );
                                })
                            }

              {
                                footerProps?.support?.map((support) => (
                                  <strong className="link" key={support?.id}>
                                    <Link to={support?.url}>
                                      {support?.title}
                                    </Link>
                                  </strong>
                                ))
                            }

              {/* { */}
              {/*    footerProps?.type?.id === 0 */}
              {/*    && ( */}
              {/*    <strong className="link" onClick={createNftBtnAction}> */}
              {/*      {intl.formatMessage({ id: 'create_nft' })} */}
              {/*    </strong> */}
              {/*    ) */}
              {/* } */}

            </div>

            {/* desktop Enamad section */}

            {
                            window?.location?.origin === 'https://mymoment.app'
                              ? (
                                <div>
                                  {/* eslint-disable-next-line */}
                                        <a
                                          referrerPolicy="origin"
                                          target="_blank"
                                          href="https://trustseal.enamad.ir/?id=346580&amp;Code=m4DE7NJLSOQZliN32U1V"
                                          aria-label="link to moments enamad"
                                        >
                                          <img
                                            referrerPolicy="origin"
                                            src="https://Trustseal.eNamad.ir/logo.aspx?id=346580&amp;Code=m4DE7NJLSOQZliN32U1V"
                                            alt=""
                                            style={{ cursor: 'pointer' }}
                                            id="m4DE7NJLSOQZliN32U1V"
                                          />
                                        </a>
                                </div>
                              )
                              : window?.location?.origin === 'https://momentsport.app' && (
                                <div>
                                    {/* eslint-disable-next-line */}
                                    <a
                                      referrerPolicy="origin"
                                      target="_blank"
                                      href="https://trustseal.enamad.ir/?id=521574&Code=L8pTcmPZ3IaiER2avcW1YLvUEOG0DXCu"
                                      aria-label="link to moment sports enamad"
                                    >
                                      <img
                                        referrerPolicy="origin"
                                        src="https://trustseal.enamad.ir/logo.aspx?id=521574&Code=L8pTcmPZ3IaiER2avcW1YLvUEOG0DXCu"
                                        alt=""
                                        style={{ cursor: 'pointer' }}
                                        id="L8pTcmPZ3IaiER2avcW1YLvUEOG0DXCu"
                                      />
                                    </a>
                                </div>
                              )
                        }

          </div>

          <div className="socialMediaSec">
            <div className="socialDes">
              {footerProps?.type?.id === 0 ? intl.formatMessage({ id: 'join_us_des' }) : intl.formatMessage({ id: 'join_us_des' })}
            </div>

            <div className="footerSignUpSec">

              <input
                type="text"
                className="footerInput"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="ایمیلت رو وارد کن!"
              />

              <button
                type="submit"
                id="kt_sign_in_submit"
                onClick={submit}
                className={footerProps?.type?.id === 0 ? 'newsletterSubmitBtn' : 'momentFamilyNewsletterSubmitBtn'}
                disabled={email?.length === 0 || !emailRegex.test(email)}
              >
                {loading
                  ? <Spinner animation="border" />
                  : intl.formatMessage({ id: 'submit_email' })}
              </button>

            </div>

            <div className="socialRow">

              {/* <a */}
              {/*  href="https://www.linkedin.com/company/mymoment" */}
              {/*  aria-label="link to linkdin" */}
              {/*  target="_blank" */}
              {/*  rel="noreferrer" */}
              {/* > */}
              {/*  <img */}
              {/*    src={pathname === ('/') ? '/icons/sportCategory/darkLinkdin.svg' : '/icons/linkdin.svg'} */}
              {/*    className="socialLogo" */}
              {/*    alt="" */}
              {/*  /> */}
              {/* </a> */}

              <a
                href="https://www.instagram.com/momentsportapp/"
                aria-label="link to instagram"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={pathname?.includes('/waitlist') ? '/icons/sportCategory/darkInstagram.svg' : '/icons/instagram.svg'}
                  className="socialLogo"
                  alt=""
                  loading="lazy"
                />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                                // href="https://wa.me/+989057260799"
                href="https://t.me/momentsportapp"
                aria-label="link to telegram"
              >
                <img
                  src={pathname?.includes('/waitlist') ? '/icons/sportCategory/darkTelegram.svg' : '/icons/telegram.svg'}
                  className="socialLogo"
                  alt=""
                  loading="lazy"
                />
              </a>

              <a
                href="mailto:info@mymoment.app"
                target="_blank"
                rel="noreferrer"
                aria-label="link to email"
              >
                <img
                  src={pathname?.includes('/waitlist') ? '/icons/sportCategory/darkEmail.svg' : '/icons/mail.svg'}
                  className="socialLogo"
                  alt=""
                  loading="lazy"
                />
              </a>

              {/* <a */}
              {/*  href="https://twitter.com/Mymoment_app" */}
              {/*  target="_blank" */}
              {/*  rel="noreferrer" */}
              {/*  aria-label="link to rubika" */}
              {/* > */}
              {/*<img*/}
              {/*  src={pathname?.includes('/waitlist') ? '/logos/sport/Rubika_Icon.png' : '/logos/sport/Rubika_Icon.png'}*/}
              {/*  className="socialLogo"*/}
              {/*  alt=""*/}
              {/*/>*/}
              {/* </a> */}

            </div>

          </div>

          <div className="linkSecMob">

            <div className="linkColumn">
              <strong className="linksTitle">
                {intl.formatMessage({ id: 'links' })}
              </strong>

              {
                                footerProps?.links?.map((link) => {
                                  if (link?.title === intl.formatMessage({ id: 'blogs' })) {
                                    return (
                                      <strong className="link" key={link?.id}>
                                        <div>
                                          <a
                                            href="https://mag.mymoment.app"
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label={link?.url}
                                          >
                                            {link?.title}
                                          </a>
                                        </div>
                                      </strong>
                                    );
                                  }

                                  return (
                                    <strong className="link" key={link?.id}>
                                      <Link to={link?.url} aria-label={link?.url}>
                                        {link?.title}
                                      </Link>
                                    </strong>
                                  );
                                })
                            }

              {
                                footerProps?.support?.map((support) => (
                                  <strong className="link" key={support?.id}>
                                    <Link to={support?.url} aria-label={support?.url}>
                                      {support?.title}
                                    </Link>
                                  </strong>
                                ))
                            }

              {/* { */}
              {/*    footerProps?.type?.id === 0 */}
              {/*    && ( */}
              {/*    <strong className="link" onClick={createNftBtnAction}> */}
              {/*      {intl.formatMessage({ id: 'create_nft' })} */}
              {/*    </strong> */}
              {/*    ) */}
              {/* } */}

            </div>

            {/* link section desktop */}

            {
                            window?.location?.origin === 'https://mymoment.app'
                              ? (
                                <div>
                                  {/* eslint-disable-next-line */}
                                        <a
                                          referrerPolicy="origin"
                                          target="_blank"
                                          href="https://trustseal.enamad.ir/?id=346580&amp;Code=m4DE7NJLSOQZliN32U1V"
                                          aria-label="link to moments enamad"
                                        >
                                          <img
                                            referrerPolicy="origin"
                                            src="https://Trustseal.eNamad.ir/logo.aspx?id=346580&amp;Code=m4DE7NJLSOQZliN32U1V"
                                            alt=""
                                            style={{ cursor: 'pointer' }}
                                            id="m4DE7NJLSOQZliN32U1V"
                                          />
                                        </a>
                                </div>
                              )
                              : window?.location?.origin === 'https://momentsport.app' && (
                                <div>
                                    {/* eslint-disable-next-line */}
                                    <a
                                      referrerPolicy="origin"
                                      target="_blank"
                                      href="https://trustseal.enamad.ir/?id=521574&Code=L8pTcmPZ3IaiER2avcW1YLvUEOG0DXCu"
                                      aria-label="link to moment sports enamad"
                                    >
                                      <img
                                        referrerPolicy="origin"
                                        src="https://trustseal.enamad.ir/logo.aspx?id=521574&Code=L8pTcmPZ3IaiER2avcW1YLvUEOG0DXCu"
                                        alt=""
                                        style={{ cursor: 'pointer' }}
                                        id="L8pTcmPZ3IaiER2avcW1YLvUEOG0DXCu"
                                      />
                                    </a>
                                </div>
                              )
                        }

          </div>

        </div>

        <div className={`copyRightSec ${pathname?.includes('/waitlist') ? 'darkModeFooter' : ''}`}>
          <div className="copyRightHr" />
          <strong>
            {intl.formatMessage({ id: 'copyRight' })}
          </strong>

          <div className="versioning">
            V1.0.6
          </div>
        </div>
      </div>

    </footer>
  );
}
