import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import { selectLanguage } from '../../../redux/slices/languageSlice';

import '../../../assets/styles/Faq/Faq.scss';
import { Link } from 'react-router-dom';
import LazyVideo from '../../../components/sport/other/LazyVideo.jsx';
// import VideoComponent from '../../../components/sport/other/VideoComponent.jsx';

export default function Learning() {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);

  return (
    <div className="container learningPage">

      <div className="learningVideoSec">

        <VideoComponent />

        {/* <LazyVideo */}
        {/*  className="learningVideo" */}
        {/*  src="https://moment-s3.s3.ir-thr-at1.arvanstorage.ir/public/static/p4final.mp4" */}
        {/*  poster="/p4finalPoster.png" */}
        {/*  controls */}
        {/*  playsInline */}
        {/* /> */}

        {/* <video className="learningVideo" controls playsInline poster="/p4finalPoster.png"> */}
        {/*  <source src="https://moment-s3.s3.ir-thr-at1.arvanstorage.ir/public/static/p4final.mp4" type="video/mp4" /> */}
        {/* </video> */}

        {/* <video className="learningVideo" controls playsInline poster="/p4finalPoster.png"> */}
        {/*  <source src="/p4final.mp4" type="video/mp4" /> */}
        {/* </video> */}

      </div>

      {/* <div className="contactUsWrapper"> */}

      <div className="contactTextSec">
        <div>
          {intl.formatMessage({ id: 'wanna_ask?' })}
        </div>

        <div>
          {intl.formatMessage({ id: 'you_can_contact' })}
        </div>
      </div>

      <div className="contactSec">

        <a href="tel:02188872482">
          <div className="contactRow">
            <img
              src="/icons/sportCategory/contact.svg"
              className="contactIcon"
              alt=""
            />
            <div>
              {intl.formatMessage({ id: 'phone' })}
              :
              {languageRedux === 'fa' ? ToPersianNumber('02188872482') : '02188872482'}

            </div>
          </div>
        </a>

        <div className="contactRow">
          <img
            src="/icons/sportCategory/location.svg"
            className="contactIcon"
            alt=""
          />
          <div>
            {intl.formatMessage({ id: 'address' })}

            :
            {languageRedux === 'fa' ? ToPersianNumber(intl.formatMessage({ id: 'address_information' })) : intl.formatMessage({ id: 'address_information' })}

          </div>
        </div>

        <a href="mailto:momentapp.contact@gmail.com" target="_blank" rel="noreferrer">
          <div className="contactRow">
            <img
              src="/icons/email.png"
              className="contactIcon"
              alt=""
            />
            <div>
              {intl.formatMessage({ id: 'email' })}

              :
              momentapp.contact@gmail.com

            </div>
          </div>
        </a>

        <div className="whatIsNftFinalDes">
          {intl.formatMessage({ id: 'go_to_faq_1' })}
          <Link to="/faq">
            <u>
              {intl.formatMessage({ id: 'what_is_nft_page_last_detail2' })}
            </u>
          </Link>

          {intl.formatMessage({ id: 'go_to_faq_last_part' })}

        </div>

      </div>

      {/* </div> */}

    </div>
  );
}

const VideoComponent = memo(() => {
  console.log('Greeting was rendered at', new Date().toLocaleTimeString());
  return (
    <video className="learningVideo" controls playsInline poster="/p4finalPoster.png">
      <source src="https://moment-s3.s3.ir-thr-at1.arvanstorage.ir/public/static/p4final.mp4" type="video/mp4" />
    </video>
  );
});
