import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export default function UtilityConfirmModal({ show, setShow, receiveUtility }) {
  const intl = useIntl();

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="warningModal  modal-dialog-centered"
      show={show}
            // onHide={handleClose}
      backdrop
    >
      <div className="unfollowModalBody">
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <div className="attentionTitle">
            {intl.formatMessage({ id: 'attention' })}
          </div>

          <div className="warningDes">
            {intl.formatMessage({ id: 'use_utility_description' })}
          </div>

          <div className="flexClass justify-content-between unfollowBtnSec">
            <button
              className="btn unfollowBtn"
                            // disabled={isLoading}
              onClick={receiveUtility}
            >
              {intl.formatMessage({ id: 'receive_utility' })}
              {/* { */}
              {/*    isLoading */}
              {/*      ? <Spinner animation="border" className="cancelSpinner" /> */}
              {/*      : intl.formatMessage({ id: 'receive_utility' }) */}
              {/*  } */}
            </button>
            <button
              className="btn warningModalCancelBtn"
                            // disabled={isLoading}
              onClick={() => setShow(false)}
            >
              {intl.formatMessage({ id: 'not_receive_utility' })}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
