import React, { useEffect, useState } from 'react';
import { Collapse, Modal, Spinner } from 'react-bootstrap';
import '../../../../assets/styles/Transfer/Transfer.scss';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import {
  useAccount, useDisconnect, useSignMessage,
} from 'wagmi';
// import { verifyMessage } from 'ethers/lib/utils.js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import api from '../../../../api.js';
import { getWindowSize } from '../../../../helpers/getWindowSize.jsx';
import { setShowToast, setToastMessage, setToastStatus } from '../../../../redux/slices/toastSlice';
import { Wallets } from '../Wallets.jsx';
import { selectLanguage } from '../../../../redux/slices/languageSlice';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import {polygon} from "wagmi/chains";

export default function TransferWallet({
  show, setShow, momentId, setIsMomentStatusChanged,
  moment, selectedEdition, setSelectedEdition,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const languageRedux = useSelector(selectLanguage);

  const { open: openWeb3Modal } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { isConnected } = useAccount();
  // const {
  //   chain,
  // } = useNetwork();

  const [wallets, setWallets] = useState([]);

  const [selectedItems, setSelectedItem] = useState({});
  const [openCollapse, setOpenCollapse] = useState(false);
  const [userDatas, setUserDatas] = useState({
    user: {
      walletAddresses: [],
    },
  });

  const [loading, setLoading] = useState(false);

  const [isUserRequestedAdd, setIsUserRequestedAdd] = useState(false);


  const myEditionsList = moment?.momentEditions?.filter((item) => item?.isOwner === true);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const matches = (windowSize?.innerWidth <= 972);

  useEffect(() => {
    if (show === true) {
      api.get('/profile/wallets').then(({ data }) => {
        setWallets(data?.wallets);
      });
      console.log("selectedEdition in transfer wallet  ", selectedEdition)
    }
  }, [show, userDatas]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const editionHandler = (event) => {
    const edition = moment?.momentEditions.find((item) => item.id === parseInt(event.target.value, 10));
    setSelectedEdition(edition);
  };

  // const {
  //   signMessage,
  // } = useSignMessage({
  //   onSuccess(data, variables) {
  //     const address = verifyMessage(variables.message, data);
  //     const chainId = chain.id;
  //     api.post('/profile/add-wallet', {
  //       address,
  //       signature: data,
  //       chainId,
  //     })
  //       .then((r) => {
  //         api.get('/profile/wallets').then(({ data: response }) => {
  //           setWallets(response?.wallets);
  //         });
  //       })
  //       .catch((e) => {
  //         disconnect();
  //       });
  //   },
  //   onError(e, e2) {
  //     disconnect();
  //   },
  // });

  const handleClose = () => {
    if (!loading) {
      setShow(false);
    }
  };

  // const deleteWallet = (id) => {
  //   api.delete(`/profile/remove-wallet/${id}`)
  //     .then(({ data }) => {
  //       setWallets(data.wallets);
  //
  //       // setUserDatas(
  //       //     (prevState) => (
  //       //         {
  //       //           ...prevState,
  //       //           user: {
  //       //             ...prevState.user,
  //       //             walletAddresses: r.data.wallets,
  //       //           },
  //       //         }
  //       //     ),
  //       // );
  //     });
  // };

  const transferToWallet = () => {
    setLoading(true);

    if (moment?.type === 'edition') {
      api.post(`/profile/transfer/editions/${selectedEdition?.id}/to-wallet`, {
        walletAddressId: selectedItems?.id,
      }).then(({ data }) => {
        setLoading(false);
        setShow(false);

        setIsMomentStatusChanged((prevState) => !prevState);
      }).catch(({ response }) => {
        setIsMomentStatusChanged((prevState) => !prevState);

        setLoading(false);
        if (!response?.data?.message) {
          dispatch(setShowToast(true));
          dispatch(setToastMessage(intl.formatMessage({ id: 'try_again' })));
          dispatch(setToastStatus(0));
        }
      });
    } else {
      api.post(`profile/transfer/${momentId}/to-wallet`, {
        walletAddressId: selectedItems?.id,
      }).then(({ data }) => {
        setLoading(false);
        setShow(false);

        setIsMomentStatusChanged((prevState) => !prevState);
      }).catch(({ response }) => {
        setIsMomentStatusChanged((prevState) => !prevState);

        setLoading(false);
        if (!response?.data?.message) {
          dispatch(setShowToast(true));
          dispatch(setToastMessage(intl.formatMessage({ id: 'try_again' })));
          dispatch(setToastStatus(0));
        }
      });
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {/* Simple tooltip */}
      {intl.formatMessage({ id: 'You_can_choose_from_the_wallets_in_your_profile' })}
    </Tooltip>
  );

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="transferWalletModal modal-dialog-centered"
      show={show}
      // onHide={handleClose}
      backdrop
    >
      <div className="modalHeader">
        <img
          src="/icons/close.svg"
          alt=""
          onClick={handleClose}
          className="close"
        />
      </div>

      <div className="transferImageSec">
        <img
          className="transferModalImage"
          src="/icons/sportCategory/wallet.svg"
          alt=""
        />
        <div>
          {intl.formatMessage({ id: 'transfer_to_wallet' })}
        </div>
      </div>

      {
        moment?.type === 'edition'
          ? (pathname?.includes('users/') && !loading)
          // user can select the edition she/he wants
            ? (
              <select
                className="form-select transferWalletEditionSelect"
                data-control="select2"
                onChange={editionHandler}
                value={selectedEdition?.id}
              >
                {
                        myEditionsList?.map((edition) => (
                          <option key={edition?.id} value={edition?.id}>
                            {intl.formatMessage({ id: 'edition' })}
                            {' '}
&nbsp;
                            {`${languageRedux === 'fa' ? ToPersianNumber(edition?.number) : edition?.number} `}
                          </option>
                        ))
                      }
              </select>
            )
            : (
              <div className="lockedTransferEditionSelect">
                <div>
                  {intl.formatMessage({ id: 'edition' })}
                  {' '}
                  &nbsp;
                  {`${languageRedux === 'fa' ? ToPersianNumber(selectedEdition?.number) : selectedEdition?.number} `}
                </div>
              </div>
            )
          : ''
      }

      <div className="walletSec">

        <div className="walletAddresses">
          <div
            className="walletsHeader"
            onClick={() => {
              // if (wallets.length > 0) {
              setOpenCollapse(!openCollapse);
              // }
            }}
          >
            {
              Object.keys(selectedItems).length > 0
                ? (
                  <div className="flexClass justify-content-between align-items-center">
                    <div className="walletItem">
                      <div className="flexClass align-items-center">
                        <img
                          src={selectedItems?.chain?.logo}
                          className="chainImg"
                          alt=""
                        />
                        <div>
                          {matches ? `${selectedItems?.address?.slice(0, 4)}...${selectedItems?.address?.slice(-4)}` : `${selectedItems?.address?.slice(0, 10)}...${selectedItems?.address?.slice(-10)}`}
                        </div>
                      </div>
                    </div>
                    <img
                      className="deleteSelectedWalletSvg"
                      src="/icons/trash.svg"
                      alt=""
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedItem({});
                      }}
                    />
                  </div>
                )
                : (
                  <>
                    <div>
                      {intl.formatMessage({ id: 'you_have' })}
                      {' '}
                      {wallets?.length}
                      {' '}
                      {intl.formatMessage({ id: 'wallet' })}
                      {(wallets.length > 1 && languageRedux === 'en') ? 's' : ''}
                    </div>
                    <img
                      src={openCollapse ? '/icons/vectorUp.svg' : '/icons/vectorDown.svg'}
                      className="vector"
                      alt=""
                    />
                  </>
                )
            }
          </div>
          <div className="walletsCollapse">
            <Collapse in={openCollapse}>
              <div className="walletPart">
                <Wallets
                  wallets={wallets}
                  setUserDatas={setUserDatas}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </Collapse>
          </div>
        </div>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <img src="/icons/about.svg" alt="" />
        </OverlayTrigger>
      </div>

      <div style={{ marginBottom: 40 }}>
        {intl.formatMessage({ id: 'see_more_info_1' })}
        <Link to="/faq">
          <strong>
            {intl.formatMessage({ id: 'faq' })}
          </strong>
        </Link>
        {intl.formatMessage({ id: 'see_more_info_2' })}

      </div>

      <button
        type="button"
        className="btn transferButton"
        disabled={Object.keys(selectedItems).length === 0 || loading}
        onClick={transferToWallet}
      >
        {loading ? <Spinner size="sm" animation="border" /> : intl.formatMessage({ id: 'transfer' })}
        {/* {intl.formatMessage({ id: 'transfer' })} */}
      </button>

    </Modal>
  );
}
