import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { setShowFollowDrawer } from '../../../../redux/slices/showDrawersSlice';
import ProfilePicture from '../../other/ProfilePicture.jsx';
import { ShowUserName } from '../../general/ShowUserName.jsx';

export default function List({
  activeTab,
  followings, followHandler, followers,
}) {
  const dispatch = useDispatch();

  const intl = useIntl();
  let svgDiv;

  useEffect(() => {
    if (activeTab === 0) {
      if (Object.keys(followings).length > 0) {
        followings?.map((following) => {
          if (document.getElementById(following?.username)) {
            svgDiv = document.getElementById(following?.username);
            const gTag = svgDiv.getElementsByTagName('g')[0];
            gTag?.setAttribute('mask', 'none');
          }
        });
      }
    }

    if (activeTab === 1) {
      if (Object.keys(followers).length > 0) {
        followers?.map((follower) => {
          if (document.getElementById(follower?.username)) {
            svgDiv = document.getElementById(follower?.username);
            const gTag = svgDiv.getElementsByTagName('g')[0];
            gTag?.setAttribute('mask', 'none');
          }
        });
      }
    }
  }, [activeTab, followers, followings]);

  if (activeTab === 0) {
    if (Object.keys(followings).length === 0) {
      // setIsEmpty(true);
      return (
        <div className="emptySec">
          {intl.formatMessage({ id: 'there_is_no_following_yet' })}
        </div>
      );
    }
    if (Object.keys(followings).length > 0) {
      return followings.map((following, idx) => (
        <div className="follower" key={following.id}>
          <Link to={`/users/${following?.username}`}>
            <div
              className="avatarSec"
              onClick={() => {
                dispatch(setShowFollowDrawer(false));
              }}
            >
              {
                following?.image
                  ? (
                    <div
                      className="followerAvatar"
                      style={{ backgroundImage: `url(${following.image})` }}
                    />
                  )
                  : (
                    <div className="followerGeneratedAvatar" id={following?.username}>
                      <ProfilePicture colors={following?.profileColors} />
                    </div>
                  )
              }
              <div>
                <strong className="username">
                  <ShowUserName user={following} />
                </strong>
                <div className="hisOrHerFollower">
                  {following?.followersCount}
                  {' '}
                  {intl.formatMessage({ id: 'followers' })}
                </div>
              </div>
            </div>
          </Link>

          <div className="btnSec">

            {
                following?.isFollowedByMe !== undefined
                && (
                <button
                  type="button"
                  onClick={() => followHandler(following?.isFollowedByMe, following.username, idx, false, following)}
                  className={following?.isFollowedByMe ? 'btn detailFollowedBtn' : 'btn detailFollowBtn'}
                >
                  {
                        following?.isFollowedByMe
                        // || lsUsername === username
                          ? intl.formatMessage({ id: 'following' })
                          : intl.formatMessage({ id: 'follow' })
                      }
                </button>
                )
            }

          </div>

        </div>
      ));
    }
  }
  if (activeTab === 1) {
    if (Object.keys(followers).length === 0) {
      return (
        <div className="emptySec">
          {intl.formatMessage({ id: 'there_is_no_followers_yet' })}
        </div>
      );
    }
    if (Object.keys(followers).length > 0) {
      return followers.map((follower, idx) => {
        if (document.getElementById(follower?.username)) {
          svgDiv = document.getElementById(follower?.username);
          const gTag = svgDiv.getElementsByTagName('g')[0];
          gTag?.setAttribute('mask', 'none');
        }
        return (
          <div className="follower" key={follower.id}>

            <div onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(setShowFollowDrawer(false));
            }}
            >
              <Link to={`/users/${follower?.username}`}>
                <div className="avatarSec">
                  {
                  follower?.image
                    ? (
                      <div
                        className="followerAvatar"
                        style={{ backgroundImage: `url(${follower.image})` }}
                      />
                    )
                    : (
                      <div className="followerGeneratedAvatar" id={follower?.username}>
                        <ProfilePicture colors={follower?.profileColors} />
                      </div>
                    )
                }
                  <div>
                    <strong className="username">
                      <ShowUserName user={follower} />
                    </strong>
                    <div className="hisOrHerFollower">
                      {follower?.followersCount}
                      {' '}
                      {intl.formatMessage({ id: 'followers' })}
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="btnSec">

              {
                follower?.isFollowedByMe !== undefined
                && (
                <button
                  type="button"
                  className={follower?.isFollowedByMe ? 'btn detailFollowedBtn' : 'btn detailFollowBtn'}
                  onClick={() => followHandler(follower?.isFollowedByMe, follower.username, idx, true, follower)}
                >
                  {
                        follower?.isFollowedByMe
                          ? intl.formatMessage({ id: 'following' })
                          : intl.formatMessage({ id: 'follow' })
                      }
                </button>
                )
            }

            </div>

          </div>
        );
      });
    }
  }
}
