import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import api from '../../../api.js';

export default function IncreaseCreditPopup({show, setShow, setProfileStatusChanged}) {
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(false);
    const [creditCode, setCreditCode] = useState('');

    const inputHandler = (e) => {
        setCreditCode(e.target.value);
    };

    const handlePaste = (e) => {
        setCreditCode(e.clipboardData.getData('text'));
        e.preventDefault();
    };

    const handleClose = () => {
        if (isLoading === false) {
            setShow(false);
        }
    };

    const increaseCreditWithCode = () => {
        setIsLoading(true);
        api.post('/profile/credit/increase-with-code', {
            code: creditCode,
        }).then(({data}) => {
            setProfileStatusChanged((prevState) => !prevState);
            setIsLoading(false);
            setShow(false);
        }).catch(({response}) => {
            setIsLoading(false);
        });
    };

    return (
        <Modal
            id="kt_modal_create_app"
            aria-hidden="true"
            dialogClassName="insertCreditPopup  modal-dialog-centered"
            show={show}
            backdrop
        >

            <div className="closeVectorSec">
                <img
                    src="/icons/close.svg"
                    alt=""
                    onClick={handleClose}
                />
            </div>

            <div className="creditPopupBody">

                <div className="insertCreditPopupText">
                    {/* bla bla bla */}
                    {intl.formatMessage({id: 'insert_credit_txt'})}
                </div>

                <div className="codeInputRow">

                    <div>
                        {intl.formatMessage({id: 'your_code'})}
                        :
                    </div>
                    <input
                        type="text"
                        inputMode="text"
                        onPaste={handlePaste}
                        onChange={inputHandler}
                        value={creditCode}
                        className="creditCodeInput"
                        name="2"
                    />

                </div>

                <div className="creditPopupBtnSec">
                    <button
                        type="button"
                        id="kt_sign_in_submit"
                        onClick={increaseCreditWithCode}
                        className="insertCreditBtn"
                        disabled={!creditCode || isLoading}
                    >
                        {
                            isLoading
                                ? (
                                    <span className="indicator-progress" style={{display: 'block'}}>
                                  {intl.formatMessage({id: 'please_wait'})}
                                        <span
                                            className="spinner-border spinner-border-sm align-middle ms-2"
                                        />
                                </span>
                                )
                                : (
                                    <span className="indicator-label">
                                  {' '}
                                        {intl.formatMessage({id: 'submit'})}
                                </span>
                                )
                        }
                    </button>
                </div>

            </div>

        </Modal>
    );
}
