import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../../../assets/styles/User/User.scss';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import api from '../../../../api.js';
import { setShowToast, setToastMessage, setToastStatus } from '../../../../redux/slices/toastSlice';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';

export default function CongratesNewUserModal({
  show,
  setShow,
}) {
  const congratesHandler = () => {
    localStorage.removeItem('userCreated');
    setShow(false);
  };

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="warningModal  modal-dialog-centered"
      show={show}
      onHide={congratesHandler}
      backdrop
    >

      <img
        src="/gif/Animation.gif"
        alt=""
        className="congratsAnimation showAnimation"
      />
      <div className="congratsMessage showAnimation" />

      <div className="unfollowModalBody">
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >

          <div className="CongratesModalTitle">
            تبریک میگم!
          </div>

          <div className="warningDes">
            {ToPersianNumber('کیف پولت 50 هزارتومن شارژ شد!')}
          </div>

          {/* <div className="flexClass justify-content-between unfollowBtnSec"> */}
          {/*  <button */}
          {/*    className="btn unfollowBtn" */}
          {/*    disabled={isLoading} */}
          {/*    onClick={changePublishStatus} */}
          {/*  > */}
          {/*    متوجه شدم */}
          {/*  </button> */}
          {/* </div> */}
        </div>
      </div>
    </Modal>
  );
}
