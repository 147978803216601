// export default function numberWithCommas(x) {
//     if (isFloat(parseFloat(x))) {
//         x = x.toString();
//         let decIndex = x.indexOf('.');
//         let num = x.slice(0, decIndex);
//         let decs = x.slice(decIndex + 1)
//         return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + decs
//     } else {
//         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     }
// }

export default function addCommas(nStr) {
  if (nStr) {
    nStr += '';
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? `.${x[1]}` : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '،' + '$2');
    }
    return x1 + x2;
  } if (nStr === 0) {
    return 0;
  }
  return '';
}
export function addDots(nStr) {
  if (nStr) {
    nStr += '';
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? `.${x[1]}` : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
  } if (nStr === 0) {
    return 0;
  }
  return '';
}
