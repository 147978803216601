import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../../../assets/styles/User/User.scss';
import { useIntl } from 'react-intl';
import { ShowUserName } from '../../general/ShowUserName';
import ProfilePicture from '../../other/ProfilePicture';

export default function UnfollowModal({
  show, setShow,
  followUser,
  user,
}) {
  const intl = useIntl();

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="unfollowModal  modal-dialog-centered"
      show={show}
      onHide={handleClose}
      backdrop
    >

      <div className="modalHeader">
        <img
          src="/icons/close.svg"
          alt=""
          onClick={handleClose}
          className="close"
        />
      </div>

      <div className="unfollowModalRealBody">
        <div
          className="unfollowModalWrapper"
        >
          {
                        user?.image
                          ? (
                            <div
                              className="unfollowUserAvatar"
                              style={{ backgroundImage: `url(${user?.image})` }}
                            />
                          )
                          : (
                            <div className="unfollowUserAvatar">
                              <ProfilePicture colors={user?.profileColors} />
                            </div>
                          )

                    }
          <div className="username">
            <span>
              <ShowUserName user={user} />
            </span>
          </div>
          <div className="flexClass justify-content-between unfollowBtnSec">
            <button className="btn unfollowBtn" onClick={followUser}>
              {intl.formatMessage({ id: 'unfollow' })}
            </button>
            <button className="btn cancelBtn" onClick={() => setShow(false)}>
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
