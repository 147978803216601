import React, { useEffect, useState } from 'react';
import '../../../assets/styles/Explore/Explore.scss';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../redux/slices/languageSlice';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';

export default function CountDown({ time }) {
  const [differenceTime, setDifferenceTime] = useState({
    day: '',
    hour: '',
    minute: '',
    second: '',
  });
  const [rendered, setRendered] = useState(false);

  const now = new Date();
  const languageRedux = useSelector(selectLanguage);

  function TimerHandler() {
    // start date started
    time = new Date(time);
    const differenceDate = (time - now);
    const differenceDays = Math.floor(differenceDate / (1000 * 60 * 60 * 24));
    const differenceHours = Math.floor((differenceDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const differenceMinutes = Math.floor((differenceDate % (1000 * 60 * 60)) / (1000 * 60));
    const differenceSeconds = Math.floor((differenceDate % (1000 * 60)) / 1000);

    let days;
    let hours;
    let minutes;
    let seconds;

    if (differenceDays < 10) {
      days = `0${differenceDays}`;
    } else {
      days = `${differenceDays}`;
    }
    if (differenceDays < 0) {
      days = '00';
    }

    if (differenceHours < 10) {
      hours = `0${differenceHours}`;
    } else {
      hours = `${differenceHours}`;
    }
    if (differenceHours < 0) {
      hours = '00';
    }

    if (differenceMinutes < 10) {
      minutes = `0${differenceMinutes}`;
    } else {
      minutes = `${differenceMinutes}`;
    }
    if (differenceMinutes < 0) {
      minutes = '00';
    }

    if (differenceSeconds < 10) {
      seconds = `0${differenceSeconds}`;
    } else {
      seconds = `${differenceSeconds}`;
    }
    if (differenceSeconds < 0) {
      seconds = '00';
    }

    if (minutes === '00' && hours === '00' && days === '00' && seconds === '00') {
      // setIsOver(true);
    }

    setDifferenceTime({
      day: days,
      hour: hours,
      minute: minutes,
      second: seconds,
    });
  }

  useEffect(() => {
    if (!rendered) {
      TimerHandler();
      setRendered(true);
    } else {
      setTimeout(() => {
        TimerHandler();
      }, 1000);
    }
  }, [differenceTime]);

  return (
    <div className="countdownSec">
      <div className="timeNumber">
        {' '}
        {languageRedux === 'fa'
          ? differenceTime?.day === 0 ? ToPersianNumber(0) : ToPersianNumber(differenceTime?.day)
          : differenceTime?.day === 0 ? 0 : differenceTime?.day}
        <span>d</span>
      </div>

      <div className="timeNumber">
        {
          languageRedux === 'fa'
            ? differenceTime?.hour === 0 ? ToPersianNumber(0) : ToPersianNumber(differenceTime?.hour)
            : differenceTime?.hour === 0 ? '0' : differenceTime?.hour
        }
        <span>h</span>
      </div>

      <div className="timeNumber">
        {
          languageRedux === 'fa'
            ? differenceTime?.minute === 0 ? ToPersianNumber(0) : ToPersianNumber(differenceTime?.minute)
            : differenceTime?.minute === 0 ? '0' : differenceTime?.minute
        }
        <span>m</span>
      </div>
    </div>

  );
}
