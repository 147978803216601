import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { setShowToast, setToastMessage, setToastStatus } from '../redux/slices/toastSlice';
import api from '../api.js';

function NetworkStatus() {
  const dispatch = useDispatch();

  const intl = useIntl();

  useEffect(() => {
    const checkOnlineStatus = async () => {
      try {
        // Make a GET request to a reliable public API
        await api.get('/moments/filter-data', { cache: 'no-store' });
        return true;
      } catch (error) {
        return false;
      }
    };

    const handleOnlineStatus = async () => {
      const online = await checkOnlineStatus();
      if (!online) {
        dispatch(setToastStatus(0));
        dispatch(setShowToast(true));
        dispatch(setToastMessage(intl.formatMessage({ id: 'network_connection_problem' })));
      }
    };

    // window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    // Initial check
    handleOnlineStatus();

    // Periodically check the online status every 5 seconds
    // const intervalId = setInterval(handleOnlineStatus, 5000);

    return () => {
      // window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
      // clearInterval(intervalId);
    };
  }, [dispatch]);

  return null;
}

export default NetworkStatus;
