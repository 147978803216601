import React from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export default function ShowUtilityModal({
  show, setShow, copyUtility, moment,
  utility,
}) {
  const intl = useIntl();

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="warningModal  modal-dialog-centered"
      show={show}
        // show={true}
      onHide={() => {
        setShow(false);
      }}
      backdrop
    >
      <div className="utilityModal">
        <div className="showUtilityModalBody">

          <div className="utilityModalHeader">
            <img
              src="/icons/closeMetronic.svg"
              alt=""
              onClick={() => {
                setShow(false);
              }}
              className="utilityCloseIcon"
            />
          </div>

          <div className="ShowUtilitySec">
            <div>
              {utility.code}
            </div>
            <img src="/icons/copy.png" alt="" onClick={copyUtility} />
          </div>

          <div className="utilityCodeDescription">
            {intl.formatMessage({ id: 'utility_code_description_1' })}

            <span>
              {intl.formatMessage({ id: 'utility_code_description_2' })}
            </span>
            {' '}
            <br />

            {intl.formatMessage({ id: 'utility_code_description_3' })}

            <a href="tel:09120384138">
              <u>
                09120384138،
              </u>
            </a>
            {' '}
            <a href="tel:09226085519">
              <u>
                09226085519
              </u>
            </a>

            {intl.formatMessage({ id: 'utility_code_description_4' })}

          </div>

        </div>
      </div>
    </Modal>
  );
}
