import React, { useEffect, useState } from 'react';
import { Collapse, Modal, Spinner } from 'react-bootstrap';
import '../../../assets/styles/Transfer/Transfer.scss';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import {
  useAccount, useDisconnect, useSignMessage,
} from 'wagmi';
// import { verifyMessage } from 'ethers/lib/utils.js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Carousel from 'react-bootstrap/Carousel';
import api from '../../../api.js';
import { getWindowSize } from '../../../helpers/getWindowSize.jsx';
import { setShowToast, setToastMessage, setToastStatus } from '../../../redux/slices/toastSlice';
import { Wallets } from '../general/Wallets.jsx';
import { selectLanguage } from '../../../redux/slices/languageSlice';
import { selectIsLogin } from '../../../redux/slices/accountSlice';
import { selectShowWalletsAfterLoginBuyNFT, setShowWalletsAfterLoginBuyNFT } from '../../../redux/slices/buySlice';

export default function SelectWalletForBuyingNFT({
  show, setShow,
  selectedWallet,
  setSelectedWallet,
  setShowBuyModal,
  // momentId,
  // setIsMomentStatusChanged,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const languageRedux = useSelector(selectLanguage);
  const isLogin = useSelector(selectIsLogin);

  const showAfterLogin = useSelector(selectShowWalletsAfterLoginBuyNFT);

  const { open: openWeb3Modal } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { isConnected } = useAccount();
  // const {
  //   chain,
  // } = useNetwork();

  const [wallets, setWallets] = useState([]);

  // const [selectedItems, setSelectedItem] = useState({});

  const [openCollapse, setOpenCollapse] = useState(false);
  const [userDatas, setUserDatas] = useState({
    user: {
      walletAddresses: [],
    },
  });

  const [loading, setLoading] = useState(false);

  const [isContinueWithWallet, setIsContinueWithWallet] = useState(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const matches = (windowSize?.innerWidth <= 972);
  const signMessageText = import.meta.env.VITE_WALLET_MESSAGE;

  useEffect(() => {
    if (show === true) {
      api.get('/profile/wallets').then(({ data }) => {
        setWallets(data?.wallets);
      });

      // after the modal opened, we should reset the state
      dispatch(setShowWalletsAfterLoginBuyNFT(false));
    }
  }, [show, userDatas]);

  useEffect(() => {
    if (show === false) {
      setIsContinueWithWallet(false);
    }
  }, [show]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  // const {
  //   signMessage,
  // } = useSignMessage({
  //   onSuccess(data, variables) {
  //     const address = verifyMessage(variables.message, data);
  //     const chainId = chain.id;
  //     api.post('/profile/add-wallet', {
  //       address,
  //       signature: data,
  //       chainId,
  //     })
  //       .then((r) => {
  //         api.get('/profile/wallets').then(({ data: response }) => {
  //           setWallets(response?.wallets);
  //         });
  //       })
  //       .catch((e) => {
  //         disconnect();
  //       });
  //   },
  //   onError(e, e2) {
  //     disconnect();
  //   },
  // });

  useEffect(() => {
    if (isConnected) {
      // signMessage({ message: signMessageText });
    }
  }, [isConnected]);

  const handleClose = () => {
    if (!loading) {
      setShow(false);
      dispatch(setShowWalletsAfterLoginBuyNFT(false));
    }
  };

  // const deleteWallet = (id) => {
  //   api.delete(`/profile/remove-wallet/${id}`)
  //     .then(({ data }) => {
  //       setWallets(data.wallets);
  //
  //       // setUserDatas(
  //       //     (prevState) => (
  //       //         {
  //       //           ...prevState,
  //       //           user: {
  //       //             ...prevState.user,
  //       //             walletAddresses: r.data.wallets,
  //       //           },
  //       //         }
  //       //     ),
  //       // );
  //     });
  // };

  const transferToWallet = () => {
    setLoading(true);

    // api.post(`profile/transfer/${momentId}/to-wallet`, {
    //   walletAddressId: selectedItems?.id,
    // }).then(({ data }) => {
    //   setLoading(false);
    //   setShow(false);
    //
    //   setIsMomentStatusChanged((prevState) => !prevState);
    // }).catch(({ response }) => {
    //   setIsMomentStatusChanged((prevState) => !prevState);
    //
    //   setLoading(false);
    //   if (!response?.data?.message) {
    //     dispatch(setShowToast(true));
    //     dispatch(setToastMessage(intl.formatMessage({ id: 'try_again' })));
    //     dispatch(setToastStatus(0));
    //   }
    // });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {/* Simple tooltip */}
      {intl.formatMessage({ id: 'You_can_choose_from_the_wallets_in_your_profile' })}
    </Tooltip>
  );

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="transferWalletModal modal-dialog-centered"
      show={show || (isLogin && showAfterLogin)}
      backdrop
    >
      <div className="modalHeader">
        <img
          src="/icons/close.svg"
          alt=""
          onClick={handleClose}
          className="close"
        />
      </div>

      <Carousel
        interval={null}
        activeIndex={isContinueWithWallet ? 1 : 0}
        controls={false}
        indicators={false}
      >

        <Carousel.Item>
          <div className="transferImageSec">
            <img
              className="transferModalImage"
              src="/icons/sportCategory/wallet.svg"
              alt=""
            />
            <div className="selectWalletText">
              <div>
                {intl.formatMessage({ id: 'select_wallet_for_buy_nft_1' })}
              </div>

              <br />

              <div>
                {intl.formatMessage({ id: 'select_wallet_for_buy_nft_2' })}
              </div>
            </div>
          </div>

          <div className="selectWalletBtnSec">

            <button
              className="btn withWalletBtn"
              onClick={() => {
                setIsContinueWithWallet(true);
              }}
            >
              {intl.formatMessage({ id: 'continue_with_wallet' })}
            </button>
            <button
              className="btn withoutWalletBtn"
              onClick={() => {
                // if the user regret using wallet and click this button , we remove his selected wallet
                if (Object?.keys(selectedWallet)?.length > 0) {
                  setSelectedWallet({});
                }
                setShow(false);
                setShowBuyModal(true);
              }}
            >
              {intl.formatMessage({ id: 'continue_without_wallet' })}
            </button>

          </div>
        </Carousel.Item>

        <Carousel.Item>

          <div className="selectWalletSec">

            <div className="transferImageSec">
              <img
                className="transferModalImage"
                src="/icons/sportCategory/wallet.svg"
                alt=""
              />
              <div>
                {intl.formatMessage({ id: 'transfer_to_wallet' })}
              </div>
            </div>

            <div className="walletSec">

              <div className="walletAddresses">
                <div
                  className="walletsHeader"
                  onClick={() => {
                    // if (wallets.length > 0) {
                    setOpenCollapse(!openCollapse);
                    // }
                  }}
                >
                  {
                                        Object.keys(selectedWallet).length > 0
                                          ? (
                                            <div className="flexClass justify-content-between align-items-center">
                                              <div className="walletItem">
                                                <div className="flexClass align-items-center">
                                                  <img
                                                    src={selectedWallet?.chain?.logo}
                                                    className="chainImg"
                                                    alt=""
                                                  />
                                                  <div>
                                                    {matches ? `${selectedWallet?.address?.slice(0, 4)}...${selectedWallet?.address?.slice(-4)}` : `${selectedWallet?.address?.slice(0, 10)}...${selectedWallet?.address?.slice(-10)}`}
                                                  </div>
                                                </div>
                                              </div>
                                              <img
                                                className="deleteSelectedWalletSvg"
                                                src="/icons/trash.svg"
                                                alt=""
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  // setSelectedItem({});
                                                  setSelectedWallet({});
                                                }}
                                              />
                                            </div>
                                          )
                                          : (
                                            <>
                                              <div>
                                                {intl.formatMessage({ id: 'you_have' })}
                                                {' '}
                                                {wallets?.length}
                                                {' '}
                                                {intl.formatMessage({ id: 'wallet' })}
                                                {(wallets.length > 1 && languageRedux === 'en') ? 's' : ''}
                                              </div>
                                              <img
                                                src={openCollapse ? '/icons/vectorUp.svg' : '/icons/vectorDown.svg'}
                                                className="vector"
                                                alt=""
                                              />
                                            </>
                                          )
                                    }
                </div>
                <div className="walletsCollapse">
                  <Collapse in={openCollapse}>
                    <div className="walletPart">
                        <Wallets
                            wallets={wallets}
                            setUserDatas={setUserDatas}
                            setSelectedItem={setSelectedWallet}
                          />
                      </div>
                  </Collapse>
                </div>
              </div>

              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <img src="/icons/about.svg" alt="" />
              </OverlayTrigger>
            </div>

            <button
              className="selectWalletBtn"
              onClick={() => {
                setShow(false);
                setShowBuyModal(true);
              }}
              disabled={Object?.keys(selectedWallet)?.length === 0}
            >
              {intl.formatMessage({ id: 'select_wallet' })}
            </button>

          </div>

        </Carousel.Item>

      </Carousel>

    </Modal>
  );
}
