import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import api from '../../../api.js';

export default function WithdrawRequestModal({ show, setShow }) {
  const [cardNumber, setCardNumber] = useState('');

  const intl = useIntl();

  const handleClose = () => {
    setShow(false);
  };

  const inputHandler = (e) => {
    if (!(isNaN(e.target.value))) {
      setCardNumber(e.target.value);
    }
  };

  const handlePaste = (e) => {
    let pastedData;
    if (!(isNaN(e.clipboardData.getData('text')))) {
      pastedData = e.clipboardData.getData('text');

      setCardNumber(pastedData);
    }
  };

  const submitWithdrawRequest = () => {
    // console.log(cardNumber);

    api.post('/profile/withdraw-request', {
      cardNumber: cardNumber,
    }).then(({ data }) => {
      console.log(data);
      setShow(false);
    }).catch(({ response }) => {
      console.log(response);
    });
  };

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="showReferralModal  modal-dialog-centered"
      show={show}
      onHide={handleClose}
      backdrop
    >
      <div className="modalHeader">
        <img
          src="/icons/close.svg"
          alt=""
          onClick={handleClose}
          className="closeIcon"
        />
      </div>

      <div className="referralModalBody">

        <input
          type="number"
          inputMode="numeric"
          onPaste={handlePaste}
          value={cardNumber}
          onChange={inputHandler}
          className="withDrawRequestCardNumber"
          name="2"
        />

        <div className="enterCardTxt">
          {intl.formatMessage({ id: 'please_enter_card_number' })}
        </div>

        <button
          type="button"
          className="submitWithdrawBtn"
          disabled={cardNumber?.length < 16}
          onClick={submitWithdrawRequest}
        >
          {intl.formatMessage({ id: 'submit_withdraw' })}
        </button>

      </div>

    </Modal>
  );
}
