import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import api from '../../../../api';
import { setShowFollowDrawer } from '../../../../redux/slices/showDrawersSlice';
import UnfollowModal from './UnfollowModal';
import List from './List.jsx';

export default function FollowDetails({
  followings, followers,
  setFollowings, setFollowers,
  setFollowStatusChanged,
}) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showUnFollowModal, setShowUnFollowModal] = useState(false);

  const [followDetails, setFollowDetails] = useState({
    personTOFollowOrUnFollow: '',
    personTOFollowOrUnFollowData: [],
    id: 0,
    isInFollowerList: false,
  });

  useEffect(() => {
    if (activeTab === 0) {
      if (Object.keys(followings).length === 0) {
        setIsEmpty(true);
      }
      if (Object.keys(followings).length > 0) {
        setIsEmpty(false);
      }
    }
    if (activeTab === 1) {
      if (Object.keys(followers).length === 0) {
        setIsEmpty(true);
      }
      if (Object.keys(followers).length > 0) {
        setIsEmpty(false);
      }
    }
    if (activeTab === 2) {
      setIsEmpty(true);
    }
  }, [activeTab, followings, followers]);

  const followOrUnfollow = (userName, idx, isFollower) => {
    if (isFollower) {
      const nextFollowers = followers.map((follower, i) => {
        if (i === idx) {
          return { ...follower, isFollowedByMe: !follower.isFollowedByMe };
        }
        return follower;
      });

      setFollowers(nextFollowers);
      setShowUnFollowModal(false);
    } else {
      const nextFollowings = followings.map((following, i) => {
        if (i === idx) {
          return { ...following, isFollowedByMe: !following.isFollowedByMe };
        }
        return following;
      });
      setFollowings(nextFollowings);
    }
    api.post(`/profile/${userName}/follow`).then(({ data }) => {
      setFollowStatusChanged((prevstate) => !prevstate);
    }).catch(({ error }) => {
      if (isFollower) {
        const nextFollowers = followers.map((follower, i) => {
          if (i === idx) {
            return { ...follower, isFollowedByMe: false };
          }
          return follower;
        });

        setFollowers(nextFollowers);
      } else {
        const nextFollowings = followings.map((following, i) => {
          if (i === idx) {
            return { ...following, isFollowedByMe: false };
          }
          return following;
        });
        setFollowings(nextFollowings);
      }
    });
    setShowUnFollowModal(false);
  };

  const followHandler = (isMyFollowing, username, idx, isFollower, user) => {
    if (isMyFollowing) {
      setShowUnFollowModal(true);
      setFollowDetails({
        personTOFollowOrUnFollow: username,
        personTOFollowOrUnFollowData: user,
        id: idx,
        isInFollowerList: isFollower,
      });
    } else {
      followOrUnfollow(username, idx, isFollower);
    }
  };

  return (
    <div className="followDrawerWrapper">
      <UnfollowModal
        show={showUnFollowModal}
        setShow={setShowUnFollowModal}
        followUser={() => followOrUnfollow(followDetails?.personTOFollowOrUnFollow, followDetails?.id, followDetails?.isInFollowerList)}
        user={followDetails?.personTOFollowOrUnFollowData}
      />
      <div className="modalHeader">
        <div className="followDetails">
          <div className={activeTab === 0 ? 'firstDetailFollowColumn activeItem' : 'firstDetailFollowColumn'} onClick={() => setActiveTab(0)}>
            <div className="followNumber">
              {Object.keys(followings).length}
            </div>
            <div className="detailFollowText">
              {intl.formatMessage({ id: 'followings' })}
            </div>
          </div>

          <div className={activeTab === 1 ? 'lastDetailFollowColumn activeItem' : 'lastDetailFollowColumn'} onClick={() => setActiveTab(1)}>
            <div className="followNumber">
              {Object.keys(followers).length}
            </div>
            <div className="detailFollowText">
              {intl.formatMessage({ id: 'followers' })}
            </div>
          </div>

          {/* <div className={activeTab === 2 ? 'lastDetailFollowColumn activeItem' : 'lastDetailFollowColumn'} onClick={() => setActiveTab(2)}> */}
          {/*  <div className="followNumber"> */}
          {/*    0 */}
          {/*  </div> */}
          {/*  <div className="detailFollowText"> */}
          {/*    {intl.formatMessage({ id: 'charities' })} */}
          {/*  </div> */}
          {/* </div> */}
        </div>

        <img
          src="/icons/close.svg"
          alt=""
          onClick={() => {
            dispatch(setShowFollowDrawer(false));
          }}
        />

      </div>
      <div className={!isEmpty ? 'followersList' : 'emptyFollowerList'}>
        {/* <div className="followersList"> */}
        <List
          followers={followers}
          followings={followings}
          activeTab={activeTab}
          followHandler={followHandler}
        />
      </div>
    </div>
  );
}
