import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Dropdown from 'react-bootstrap/Dropdown';
import ProfilePicture from '../../other/ProfilePicture.jsx';
import { ShowUserName } from '../ShowUserName.jsx';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import addCommas from '../../../../helpers/addCommas.jsx';
import { selectLanguage } from '../../../../redux/slices/languageSlice';
import MomentImage from './MomentImage.jsx';
import { urlHandler } from '../../../../helpers/momentCardUrlHandler.jsx';

export default function Card({
  moment,
  playMode,
  setPlayMode,
  key,
}) {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);

  let svgDiv;
  useEffect(() => {
    if (moment?.momentEditions?.length > 0) {
      moment?.momentEditions?.map((edition) => {
        if (document.getElementById(edition.id)) {
          svgDiv = document.getElementById(edition.id);
          const gTag = svgDiv.getElementsByTagName('g')[0];
          gTag?.setAttribute('mask', 'none');
        }
      });
    }
    if (moment?.momentEditions?.length === 0) {
      if (document.getElementById(moment?.id)) {
        svgDiv = document.getElementById(moment?.id);
        const gTag = svgDiv.getElementsByTagName('g')[0];
        gTag?.setAttribute('mask', 'none');
      }
    }
  }, [key, moment?.owner?.username]);

  return (
    <>
      {

                moment?.isSold
                  ? (
                    <Link to={urlHandler(moment)}>
                      <MomentImage
                        moment={moment}
                        playMode={playMode}
                        setPlayMode={setPlayMode}
                      />
                    </Link>
                  )
                  : (moment?.campaign && moment?.campaign?.isHidden === false)
                    ? (
                      <Link to={urlHandler(moment)}>
                        <MomentImage
                          moment={moment}
                          playMode={playMode}
                          setPlayMode={setPlayMode}
                        />
                      </Link>
                    )
                    : (
                      <MomentImage
                        moment={moment}
                        playMode={playMode}
                        setPlayMode={setPlayMode}
                      />
                    )
            }

      <div className="cardMomentTitle">
        <img src="/icons/sportCategory/frame.svg" alt="" />
        {languageRedux === 'fa' ? ToPersianNumber(moment.title) : moment.title}
      </div>

      <div className="flexClass align-items-center justify-content-between ownerParent">
        {
                    !moment.owner && moment?.momentEditions?.length === 0
                    // doesnt have owner
                      ? (
                        <div className="ownerSec">
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="momentCharitiesDropDown1"
                                aria-label="momentCharitiesDropDown1"
                                className="ownerIdMenuToggle"
                              >
                                <Link to={`/clubs/${moment?.clubs ? moment?.clubs[0]?.id : ''}`}>
                                  <div className="ownerId flexClass align-items-center">
                                    <div
                                      className="charityImage"
                                      style={{ backgroundImage: 'url(/icons/sportCategory/football-ball.svg)' }}
                                    />
                                    {
                                                        moment?.clubs?.length > 1
                                                          ? `${moment?.clubs.length} ${intl.formatMessage({ id: 'clubs' })}`
                                                          : moment?.clubs && moment?.clubs[0]?.name

                                                    }
                                  </div>
                                </Link>
                              </Dropdown.Toggle>

                              {
                                            moment?.clubs?.length > 1
                                            && (
                                            <Dropdown.Menu>
                                              {
                                                        moment?.clubs.map((club) => (
                                                          <Link to={`/clubs/${club?.id}`}>
                                                            <Dropdown.Item>
                                                              <div className="charityItem">
                                                                <div
                                                                  className="charityImage"
                                                                  style={{ backgroundImage: 'url(/icons/sportCategory/football-ball.svg)' }}
                                                                />
                                                                <div>
                                                                  {club?.name}
                                                                </div>
                                                              </div>
                                                            </Dropdown.Item>
                                                          </Link>
                                                        ))
                                                    }
                                            </Dropdown.Menu>
                                            )
                                        }

                            </Dropdown>
                          </div>

                        </div>
                      )
                      : (
                        <div className="ownerSec">
                          {
                                    moment?.momentEditions?.length > 0
                                      ? (
                                        <div className="multipleOwnerParent">
                                          <div className="symbol-group symbol-hover flex-nowrap">
                                            {moment?.momentEditions.map((edition, idx) => {
                                              if (moment?.momentEditions?.length >= 4) {
                                                if (idx < 4) {
                                                  return (
                                                  // todo link to where?
                                                    <div
                                                      className="symbol symbol-35px symbol-circle"
                                                      data-bs-toggle="tooltip"
                                                    >
                                                      <div className="multipleOwnerImg" id={edition?.id}>
                                                        {
                                                                                edition.owner.image
                                                                                  ? (
                                                                                    <div
                                                                                      id={moment?.id}
                                                                                      className="multipleImage"
                                                                                      style={{ backgroundImage: `url(${edition.owner.image})` }}
                                                                                      alt=""
                                                                                    />
                                                                                  )
                                                                                  : (
                                                                                    <ProfilePicture
                                                                                      size={30}
                                                                                      colors={edition.owner.profileColors}
                                                                                    />
                                                                                  )
                                                                            }
                                                      </div>
                                                    </div>

                                                  );
                                                }
                                              }
                                              if (moment?.momentEditions?.length < 4) {
                                                return (
                                                  <div
                                                    className="symbol symbol-35px symbol-circle"
                                                    data-bs-toggle="tooltip"
                                                  >
                                                    <div className="multipleOwnerImg" id={edition?.id}>
                                                      {

                                                                            edition.owner.image
                                                                              ? (
                                                                                <div
                                                                                  className="multipleImage"
                                                                                  style={{ backgroundImage: `url(${edition.owner.image})` }}
                                                                                  alt=""
                                                                                />
                                                                              )
                                                                              : (
                                                                                <ProfilePicture
                                                                                  size={30}
                                                                                  colors={edition.owner.profileColors}
                                                                                />
                                                                              )
                                                                        }
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      )
                                      : moment.owner
                                        ? moment?.owner?.image
                                          ? (
                                            <Link to={`/users/${moment?.owner?.username}`}>
                                              <div
                                                className="ownerProfile"
                                                style={{ backgroundImage: `url(${moment.owner?.image})` }}
                                              />
                                            </Link>
                                          )
                                          : (
                                            <Link to={`/users/${moment?.owner?.username}`}>
                                              <div id={moment?.id}>
                                                <ProfilePicture
                                                  size={30}
                                                  colors={moment.owner.profileColors}
                                                />
                                              </div>
                                            </Link>
                                          )
                                        : (
                                          <div
                                            className="charityImage"
                                            style={{ backgroundImage: 'url(/icons/sportCategory/football-ball.svg)' }}
                                          />
                                        )

                                }
                          {
                                    moment?.momentEditions?.length > 0
                                      ? (
                                    // ${languageRedux === 'fa' ? ToPersianNumber(moment?.momentEditions?.length) : moment?.momentEditions?.length}
                                        <div className="ownerId">
                                          {`${intl.formatMessage({ id: 'has_several' })} 
                                                         ${languageRedux === 'fa' ? ToPersianNumber(moment?.editionsCount) : moment?.editionsCount}

                                                   ${intl.formatMessage({ id: 'version' })} `}

                                          {/* { */}
                                          {/*      languageRedux === 'fa' */}
                                          {/*        ? `(${ToPersianNumber(moment?.momentEditions?.length)}/${parseInt(moment?.campaign?.momentEditionsFraction, 10) === 0 ? ToPersianNumber(moment?.momentEditions?.length) : ToPersianNumber(parseInt(moment?.campaign?.momentEditionsFraction, 10))})` */}
                                          {/*        : `(${moment?.momentEditions?.length}/${parseInt(moment?.campaign?.momentEditionsFraction, 10) === 0 ? moment?.momentEditions?.length : parseInt(moment?.campaign?.momentEditionsFraction, 10)})` */}
                                          {/*    } */}
                                        </div>
                                      )

                                      : moment.owner
                                        ? (
                                          <Link
                                            to={`/users/${moment?.owner?.username}`}
                                            aria-label={`link to ${moment?.owner?.username} profile`}
                                          >
                                            <div
                                              className={`ownerId ${languageRedux === 'fa' ? 'ltrDirection' : 'rtlDirection'}`}
                                            >
                                              <ShowUserName user={moment.owner} />
                                            </div>
                                          </Link>
                                        )
                                        : (
                                          <div>
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                id="momentCharitiesDropDown2"
                                                aria-label="momentCharitiesDropDown2"
                                                className="ownerIdMenuToggle"
                                              >
                                                <Link to={`clubs/${moment?.campaign?.clubs[0]?.id}`}>
                                                  <div className="ownerId">
                                                    {
                                                                        moment?.clubs.length > 1
                                                                        // change this
                                                                          ? `${moment?.clubs.length} ${intl.formatMessage({ id: 'clubs' })}`
                                                                          : moment?.clubs[0]?.name

                                                                    }
                                                  </div>
                                                </Link>
                                              </Dropdown.Toggle>

                                              {
                                                            moment?.clubs.length > 1
                                                            && (
                                                            <Dropdown.Menu>
                                                              {
                                                                        moment?.campaign?.clubs.map((club) => (
                                                                          <Dropdown.Item>
                                                                            <div className="charityItem">
                                                                              <div
                                                                                className="charityImage"
                                                                                style={{ backgroundImage: 'url(/icons/sportCategory/football-ball.svg)' }}
                                                                              />
                                                                              <div>
                                                                                {club?.name}
                                                                              </div>
                                                                            </div>
                                                                          </Dropdown.Item>
                                                                        ))
                                                                    }
                                                            </Dropdown.Menu>
                                                            )
                                                        }

                                            </Dropdown>
                                          </div>
                                        )
                                }
                        </div>
                      )
                }

      </div>

      <div className="priceSec">
        <div className="priceRow">
          {
                        moment?.isForSale
                          ? (
                            <div className="price">
                              {moment?.listingPrice ? languageRedux === 'fa' ? ToPersianNumber(String(addCommas(moment?.listingPrice))) : String(addCommas(moment?.listingPrice)) : '-'}
                              {' '}
                              تومان
                            </div>
                          )
                          : moment.price > 0
                            ? (
                              <div className="price">
                                {moment?.momentEditions?.length > 0 && '>'}
                                {`${languageRedux === 'fa' ? ToPersianNumber(String(addCommas(moment?.price))) : String(addCommas(moment?.price))} ${moment?.currency?.symbol}`}
                              </div>
                            )
                            : (
                              <div className="price">
                                {intl.formatMessage({ id: 'its_not_for_sale' })}
                              </div>
                            )
                    }
        </div>
      </div>
    </>
  );
}
