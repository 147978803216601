import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { loopHooks } from 'react-table';
import UtilityConfirmModal from '../utility/UtilityConfirmModal.jsx';
import ShowUtilityModal from '../utility/ShowUtilityModal.jsx';
import { setShowToast, setToastMessage, setToastStatus } from '../../../redux/slices/toastSlice';
import { selectIsLogin, selectUserData } from '../../../redux/slices/accountSlice';
import api from '../../../api.js';
import getLocalStorage from '../../../helpers/getLocalStorage.js';

function HandleShowUtility({
  selectedEdition, moment,
  isUtilityHiddenForOwner, setIsUtilityHiddenForOwner,
  copyUtility,
  setShowUtilityConfirmModal,
  utility,
}) {
  const intl = useIntl();
  const userData = useSelector(selectUserData);

  const [openUtility, setOpenUtility] = useState(true);

  const isLogin = useSelector(selectIsLogin);

  if (isLogin) {
    //   if the viewer loggind
    //   todo moment edition if and else in utility
    // if (moment?.momentEditions?.length > 0) {
    //   if (userData.username === selectedEdition.owner.username) {
    //     // if the loggedin user is the owner of the selected edition
    //
    //   } else {
    //     //  if the loggind user is just a viewer , not owner
    //   }
    // }

    // if (moment?.momentEditions?.length === 0 || !moment?.momentEditions) {
    //   the moment type is "single"
    if (moment?.type === 'single' ? userData.username === moment.owner?.username : userData?.username === selectedEdition?.owner?.username) {
      //     if the logged-in user is the owner of moment
      return (
        <div className="accordion utilityAccordionBorder">
          <div className="accordionSummary" onClick={() => setOpenUtility(!openUtility)}>
            <div className="summeryImageSec">
              <img
                src="/icons/description.svg"
                alt=""
                className="accordionImg"
              />
              <div className="utilitySecTitle">
                <span>
                  {intl.formatMessage({ id: 'utility' })}
                </span>
                <div className="utilityTitleInfo">
                  &nbsp;
                  (
                  {intl.formatMessage({ id: 'utility_detail_title' })}
                  )
                </div>
              </div>

            </div>
            <img
              src="/icons/vectorUp.svg"
              alt=""
              className={openUtility ? 'vectorUp' : 'vectorDown'}
            />
          </div>

          {
                        openUtility
                        && (
                        <div className="accordionDetails">
                          <div
                            className="description"
                          >
                            <div className="utilityParent">
                              <div className="receiveUtilityAttention">
                                <div>

                                  <span style={{ fontWeight: 700 }}>
                                    {intl.formatMessage({ id: 'attention' })}
                                    :
                                    &nbsp;
                                  </span>

                                  {intl.formatMessage({ id: 'show_utility_attention_1' })}

                                  <span style={{ fontWeight: 600 }}>
                                    {intl.formatMessage({ id: 'show_utility_attention_2' })}
                                  </span>

                                  <br />

                                  <div className="utilityTitleSec">
                                    <div className={"giftIconSec"}>
                                      <img src="/icons/greenGift.svg" alt="" />
                                      <div>
                                        {intl.formatMessage({ id: 'award' })}
                                      </div>
                                      :
                                    </div>

                                      {/*&nbsp;*/}

                                    <div dangerouslySetInnerHTML={{ __html: utility?.description }} />
                                  </div>

                                </div>

                                {/* <br /> */}
                                {
                                                !isUtilityHiddenForOwner
                                                && (
                                                <div>
                                                  {intl.formatMessage({ id: 'utility_code_description_in_moment_page_1' })}
                                                  <a href="tel:09120384138">
                                                    <u>
                                                      09120384138
                                                    </u>
                                                  </a>
                                                  {'   '}
                                                  {intl.formatMessage({ id: 'and' })}
                                                  {'   '}
                                                  <a href="tel:09226085519">
                                                    <u>
                                                      09226085519
                                                    </u>
                                                  </a>

                                                  {intl.formatMessage({ id: 'utility_code_description_in_moment_page_2' })}
                                                </div>
                                                )

                                            }
                              </div>

                              <div className="accordionUtilityBtnSec">

                                {
                                                isUtilityHiddenForOwner
                                                  ? (
                                                    <button
                                                      className="showUtilityBtn"
                                                            // style={{ border: 'solid lightBlue' }}
                                                      onClick={() => {
                                                        setShowUtilityConfirmModal(true);
                                                      }}
                                                    >
                                                      {intl.formatMessage({ id: 'show_utility_code' })}
                                                    </button>
                                                  )
                                                  : (
                                                    <div className="ShowUtilitySec">
                                                      <div className="utilityText">
                                                        {utility?.code}
                                                      </div>
                                                      <img
                                                        src="/icons/copy.png"
                                                        alt=""
                                                        onClick={copyUtility}
                                                      />
                                                    </div>
                                                  )
                                            }
                              </div>
                            </div>

                          </div>
                        </div>
                        )
                    }
        </div>
      );
    }
    if (isUtilityHiddenForOwner) {
      //     if the loggind user is just the viewer , not the owner
      return (
        <div className="accordion utilityAccordionBorder">
          <div className="accordionSummary" onClick={() => setOpenUtility(!openUtility)}>
            <div className="summeryImageSec">
              <img
                src="/icons/description.svg"
                alt=""
                className="accordionImg"
              />
              <div className="utilitySecTitle">
                <span>
                  {intl.formatMessage({ id: 'utility' })}
                </span>
                <div className="utilityTitleInfo">
                  &nbsp;
                  (
                  {intl.formatMessage({ id: 'utility_detail_title' })}
                  )
                </div>
              </div>
            </div>
            <img
              src="/icons/vectorUp.svg"
              alt=""
              className={openUtility ? 'vectorUp' : 'vectorDown'}
            />
          </div>

          {
                        openUtility
                        && (
                        <div className="accordionDetails">
                          <div
                            className="description"
                          >
                            <div className="utilityParent">
                              <div className="utilityTitleSec">
                                <div className={"giftIconSec"}>
                                  <img src="/icons/greenGift.svg" alt="" />
                                  <div>
                                    {intl.formatMessage({ id: 'award' })}
                                  </div>
                                  :
                                </div>

                                {/*&nbsp;*/}

                                <div dangerouslySetInnerHTML={{ __html: utility?.description }} />
                              </div>

                            </div>

                          </div>
                        </div>
                        )
                    }
        </div>
      );
    }
    // }
    // else {
    // //   the moment type is "edition"
    //   console.log(selectedEdition);
    //
    //   if (userData?.username === selectedEdition?.owner?.username) {
    //     //     if the loggind user is the owner of selected edition
    //     return (
    //       <div className="accordion">
    //         <div className="accordionSummary" onClick={() => setOpenUtility(!openUtility)}>
    //           <div className="summeryImageSec">
    //             <img
    //               src="/icons/description.svg"
    //               alt=""
    //               className="accordionImg"
    //             />
    //             <div className="utilitySecTitle">
    //               <span>
    //                 {intl.formatMessage({ id: 'utility' })}
    //               </span>
    //               <div className="utilityTitleInfo">
    //                 &nbsp;
    //                 (
    //                 {intl.formatMessage({ id: 'utility_detail_title' })}
    //                 )
    //               </div>
    //             </div>
    //
    //           </div>
    //           <img
    //             src="/icons/vectorUp.svg"
    //             alt=""
    //             className={openUtility ? 'vectorUp' : 'vectorDown'}
    //           />
    //         </div>
    //
    //         {
    //               openUtility
    //               && (
    //               <div className="accordionDetails">
    //                 <div
    //                   className="description"
    //                 >
    //                   <div className="utilityParent">
    //                     <div className="receiveUtilityAttention">
    //                       <div>
    //                         {intl.formatMessage({ id: 'show_utility_attention' })}
    //                         <div dangerouslySetInnerHTML={{ __html: utility?.description }} />
    //                       </div>
    //                     </div>
    //
    //                     <div className="accordionUtilityBtnSec">
    //
    //                       {
    //                             isUtilityHiddenForOwner
    //                               ? (
    //                                 <button
    //                                   className="showUtilityBtn"
    //                                         // style={{ border: 'solid lightBlue' }}
    //                                   onClick={() => {
    //                                     setShowUtilityConfirmModal(true);
    //                                   }}
    //                                 >
    //                                   {intl.formatMessage({ id: 'show_utility_code' })}
    //                                 </button>
    //                               )
    //                               : (
    //                                 <div className="ShowUtilitySec">
    //                                   <div className="utilityText">
    //                                     {utility?.code}
    //                                   </div>
    //                                   <img
    //                                     src="/icons/copy.png"
    //                                     alt=""
    //                                     onClick={copyUtility}
    //                                   />
    //                                 </div>
    //                               )
    //                           }
    //                     </div>
    //                   </div>
    //
    //                 </div>
    //               </div>
    //               )
    //           }
    //       </div>
    //     );
    //   }
    //
    //   if (isUtilityHiddenForOwner) {
    //     //     if the loggind user is just the viewer , not the owner
    //     return (
    //       <div className="accordion">
    //         <div className="accordionSummary" onClick={() => setOpenUtility(!openUtility)}>
    //           <div className="summeryImageSec">
    //             <img
    //               src="/icons/description.svg"
    //               alt=""
    //               className="accordionImg"
    //             />
    //             <div className="utilitySecTitle">
    //               <span>
    //                 {intl.formatMessage({ id: 'utility' })}
    //               </span>
    //               <div className="utilityTitleInfo">
    //                 &nbsp;
    //                 (
    //                 {intl.formatMessage({ id: 'utility_detail_title' })}
    //                 )
    //               </div>
    //             </div>
    //           </div>
    //           <img
    //             src="/icons/vectorUp.svg"
    //             alt=""
    //             className={openUtility ? 'vectorUp' : 'vectorDown'}
    //           />
    //         </div>
    //
    //         {
    //               openUtility
    //               && (
    //               <div className="accordionDetails">
    //                 <div
    //                   className="description"
    //                 >
    //                   <div className="utilityParent">
    //                     <div style={{ width: '100%' }}>
    //                       <div
    //                         style={{ fontWeight: '600' }}
    //                         dangerouslySetInnerHTML={{ __html: utility?.description }}
    //                       />
    //                     </div>
    //                   </div>
    //
    //                 </div>
    //               </div>
    //               )
    //           }
    //       </div>
    //     );
    //   }
    // }
  } else {
    //     user didnt login
    if (isUtilityHiddenForOwner) {
      //    owner didnt use the utility yet!
      return (
        <div className="accordion utilityAccordionBorder">
          <div className="accordionSummary" onClick={() => setOpenUtility(!openUtility)}>
            <div className="summeryImageSec">
              <img
                src="/icons/description.svg"
                alt=""
                className="accordionImg"
              />
              <div className="utilitySecTitle">
                <span>
                  {intl.formatMessage({ id: 'utility' })}
                </span>
                <div className="utilityTitleInfo">
                  &nbsp;
                  (
                  {intl.formatMessage({ id: 'utility_detail_title' })}
                  )
                </div>
              </div>
            </div>
            <img
              src="/icons/vectorUp.svg"
              alt=""
              className={openUtility ? 'vectorUp' : 'vectorDown'}
            />
          </div>

          {
                        openUtility
                        && (
                        <div className="accordionDetails">
                          <div
                            className="description"
                          >
                            <div className="utilityParent">
                              {/* <div style={{ width: '100%' }}> */}
                              {/*  <div */}
                              {/*    style={{ fontWeight: '600' }} */}
                              {/*    dangerouslySetInnerHTML={{ __html: utility?.description }} */}
                              {/*  /> */}
                              {/* </div> */}

                              <div className="utilityTitleSec">
                                <div className={"giftIconSec"}>
                                  <img src="/icons/greenGift.svg" alt="" />
                                  <div>
                                    {intl.formatMessage({ id: 'award' })}
                                  </div>
                                  :
                                </div>

                                {/*&nbsp;*/}

                                <div dangerouslySetInnerHTML={{ __html: utility?.description }} />
                              </div>

                            </div>

                          </div>
                        </div>
                        )
                    }
        </div>
      );
    }
  }
}

export default function UtilityAccordion({
  isUtilityHiddenForOwner,
  setIsUtilityHiddenForOwner,
  moment,
  utility,
  setUtility,
  selectedEdition,
}) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { id } = useParams();

  const [showUtilityConfirmModal, setShowUtilityConfirmModal] = useState(false);
  const [showUtilityModal, setShowUtilityModal] = useState(false);

  const copyUtility = () => {
    navigator.clipboard.writeText(utility?.code);
    dispatch(setShowToast(true));
    dispatch(setToastMessage(`${intl.formatMessage({ id: 'copied' })}!`));
    dispatch(setToastStatus(1));
  };

  // Step 2: Save JSON data to window.localStorage whenever it changes

  // useEffect(() => {
  //   if (showUtilityModal) {
  //     // todo change shown status in utility in moment
  //   }
  // }, [showUtilityModal]);

  const receiveUtility = async () => {
    setShowUtilityConfirmModal(false);
    setShowUtilityModal(true);
    const storage = getLocalStorage();

    // setUtility((prevState) => ({
    //   ...prevState,
    //   shown: true,
    //   // code:`MOMENTINMALAVAN-123${moment?.id}`
    // }));

    if (moment?.type === 'edition') {
      api.put(`/moments/editions/${selectedEdition?.id}/util`).then(({ data }) => {
        api.get(`/moments/editions/${selectedEdition?.id}/util`).then(({ data }) => {
          setUtility(data?.utility);

          if (data?.utility?.code) {
            setIsUtilityHiddenForOwner(false);
          } else {
            setIsUtilityHiddenForOwner(true);
          }
        }).catch(({ data }) => {
          console.log(data);
          setIsUtilityHiddenForOwner(false);
          setUtility([]);
          // this moment doesnt have utility
        });
      }).catch(({ data }) => {
        console.log(data);
      });
    } else {
      // the moment type is "single"
      api.put(`/moments/${moment?.id}/util`).then(({ data }) => {
        api.get(`/moments/${moment?.id}/util`).then(({ data }) => {
          setUtility(data?.utility);

          if (data?.utility?.code) {
            setIsUtilityHiddenForOwner(false);
          } else {
            setIsUtilityHiddenForOwner(true);
          }
        }).catch(({ data }) => {
          console.log(data);
          setIsUtilityHiddenForOwner(false);
          setUtility([]);
          // this moment doesnt have utility
        });
      }).catch(({ data }) => {
        console.log(data);
      });
    }

    await storage.setItem(`moment ${id}`, JSON.stringify(utility));
    setJsonData(utility);
  };

  return (
    <>
      <HandleShowUtility
        selectedEdition={selectedEdition}
        moment={moment}
        utility={utility}
        isUtilityHiddenForOwner={isUtilityHiddenForOwner}
        setIsUtilityHiddenForOwner={setIsUtilityHiddenForOwner}
        copyUtility={copyUtility}
        setShowUtilityConfirmModal={setShowUtilityConfirmModal}
      />

      <UtilityConfirmModal
        show={showUtilityConfirmModal}
        setShow={setShowUtilityConfirmModal}
        setShowUtilityModal={setShowUtilityModal}
        receiveUtility={receiveUtility}
      />

      <ShowUtilityModal
        show={showUtilityModal}
        setShow={setShowUtilityModal}
        copyUtility={copyUtility}
        moment={moment}
        utility={utility}
      />

    </>
  );
}
