import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useParams} from 'react-router-dom';
import {getWindowSize} from '../../../../helpers/getWindowSize.jsx';
import '../../../../assets/styles/User/User.scss';
import FollowDetails from './FollowDetails';
import api from '../../../../api.js';
import Drawer from '../../general/Drawer';
import {selectShowFollowDrawer, setShowFollowDrawer} from '../../../../redux/slices/showDrawersSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectShowToast} from '../../../../redux/slices/toastSlice';
import {selectUserData} from '../../../../redux/slices/accountSlice';

export default function FollowModalAndDrawer({
                                                 setFollowStatusChanged,
                                                 followStatusChanged,
                                             }) {
    const dispatch = useDispatch();
    const loggedInUserData = useSelector(selectUserData);
    const {username} = useParams();

    const show = useSelector(selectShowFollowDrawer);
    const showToast = useSelector(selectShowToast);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const matches = (windowSize?.innerWidth <= 972);
    const [followings, setFollowings] = useState([]);
    const [followers, setFollowers] = useState([]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        api.get(`/users/${username ? username : loggedInUserData?.username}/following`).then(({data}) => {
            setFollowings(data);
        });

        api.get(`/users/${username ? username : loggedInUserData?.username}/followers`).then(({data}) => {
            setFollowers(data);
        });
        // showToast,
    }, [loggedInUserData, followStatusChanged]);

    return (
        <>
            {
                matches
                    ? (
                        <Drawer
                            name="followDrawer"
                            Content={(
                                <FollowDetails
                                    followings={followings}
                                    setFollowings={setFollowings}
                                    followers={followers}
                                    setFollowers={setFollowers}
                                    setFollowStatusChanged={setFollowStatusChanged}
                                />
                            )}
                        />
                    )
                    : (
                        <Modal
                            show={show}
                            onHide={() => {
                                dispatch(setShowFollowDrawer(false));
                            }}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="followDetailsModal"
                        >
                            <FollowDetails
                                followings={followings}
                                setFollowings={setFollowings}
                                followers={followers}
                                setFollowers={setFollowers}
                                setFollowStatusChanged={setFollowStatusChanged}
                            />
                        </Modal>
                    )
            }
        </>
    );
}
