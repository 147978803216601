import React, { useEffect, useState } from 'react';
import { FormCheck, Modal, Spinner } from 'react-bootstrap';

import { useIntl } from 'react-intl';

export default function SellNFTAttentionModal({
  show, setShow, setShowSellModal, transfers,
}) {
  const intl = useIntl();

  const [isAgreed, setIsAgreed] = useState(false);

  useEffect(() => {
    if (show === false) {
      setIsAgreed(false);
    }
    // console.log(transfers[transfers?.length - 1]?.destinationAddress);
    // console.log(transfers);
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="warningModal  modal-dialog-centered"
      show={show}
      backdrop
      onHide={handleClose}
    >
      <div className="unfollowModalBody">
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >

          <div className="sellModalHeader">
            <img
              src="/icons/close.svg"
              alt=""
              onClick={handleClose}
              className="close"
            />
          </div>

          <div className="attentionTitle">
            {intl.formatMessage({ id: 'attention' })}
          </div>

          <div className="warningDes">
            <li>
              {intl.formatMessage({ id: 'sell_nft_attention_1' })}
            </li>
            <br />
            <br />

            <li>
              {intl.formatMessage({ id: 'sell_nft_attention_2' })}
              &nbsp;
              { transfers ? `${transfers[transfers?.length - 1]?.destinationAddress?.slice(0, 5)}...${transfers[transfers?.length - 1]?.destinationAddress?.slice(-5)}` : ''}
              &nbsp;
              {intl.formatMessage({ id: 'sell_nft_attention_3' })}
            </li>
            <br />

            <li>
              {intl.formatMessage({ id: 'check_vpn' })}
            </li>

            <br />

            <li>
              {intl.formatMessage({ id: 'check_chain' })}
            </li>

          </div>

          <div className="sellAgreedRow">
            <FormCheck
              aria-label="Checkbox for sell your NFT"
              onChange={(event) => {
                setIsAgreed(event?.target?.checked);
              }}
              checked={isAgreed}
            />

            <div style={isAgreed ? { fontWeight: 600 } : { fontWeight: 500 }}>
              {intl.formatMessage({ id: 'agreed_Sell_attention' })}
            </div>
          </div>

          <button
            className="btn sellNextLevelButton"
            disabled={!isAgreed}
            onClick={() => {
              setShow(false);
              setShowSellModal(true);
            }}
          >
            {intl.formatMessage({ id: 'receive_utility' })}
            {/* { */}
            {/*    isLoading */}
            {/*      ? <Spinner animation="border" className="cancelSpinner" /> */}
            {/*      : intl.formatMessage({ id: 'receive_utility' }) */}
            {/*  } */}
          </button>
        </div>
      </div>
    </Modal>
  );
}
