import React from 'react';
import path from 'path';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';

export default function NftImage({
  moment, playMode, setPlayMode, setOpenBackdrop,
}) {
  if (!moment.mediaFile) {
    // if it was image
    if (moment?.image) {
      if (path.extname(moment.image) === '.jpg'
          || path.extname(moment.image) === '.JPG'
          || path.extname(moment.image) === '.png'
          || path.extname(moment.image) === '.PNG'
          || path.extname(moment.image) === '.svg'
          || path.extname(moment.image) === '.SVG'
          || path.extname(moment.image) === '.GIF'
          || path.extname(moment.image) === '.gif'
          || path.extname(moment.image) === '.jpeg'
          || path.extname(moment.image) === '.JPEG') {
        return (
          <div
            className="campaignImg"
            style={{ backgroundImage: `url(${moment?.image})` }}
            onClick={() => setOpenBackdrop((prevState) => (
              !prevState
            ))}
          >
            <img
              className="imageFullScreenIcon"
              src="/icons/fullScreen.svg"
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenBackdrop((prevState) => (
                  !prevState
                ));
              }}
            />
          </div>
        );
      }
    }
    if (path.extname(moment?.campaign.image) === '.jpg'
        || path.extname(moment?.campaign.image) === '.JPG'
        || path.extname(moment?.campaign.image) === '.png'
        || path.extname(moment?.campaign.image) === '.PNG'
        || path.extname(moment?.campaign.image) === '.svg'
        || path.extname(moment?.campaign.image) === '.SVG'
        || path.extname(moment?.campaign.image) === '.GIF'
        || path.extname(moment?.campaign.image) === '.gif'
        || path.extname(moment?.campaign.image) === '.jpeg'
        || path.extname(moment?.campaign.image) === '.JPEG') {
      return (
      // presale image
      //   <div
      //     className="campaignImg"
      //     style={{ backgroundImage: `url(${moment?.campaign.image})` }}
      //   >
      //     <img
      //       className="imageFullScreenIcon"
      //       src="/icons/fullScreen.svg"
      //       alt=""
      //       onClick={() => setOpenBackdrop((prevState) => (
      //         !prevState
      //       ))}
      //     />
      //   </div>
        <div className="profileImg">
          <div
            className="profilePicture"
            style={{ backgroundImage: `url(${moment?.campaign?.image})` }}
          />

          <img
            className="imageFullScreenIcon"
            src="/icons/fullScreen.svg"
            alt=""
            onClick={() => setOpenBackdrop((prevState) => (
              !prevState
            ))}
          />

        </div>
      );
    }
  } else if (playMode) {
    if (path.extname(moment.mediaFile) === '.mp3' || path.extname(moment.mediaFile) === '.wav' || path.extname(moment.mediaFile) === '.ogg') {
      // if audio
      return (
        <div
          className="campaignImg"
          style={{ backgroundImage: `url(${moment?.image})` }}
        >
          <audio controls id="yourAudio" className="nftPageAudio">
            <source src={moment.mediaFile} />
          </audio>
        </div>
      );
    }
    if (path.extname(moment.mediaFile) === '.mp4' || path.extname(moment.mediaFile) === '.webm') {
      // is video
      return (
        <div
          className="campaignImg"
        >
          <video controls id="yourAudio" className="nftPageVideo" poster={moment?.campaign?.image}>
            <source src={moment.mediaFile} />
          </video>
        </div>
      );
    }
  } else {
    // cover mode
    if (moment?.image) {
      return (
        <div
          className="campaignImg"
          onClick={() => setPlayMode(true)}
          style={{ backgroundImage: `url(${moment?.image})` }}
        >
          <div
            className="playIconSec"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPlayMode(true);
            }}
          >
            <img src="/icons/sportCategory/play.svg" alt="" />
          </div>
        </div>
      );
    }

    return (
    // cover mode for moments without Image
      <div className="profileImg">
        <div
          className="profilePicture"
          onClick={() => setPlayMode(true)}
          style={{ backgroundImage: `url(${moment?.campaign?.image})` }}
        />
        <div
          className="playIconSec"
          onClick={(e) => {
            setPlayMode(true);
          }}
        >
          <img src="/icons/sportCategory/play.svg" alt="" />
        </div>
      </div>
    );
  }
}
