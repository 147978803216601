// Utility functions for IndexedDB
function openIndexedDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('fallbackStorageDB', 1);

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      db.createObjectStore('storage', { keyPath: 'key' });
    };

    request.onsuccess = function (event) {
      resolve(event.target.result);
    };

    request.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

function getFromIndexedDB(key) {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openIndexedDB();
      const transaction = db.transaction(['storage'], 'readonly');
      const objectStore = transaction.objectStore('storage');
      const request = objectStore.get(key);

      request.onsuccess = function (event) {
        resolve(event.target.result ? event.target.result.value : null);
      };

      request.onerror = function (event) {
        reject(event.target.error);
      };
    } catch (error) {
      reject(error);
    }
  });
}

function removeFromIndexedDB(key) {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openIndexedDB();
      const transaction = db.transaction(['storage'], 'readwrite');
      const objectStore = transaction.objectStore('storage');
      const request = objectStore.delete(key);

      request.onsuccess = function (event) {
        resolve();
      };

      request.onerror = function (event) {
        reject(event.target.error);
      };
    } catch (error) {
      reject(error);
    }
  });
}

function setInIndexedDB(key, value) {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openIndexedDB();
      const transaction = db.transaction(['storage'], 'readwrite');
      const objectStore = transaction.objectStore('storage');
      const request = objectStore.put({ key, value });

      request.onsuccess = function (event) {
        resolve();
      };

      request.onerror = function (event) {
        reject(event.target.error);
      };
    } catch (error) {
      reject(error);
    }
  });
}

export default function getLocalStorage() {
  return {
    async getItem(key) {
      try {
        if (localStorage) {
          return localStorage.getItem(key);
        }
        return await getFromIndexedDB(key);
      } catch (e) {
        console.error('Error accessing storage:', e);
        return await getFromIndexedDB(key);
      }
    },
    async setItem(key, value) {
      try {
        if (localStorage) {
          localStorage.setItem(key, value);
        } else {
          await setInIndexedDB(key, value);
        }
      } catch (e) {
        console.error('Error accessing storage:', e);
        await setInIndexedDB(key, value);
      }
    },
    async removeItem(key) {
      try {
        if (localStorage) {
          localStorage.removeItem(key);
        } else {
          await removeFromIndexedDB(key);
        }
      } catch (e) {
        console.error('Error accessing storage:', e);
        await removeFromIndexedDB(key);
      }
    },
  };
}
