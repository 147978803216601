import React from 'react';
import '../../../../assets/styles/User/tables.scss';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectLanguage } from '../../../../redux/slices/languageSlice';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import addCommas from '../../../../helpers/addCommas.jsx';
import PersianDate from '../../general/PersianDate.jsx';

export default function Transaction({ transactions, pageLoading }) {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);

  return (
    <div className="giftTableWrapper">
      <table className="giftTable">
        <thead>
          <tr className="transactionTableHead">
            <th className="hashtagTh">#</th>
            <th>{intl.formatMessage({ id: 'count' })}</th>
            <th>{intl.formatMessage({ id: 'price' })}</th>
            <th>{intl.formatMessage({ id: 'status' })}</th>
            <th className="referenceTh">{intl.formatMessage({ id: 'reference_code' })}</th>
            <th>
              <div className="gatewayTh">
                {intl.formatMessage({ id: 'gateway' })}
              </div>
            </th>
            <th>
              <div className="flexClass align-items-center">
                <div>
                  {' '}
                  {intl.formatMessage({ id: 'date' })}
                </div>
                <img src="/icons/giftArrowDown.svg" alt="" />
              </div>
            </th>
            <th className="receiptTh">{intl.formatMessage({ id: 'receipt' })}</th>
          </tr>
        </thead>

        <tbody>

          {
          transactions?.payments?.map((transaction, idx) => {
            const date = new Date(transaction?.createdAt);
            const persianDate = new PersianDate(transaction?.createdAt);
            return (
              <tr key={transaction?.id}>
                <td className="hashtagTd">{languageRedux === 'fa' ? ToPersianNumber(idx + 1) : idx + 1}</td>
                <td className="secondTd">
                  <div className="countTd">
                    {` ${languageRedux === 'fa' ? ToPersianNumber(transaction?.quantity) : transaction?.quantity} 
                                          ${transaction?.type === 'single' ? intl.formatMessage({ id: 'moments' }) : intl.formatMessage({ id: 'editions' })} `}
                  </div>
                </td>

                <td className="secondTd">
                  <div className="priceTd">
                    {`${languageRedux === 'fa' ? ToPersianNumber(addCommas(transaction.amount)) : addCommas(transaction.amount)} ${transaction?.currency?.symbol}`}
                  </div>
                </td>

                <td className="forthTd">
                  <strong
                    className={`statusTd ${transaction?.status === 'success' ? 'successColor'
                      : transaction?.status === 'pending' ? 'processingColor' : 'unsuccessfulColor'}`}
                  >
                    {
                        transaction?.status === 'pending'
                          ? intl.formatMessage({ id: 'processing' })
                          : transaction?.status === 'success'
                            ? intl.formatMessage({ id: 'successful' })
                            : intl.formatMessage({ id: 'unsuccessful' })
                      }
                  </strong>
                </td>

                <td className="forthTd">
                  <div className="walletAddressTd">
                    {transaction?.refCode ? transaction?.refCode : '-'}
                  </div>
                </td>

                <td className="forthTd">
                  <div className="gatewayTd">
                    <img
                      className="gatewayIcon"
                      src={transaction?.paymentMethod?.logo}
                      alt=""
                    />
                  </div>
                </td>

                <td className="fifthTd">
                  <strong className="dateTd">
                    {
                        languageRedux === 'fa'
                          ? `${ToPersianNumber(persianDate.getDate())} / ${ToPersianNumber(persianDate.getMonth() + 1)} / ${ToPersianNumber(persianDate.getYear())}`
                          : `${date.getMonth() + 1} / ${date.getDate()} / ${date.getFullYear()}`
                      }
                  </strong>
                </td>

                <td>
                  <Link to={`/payment-receipt/${transaction?.uuid}`} target="_blank">
                    <div className="receiptTd">
                      <img src="/icons/sportCategory/userLinkArrow.svg" alt="" />
                    </div>
                  </Link>
                </td>

              </tr>
            );
          })

        }
        </tbody>
      </table>
    </div>
  );
}
