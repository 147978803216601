import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import { setShowLogin } from '../../../redux/slices/accountSlice';

export default function NotCoinGiftSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signUpBtnHandler = () => {
    if (localStorage.getItem('accessToken')) {
      navigate('/profile');
    } else {
      dispatch(setShowLogin(true));
    }
  };

  return (
    <div className="container">
      <section className="giftNotCoinRow text-center">

        <div className="giftsTextSec">
          <h2 className="notCoin100">
            <img src="/icons/notCoin.png" alt="نات کوین" />
            {ToPersianNumber(' 100 نات کوین,هدیه ثبت‌نام')}
          </h2>

          <p className="receiveGiftInfo">
            کمتر از یک دقیقه جایزه خود را دریافت کنید، فقط کافیه در سایت مومنت اسپرت ثبت نام کنید و به کیف
            پول خود سر بزنید، به همین سادگی!
          </p>

          <button
            className="btn btn-primary-custom text-white receiveNotCoinBtn PcReceiveBtn"
            onClick={signUpBtnHandler}
          >
            ثبت نام و دریافت 100 نات کوین
          </button>
        </div>

        <div>

          <div className="stepsWrapper">
            <div className="d-flex flex-column align-items-center">
              <img
                className="stepsImg"
                src="/images/step1.png"
                alt="ثبت نام"
              />
              <p className="">ثبت نام کنید</p>
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center stepsArrow">
              <img
                className="stepsArrowIcon"
                src="/icons/arrow.svg"
                alt="دریافت نات کوین"
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                className="stepsImg"
                src="/images/step2.png"
                alt="Step 2"
              />
              <p className="">{ToPersianNumber('+ 100 نات کوین')}</p>
            </div>

          </div>

          <button
            className="btn text-white receiveNotCoinBtn mobileReceiveBtn"
            onClick={signUpBtnHandler}
          >
            ثبت نام و دریافت 100 نات کوین
          </button>

        </div>

      </section>

    </div>
  );
}
