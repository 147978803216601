import React from 'react';
import { useSelector } from 'react-redux';
import path from 'path';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import { selectLanguage } from '../../../../redux/slices/languageSlice';

export default function MomentImage({
  moment,
  playMode,
  setPlayMode,
}) {
  const languageRedux = useSelector(selectLanguage);
  const intl = useIntl();

  const location = useLocation();

  if (!moment.mediaFile) {
    // if it was image
    if (moment?.image) {
      if (path.extname(moment.image) === '.jpg'
                || path.extname(moment.image) === '.JPG'
                || path.extname(moment.image) === '.png'
                || path.extname(moment.image) === '.PNG'
                || path.extname(moment.image) === '.svg'
                || path.extname(moment.image) === '.SVG'
                || path.extname(moment.image) === '.GIF'
                || path.extname(moment.image) === '.gif'
                || path.extname(moment.image) === '.jpeg'
                || path.extname(moment.image) === '.JPEG') {
        return (
          <div className="preSaleImageWrapper">

            {/*{*/}
            {/*      (moment?.type === 'single' && moment?.hasUtility === true)*/}
            {/*      && (*/}
                  <div className="preSaleLabel">
                    <img src="/icons/hasUtility.svg" alt="" loading="lazy" />
                    {intl.formatMessage({ id: 'with_utility' })}
                  </div>
              {/*    )*/}
              {/*}*/}

            <div
              className="normalNftImage"
              style={{ backgroundImage: `url(${moment.image})` }}
            />
            {
                            moment?.isForSale
                            && (
                            <div className="momentOnSaleSec">
                              {intl.formatMessage({ id: 'buy' })}
                            </div>
                            )
                        }

            <div className="nftInfo">
              <strong className={moment?.isForSale ? 'onSaleNftName' : 'nftName'}>
                {languageRedux === 'fa' ? ToPersianNumber(moment.title) : moment.title}
              </strong>
              <div className="iconSec">
                {
                                    moment.campaign
                                    && <img src="/icons/profileCampaign.svg" alt="" className="campaignSvg" />
                                }
                <img src={moment.chain.logo} alt="" className="chainImage" />
              </div>
            </div>
          </div>
        );
      }
    }
    if (path.extname(moment?.campaign.image) === '.jpg'
            || path.extname(moment?.campaign.image) === '.JPG'
            || path.extname(moment?.campaign.image) === '.png'
            || path.extname(moment?.campaign.image) === '.PNG'
            || path.extname(moment?.campaign.image) === '.svg'
            || path.extname(moment?.campaign.image) === '.SVG'
            || path.extname(moment?.campaign.image) === '.GIF'
            || path.extname(moment?.campaign.image) === '.gif'
            || path.extname(moment?.campaign.image) === '.jpeg'
            || path.extname(moment?.campaign.image) === '.JPEG') {
      return (
      // presale image
        <div className="preSaleImageWrapper">

          {/*{*/}
          {/*              (moment?.type === 'single' && moment?.hasUtility === true)*/}
          {/*              && (*/}
                        <div className="preSaleLabel">
                          <img src="/icons/hasUtility.svg" alt=""  loading="lazy"/>
                          {intl.formatMessage({ id: 'with_utility' })}
                        </div>
             {/*           )*/}
             {/*}*/}

          <div className="blurMomentImage" style={{ backgroundImage: `url(${moment?.campaign?.image})` }} />

          <div className="grayFilter" />
          {
                        moment?.isForSale
                        && (
                        <div className="momentOnSaleSec">
                          {intl.formatMessage({ id: 'buy' })}
                        </div>
                        )
                    }
          <div className="nftInfo">
            <strong className={moment?.isForSale ? 'onSaleNftName' : 'nftName'}>
              {languageRedux === 'fa' ? ToPersianNumber(moment.title) : moment.title}
            </strong>
            <div className="iconSec">
              {moment?.campaign && <img src="/icons/profileCampaign.svg" alt="" className="campaignSvg" />}
              <img src={moment?.chain?.logo} alt="" className="chainImage" />
            </div>
          </div>

          <div className="preSaleNumber">
            #
            {languageRedux === 'fa' ? ToPersianNumber(String(moment.posInCampaign)) : moment.posInCampaign}
          </div>
        </div>
      );
    }
  } else if (playMode) {
    if (path.extname(moment.mediaFile) === '.mp3' || path.extname(moment.mediaFile) === '.wav' || path.extname(moment.mediaFile) === '.ogg') {
      // is audio
      return (
        <div className="preSaleImageWrapper">
          <div style={{ backgroundImage: `url(${moment.image})` }} alt="" className="audioImage" />
          <div
            className="videoSec"
          >
            <audio controls id="yourAudio" className="video">
              <source src={moment.mediaFile} />
            </audio>
          </div>
        </div>

      );
    }
    if (path.extname(moment.mediaFile) === '.mp4' || path.extname(moment.mediaFile) === '.webm') {
      // is video
      return (
        <div className="preSaleImageWrapper">

          <div
            className="videoSec"
          >
            <video
              controls
              id="yourAudio"
              className="video"
              poster={moment?.campaign?.image}
            >
              <source src={moment.mediaFile} />
            </video>
          </div>

        </div>

      );
    }
  } else {
    // cover mode
    if (moment?.image) {
      return (
        <div className="preSaleImageWrapper">

          {/*{*/}
          {/*      (moment?.type === 'single' && moment?.hasUtility === true)*/}
          {/*      && (*/}
                <div className="preSaleLabel">
                  <img src="/icons/hasUtility.svg" alt="" loading="lazy"/>
                  {intl.formatMessage({ id: 'with_utility' })}
                </div>
            {/*    )*/}
            {/*}*/}

          <div
            className="normalNftImage"
            style={{ backgroundImage: `url(${moment?.image ? moment?.image : moment?.campaign?.image})` }}
          />
          <div className="playIconSec">
            <img
              src={location.pathname.includes('family') ? '/icons/family/play.svg' : '/icons/sportCategory/play.svg'}
              alt=""
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (playMode === false) {
                  setPlayMode(true);
                }
              }}
            />
          </div>

          {
                        moment?.isForSale
                        && (
                        <div className="momentOnSaleSec">
                          {intl.formatMessage({ id: 'buy' })}
                        </div>
                        )
                    }

          <div className="nftInfo">
            <strong className={moment?.isForSale ? 'onSaleNftName' : 'nftName'}>
              {languageRedux === 'fa' ? ToPersianNumber(moment.title) : moment.title}
            </strong>
            <div className="iconSec">
              {
                                moment.campaign
                                && <img src="/icons/profileCampaign.svg" alt="" className="campaignSvg" />
                            }
              <img src={moment.chain.logo} alt="" className="chainImage" />
            </div>
          </div>
        </div>
      );
    }
    return (
    // cover mode for moment without image
      <div className="preSaleImageWrapper">
        {/* <div className="preSaleLabel"> */}
        {/*  <img src="/icons/hourglass.svg" alt="" /> */}
        {/*  {intl.formatMessage({ id: 'creating_moment' })} */}
        {/* </div> */}
        <div className="blurMomentImage" style={{ backgroundImage: `url(${moment?.campaign?.image})` }} />

        <div className="grayFilter" />

        <div className="playIconSec">
          <img
            src="/icons/sportCategory/play.svg"
            alt=""
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (playMode === false) {
                setPlayMode(true);
              }
            }}
          />
        </div>

        {
                    moment?.isForSale
                    && (
                    <div className="momentOnSaleSec">
                      {intl.formatMessage({ id: 'buy' })}
                    </div>
                    )
                }

        <div className="nftInfo">
          <strong className={moment?.isForSale ? 'onSaleNftName' : 'nftName'}>
            {languageRedux === 'fa' ? ToPersianNumber(moment.title) : moment.title}
          </strong>
          <div className="iconSec">
            {moment?.campaign && <img src="/icons/profileCampaign.svg" alt="" className="campaignSvg" />}
            <img src={moment?.chain?.logo} alt="" className="chainImage" />
          </div>
        </div>

        <div className="preSaleNumber">
          #
          {languageRedux === 'fa' ? ToPersianNumber(String(moment.posInCampaign)) : moment.posInCampaign}
        </div>
      </div>
    );
  }
}
