import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import ProfileCard from '../../general/ProfileCard/ProfileCard';
import MomentCard from '../../general/Card/MomentCard';
import MomentCardSkeleton from '../../general/Card/MomentCardSkeleton';
import Skeleton from '../../general/Skeleton.jsx';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import Pagination from '../Pagination';
import {selectLanguage} from '../../../../redux/slices/languageSlice';
import {setShowCreatedModalAfterLogin, setShowCreateModal} from '../../../../redux/slices/createNFTSlice';
import {selectUserData, setShowLogin} from '../../../../redux/slices/accountSlice';

function Items({
                   momentsData, setTransferToWallet, setPublishStatusChanged, tab,
                   userDatas,
                   setIsMomentStatusChanged,
               }) {
    const {username} = useParams();
    const lsUsername = localStorage.getItem('username');

    const [isCancelStatusChanged, setIsCancelStatusChanged] = useState(false);

    const loggedInUserData = useSelector(selectUserData);

    // username ? username === lsUsername : loggedInUserData?.username === lsUsername

    return momentsData.moments
        && momentsData.moments?.map((moment, idx) => {
            if (username ? username === lsUsername : loggedInUserData?.username === lsUsername) {
                return (
                    <ProfileCard
                        moment={moment}
                        key={moment?.id}
                        username={username}
                        setTransferToWallet={setTransferToWallet}
                        setPublishStatusChanged={setPublishStatusChanged}
                        setIsMomentStatusChanged={setIsMomentStatusChanged}
                        tab={tab}
                        userDatas={userDatas}
                    />
                );
            }
            if (moment?.isPublic) {
                return (
                    <MomentCard
                        moment={moment}
                        key={moment?.id}
                        isProfile={username === lsUsername}
                    />
                );
            }
        });
}

export default function Index({
                                  tabContentLoading,
                                  setPublishStatusChanged, setTransferToWallet, momentsData,
                                  endOffset, tab,
                                  page, setPage,
                                  userDatas,
                                  itemOffset,
                                  setItemOffset,
                                  setIsMomentStatusChanged,
                              }) {
    const intl = useIntl();
    const languageRedux = useSelector(selectLanguage);
    const {username} = useParams();
    const lsUsername = localStorage.getItem('username');
    const dispatch = useDispatch();

    const navigate = useNavigate();

    // const [itemOffset, setItemOffset] = useState(1);
    // const [page, setPage] = useState(1);
    // const [transferToWallet, setTransferToWallet] = useState(false);
    // const [publishStatusChanged, setPublishStatusChanged] = useState(false);

    const handlePageClick = (event) => {
        console.log(tab);
        navigate(`/users/${username}/${tab}?page=${(event.selected + 1)}`);

        window.scrollTo(0, 0);
        setPage(event.selected + 1);
    };

    const createNFT = () => {
        if (localStorage.getItem('accessToken')) {
            dispatch(setShowCreateModal(true));
        } else {
            dispatch(setShowLogin(true));
            dispatch(setShowCreatedModalAfterLogin(true));
        }
    };
    return (
        <>
            {/* <div className={((lsUsername === username ? momentsData.moments?.length > 2 : momentsData.moments?.length > 2) || tabContentLoading) ? 'nftList' : 'nftListFlexStart'}> */}
            <div className="nftList">
                {
                    tabContentLoading
                        ? (
                            <>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                                <MomentCardSkeleton/>
                            </>
                        )
                        : (
                            <>
                                {
                                    (username === lsUsername && tab === 'created')
                                    && (
                                        <div className="createNFTCardWrapper">
                                            <div className="createNFTCard" onClick={createNFT}>
                                                <div className="flexClass align-items-center">
                                                    <img src="/icons/greenAdd.svg" alt=""/>
                                                    <div>
                                                        {intl.formatMessage({id: 'create_new_NFT'})}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <Items
                                    momentsData={momentsData}
                                    setPublishStatusChanged={setPublishStatusChanged}
                                    setTransferToWallet={setTransferToWallet}
                                    setIsMomentStatusChanged={setIsMomentStatusChanged}
                                    tab={tab}
                                    userDatas={userDatas}
                                />
                            </>
                        )
                }
            </div>
            {
                tabContentLoading
                    ? (
                        <div className="paginationSec">
                            <Skeleton style={{height: 16, width: 160}} className="showTitle"/>
                            <Skeleton style={{height: 28, width: 160}} className="explorePagination"/>
                        </div>
                    ) : momentsData?.pages > 1
                    && (
                        <div className="paginationSec">
                            <div className="showTitle">
                                {`${intl.formatMessage({id: 'showing'})}
                               ${languageRedux === 'fa' ? ToPersianNumber(itemOffset) : itemOffset}
                                ${intl.formatMessage({id: 'to'})} 
                                ${languageRedux === 'fa' ? ToPersianNumber(endOffset) : endOffset}
                                 ${intl.formatMessage({id: 'of'})} 
                                 ${languageRedux === 'fa' ? ToPersianNumber(momentsData.momentsCount)
                                    : momentsData.momentsCount} 
                                  ${intl.formatMessage({id: 'entries'})}`}
                            </div>
                            <Pagination
                                handlePageClick={handlePageClick}
                                data={momentsData}
                                page={page}
                            />
                        </div>

                    )
            }
        </>
    );
}
