import React from 'react';
import '../../../assets/styles/Nft/Nft.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ProfilePicture from '../other/ProfilePicture';
import { ShowUserName } from '../general/ShowUserName';
import PersianDate from '../general/PersianDate';
import { selectLanguage } from '../../../redux/slices/languageSlice';
import Skeleton from '../general/Skeleton.jsx';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import NftImage from './NftImage';
import EditionSelect from './EditionSelect.jsx';
import ActionsButtons from './ActionsButtons.jsx';

export default function DesktopGeneralSec({
  setOpenBackdrop,
  moment,
  isFavorite,
  makeFavorite,
  pageLoading,
  playMode,
  setPlayMode,
  setIsMomentStatusChanged,
  isTransferTokenLoading,
  setIsTransferTokenLoading,
  transfers,
  editions,
   setSelectedEdition,
   selectedEdition
}) {
  const languageRedux = useSelector(selectLanguage);
  const navigate = useNavigate();
  const intl = useIntl();
  const { id, editionId } = useParams();

  let date;
  let year;
  let month;
  let day;

  if (localStorage.getItem('i18nConfig')) {
    if (localStorage.getItem('i18nConfig').includes('fa')) {
      date = new PersianDate(moment?.momentOccurrence);
      year = date.getYear();
      month = date.getMonth();
      day = date.getDate();
    }
    if (localStorage.getItem('i18nConfig').includes('en') || localStorage.getItem('i18nConfig').includes('tr')) {
      date = new Date(moment?.momentOccurrence);
      year = date.getFullYear();
      month = date.getMonth();
      day = date.getDate();
    }
  } else {
    localStorage.setItem('i18nConfig', JSON.stringify({ selectedLang: 'en' }));
    date = new Date(moment?.momentOccurrence);
    year = date.getFullYear();
    month = date.getMonth();
    day = date.getDate();
  }

  return (
    <div className="general">
      {
                pageLoading ? <Skeleton style={{}} className="campaignImg" />
                  : (
                    <NftImage
                      moment={moment}
                      playMode={playMode}
                      setPlayMode={setPlayMode}
                      setOpenBackdrop={setOpenBackdrop}
                    />
                  )
            }
      <div className="likesRow">
        {
                    pageLoading
                      ? <Skeleton style={{ height: 24, width: 84 }} className="" />
                      : (
                        <div style={{ display: 'flex' }}>
                          <img src={moment.chain?.logo} alt="" />
                          <div className="chainName">{moment.chain?.name}</div>
                        </div>
                      )
                }
        {
                    pageLoading
                      ? <Skeleton style={{ height: 28 }} className="likeSec" />
                      : (
                        <div className="likeSec">
                          {/* <div className="likeIcon" onClick={makeFavorite}> */}
                          {/*  <img src={isFavorite ? '/icons/liked.svg' : '/icons/like.svg'} alt="" /> */}
                          {/*  {languageRedux === 'fa' ? ToPersianNumber(moment?.favoritesCount) : moment?.favoritesCount} */}
                          {/* </div> */}
                          {/* <div> */}
                          {/*  <img src="/icons/comment.svg" alt="" /> */}

                          {/*  {languageRedux === 'fa' ? ToPersianNumber(String(moment?.comments?.length)) : String(moment?.comments?.length)} */}
                          {/* </div> */}
                        </div>
                      )
                }

      </div>
      {/* { */}
      {/*          moment?.momentEditions?.length > 0 */}
      {/*          && ( */}
      {/*          <EditionSelect */}
      {/*            moment={moment} */}
      {/*            isTop */}
      {/*            selectedEdition={selectedEdition} */}
      {/*            setSelectedEdition={setSelectedEdition} */}
      {/*          /> */}
      {/*          ) */}
      {/*      } */}
      {
                pageLoading
                  ? <Skeleton style={{}} className="priceSec" />
                  : (
                    <ActionsButtons
                      moment={moment}
                      setIsMomentStatusChanged={setIsMomentStatusChanged}
                      isTransferTokenLoading={isTransferTokenLoading}
                      setIsTransferTokenLoading={setIsTransferTokenLoading}
                      transfers={transfers}
                      editions={editions}
                      selectedEdition={selectedEdition}
                      setSelectedEdition={setSelectedEdition}
                    />
                  )
            }
      {
                moment?.clubs?.length > 0
                && moment?.clubs?.slice(0, 2).map((club) =>
                // console.log(club);
                  (
                    <Link to={`/clubs/${club?.id}`}>
                      <div className="itemsRow" style={{ cursor: 'pointer' }}>
                        {
                                    pageLoading
                                      ? (
                                        <>
                                          <Skeleton style={{ height: 24, width: 84 }} className="rowTitle" />
                                          <Skeleton style={{ height: 24 }} className="answer" />
                                        </>
                                      )
                                      : (
                                        <>
                                          <strong className="rowTitle">
                                            {intl.formatMessage({ id: 'club' })}
                                            :
                                          </strong>
                                          <strong
                                            className="answer"
                                          >
                                            {club?.name ? club?.name : '-'}
                                          </strong>
                                        </>
                                      )
                                }
                      </div>
                    </Link>
                  ))
            }

      {
                pageLoading
                  ? (
                    <div className="itemsRow">
                      <Skeleton style={{ height: 24, width: 84 }} className="rowTitle" />
                      <Skeleton style={{ height: 24 }} className="ownerAnswer" />
                    </div>
                  )
                  : (
                    <div className="itemsRow">
                      <strong className="rowTitle">
                        {intl.formatMessage({ id: 'owner' })}
                        :
                      </strong>
                      {/* todo link owner to profile */}
                      {/* <Link to={`/users/${moment?.owner === null ? selectedEdition?.owner?.username : moment?.owner?.username}`}> */}

                      {/* { */}
                      {/*    moment?.momentEditions?.length > 0 ? */}
                      {/*        !selectedEdition?.owner ? */}
                      {/*            <div className="ownerAnswer"> */}
                      {/*              <div */}
                      {/*                  className="ownerImage" */}
                      {/*                  style={{ backgroundImage: `url(/imafges)` }} */}
                      {/*              /> */}
                      {/*              <strong className={languageRedux === 'fa' ? 'ltrDirection' : 'rtlDirection'}> */}
                      {/*                <ShowUserName user={moment?.owner === null */}
                      {/*                    ? selectedEdition?.owner : moment?.owner} */}
                      {/*                /> */}
                      {/*              </strong> */}
                      {/*            </div> */}

                      {/* } */}

                      <div className="ownerAnswer">
                        {
                                    moment?.owner === null
                                      ? selectedEdition?.owner?.image
                                        ? (
                                          <div
                                            className="ownerImage"
                                            style={{ backgroundImage: `url(${selectedEdition?.owner?.image})` }}
                                          />
                                        )
                                        : <ProfilePicture colors={selectedEdition?.owner?.profileColors} />
                                      : moment.owner?.image
                                        ? (
                                          <div
                                            className="ownerImage"
                                            style={{ backgroundImage: `url(${moment.owner?.image})` }}
                                          />
                                        )
                                        : <ProfilePicture colors={moment.owner?.profileColors} />
                                }
                        <strong className={languageRedux === 'fa' ? 'ltrDirection' : 'rtlDirection'}>
                          <ShowUserName user={moment?.owner === null
                            ? selectedEdition?.owner : moment?.owner}
                          />
                        </strong>
                      </div>

                    </div>
                  )

            }

      {
                pageLoading
                  ? (
                    <div className="itemsRow">

                      <Skeleton style={{ height: 24, width: 84 }} className="rowTitle" />
                      <Skeleton style={{ height: 24 }} className="answer" />
                    </div>
                  )
                  : moment?.momentOccurrence && (
                    <div className="itemsRow">
                      <strong className="rowTitle">
                        {intl.formatMessage({ id: 'creation_date' })}
                        :
                      </strong>
                      <strong className="answer">
                        {
                                languageRedux === 'fa'
                                  ? `${ToPersianNumber(String(day))} / ${ToPersianNumber(String(month + 1))} / ${ToPersianNumber(String(year))}`
                                  : `${year} / ${month + 1} / ${day}`
                            }
                      </strong>
                    </div>
                  )
            }

    </div>
  );
}
