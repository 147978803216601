import React, {Suspense, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
// import {chain, configureChains, createClient, WagmiConfig} from 'wagmi';
// import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum';
// import { Web3Modal } from '@web3modal/react';

import {useDispatch} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';

import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet';
import Layout from './components/Layout.jsx';
import {setLanguage} from './redux/slices/languageSlice';
import {
    setIsLogin, setLoadingSummary, setLoginType, setUserData,
} from './redux/slices/accountSlice';

import api from './api.js';
import {useScrollToTop} from './helpers/useScrollToTop.jsx';

import './assets/styles/general/General.scss';
import {Web3Modal} from './Web3Modal.jsx';

const I18nProvider = React.lazy(() => import('../_metronic/i18n/i18nProvider'));

export default function App() {
    const I18N_CONFIG_KEY = import.meta.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

    const dispatch = useDispatch();
    const wcPid = import.meta.env.VITE_WALLETCONNECT_PID;

    // const chains = [chain.polygon];
    // const { provider } = configureChains(chains, [
    //   walletConnectProvider({ projectId: wcPid }),
    // ]);
    // const wagmiClient = createClient({
    //   autoConnect: true,
    //   connectors: modalConnectors({ appName: 'web3Modal', chains }),
    //   provider,
    // });
    // const ethereumClient = new EthereumClient(wagmiClient, chains);

    const location = useLocation();

    const { pathname } = useLocation();
    const callSummary = () => {
        api.get('/profile/summary', {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then(({data}) => {
            localStorage.setItem('username', data?.user?.username);
            dispatch(setLoadingSummary(false));
            dispatch(setUserData(data.user));
        }).catch(({error}) => {
            dispatch(setLoadingSummary(false));
            dispatch(setIsLogin(false));
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
            localStorage.removeItem('loginType');
        });
    };

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            if (localStorage.getItem('loginType')) {
                dispatch(setLoginType(localStorage.getItem('loginType')));
            }
            dispatch(setIsLogin(true));
            callSummary();
        } else {
            dispatch(setLoadingSummary(false));
        }

        if (localStorage.getItem(I18N_CONFIG_KEY)) {
            // todo uncomment this
            if (localStorage.getItem(I18N_CONFIG_KEY).includes('fa')) {
                document.getElementById('htmlRoot').dir = 'rtl';
                dispatch(setLanguage('fa'));
                document.documentElement.setAttribute('lang', 'fa');
            } else {
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'fa'}));
                document.getElementById('htmlRoot').dir = 'rtl';
                dispatch(setLanguage('fa'));
                document.documentElement.setAttribute('lang', 'fa');
            }

            // todo comment this
            // if (localStorage.getItem(I18N_CONFIG_KEY).includes('en')) {
            //   document.getElementById('htmlRoot').dir = 'ltr';
            //   dispatch(setLanguage('en'));
            //   document.documentElement.setAttribute('lang', 'en');
            // } else {
            //   localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: 'fa' }));
            //   document.getElementById('htmlRoot').dir = 'rtl';
            //   dispatch(setLanguage('fa'));
            //   document.documentElement.setAttribute('lang', 'fa');
            // }
        } else {
            localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'fa'}));
            document.getElementById('htmlRoot').dir = 'rtl';
            dispatch(setLanguage('fa'));
            document.documentElement.setAttribute('lang', 'fa');
        }
        if (window.location.hash) {
            if (window.location.hash[0] === '#') {
                api.post('/redirect', {
                    path: window.location.hash?.slice(2),
                }).then(({data}) => {
                    window.location.replace(data?.redirectUrl);
                });
            }
        }

        // window.addEventListener('storage', () => {
        //   if (localStorage.getItem('accessToken')) {
        //     dispatch(setIsLogin(true));
        //     console.log('call summary storage');
        //     callSummary();
        //   } else {
        //     dispatch(setIsLogin(false));
        //   }
        // });

        const searchParams = new URLSearchParams(window.location.search);
        const content = searchParams.get('utm_content');
        console.log("utm/content before checking the exact value ", content)
        if (content) {
            // setUtmContent(content);
            console.log("utm/content ", content)
            localStorage.setItem('utm_content', content);

            // Optionally store it in session/local storage or make an API call

        }
        else {
            localStorage.removeItem('utm_content');
        }

    }, []);

    const setGA = () => {
        ReactGA.initialize('G-0DEMY5HDXX');
        ReactGA.pageview('Init page view');
    };

    useEffect(() => {
        setGA();
    }, [location]);

    // todo service worker
    // function registerServiceWorker() {
    //   if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker
    //       .register('/service-worker.js')
    //       .then((registration) => {
    //         console.log('Service Worker registered!', registration);
    //       })
    //       .catch((error) => {
    //         console.error('Error registering Service Worker:', error);
    //       });
    //   }
    // }
    // registerServiceWorker();
    useScrollToTop();

    console.log("is loading walletConnect ", pathname === "/profile" || pathname?.includes("/users/") || pathname?.includes("/nfts/"))
    function Loading() {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    right: '0',
                    left: '0',
                }}
                className="projectLoadingWrapper"
            >
                <div>
                    <img src="/momentSportFav-512x512.png" alt="" style={{width: 80, height: 80}}/>
                </div>
            </div>
        );
    }

    return (
        <Suspense fallback={<Loading/>}>
            <I18nProvider>
                {/* <WagmiConfig config={wagmiConfig}> */}

                {/* <WagmiConfig client={wagmiClient}> */}
                {/*  <Web3Modal projectId={wcPid} ethereumClient={ethereumClient} /> */}
                <HelmetProvider>
                    {/* { */}
                    {/*  location.pathname.includes('family') */}
                    {/*    ? ( */}
                    {/*      <FamilyLayout> */}
                    {/*        <Outlet /> */}
                    {/*      </FamilyLayout> */}
                    {/*    ) */}
                    {/*    : location.pathname.includes('/etrat-fatemi') ? ( */}
                    {/*      <EtratLayout> */}
                    {/*        <Outlet /> */}
                    {/*      </EtratLayout> */}
                    {/*    ) */}
                    {/*      : ( */}
                    {
                        pathname?.includes("/profile") || pathname?.includes("/users/") || pathname?.includes("/nfts/") ?
                            <Web3Modal>

                                {import.meta.env.VITE_APP_NO_INDEX === 'true' && (
                                    <Helmet>
                                        <meta name="robots" content="noindex"/>
                                    </Helmet>
                                )}

                                <Layout>
                                    <Outlet/>
                                </Layout>
                            </Web3Modal> :
                            <>

                                {import.meta.env.VITE_APP_NO_INDEX === 'true' && (
                                    <Helmet>
                                        <meta name="robots" content="noindex"/>
                                    </Helmet>
                                )}

                                <Layout>
                                    <Outlet/>
                                </Layout>
                            </>
                    }
                    {/*<Web3Modal>*/}

                    {/*    {import.meta.env.VITE_APP_NO_INDEX === 'true' && (*/}
                    {/*        <Helmet>*/}
                    {/*            <meta name="robots" content="noindex"/>*/}
                    {/*        </Helmet>*/}
                    {/*    )}*/}

                    {/*    <Layout>*/}
                    {/*        <Outlet/>*/}
                    {/*    </Layout>*/}
                    {/*</Web3Modal>*/}

                    {/* <h1> */}
                    {/*  fbm, cv */}
                    {/* </h1> */}

                    {/* ) */}
                    {/*      } */}
                </HelmetProvider>
                {/* </WagmiConfig> */}
                {/* </WagmiConfig> */}
            </I18nProvider>
        </Suspense>
    );
}
