import React, {useEffect} from 'react';
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import api from '../../../../api.js';
import { setShowToast, setToastMessage, setToastStatus } from '../../../../redux/slices/toastSlice';

export default function SendOtpRequestLevel({
  setShow, isPhone, inputs, inputHandler,
  setIsFirstLevel, setIsTimer, setTimer, user,
  setInputs,
}) {
  const PhoneInputReact = PhoneInput.default ? PhoneInput.default : PhoneInput;
  const intl = useIntl();
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const dispatch = useDispatch();

  useEffect(()=>{
    console.log(" is phone",isPhone)
  }, [])

  const sendVerificationCode = (event) => {
    event.preventDefault();
    if (isPhone) {
      api.post('/profile/update-mobile', {
        mobile: inputs?.mobile?.value,
      }).then(({ data }) => {
        setIsTimer(true);
        setTimer(120);
        setIsFirstLevel(false);
        setIsFirstLevel(false);
      }).catch(({ response }) => {
        if (response?.data?.mobile) {
          dispatch(setToastStatus(0));
          dispatch(setToastMessage(response?.data?.mobile?.msg));
          dispatch(setShowToast(true));
        }
      });
    } else {
      api.post('/profile/update-email', {
        email: inputs?.email?.value,
      }).then(({ data }) => {
        setIsTimer(true);
        setTimer(120);
        setIsFirstLevel(false);
      }).catch(({ response }) => {
        if (response?.data?.email) {
          dispatch(setToastStatus(0));
          dispatch(setToastMessage(response?.data?.email?.msg));
          dispatch(setShowToast(true));
        }
      });
    }
  };

  return (
    <>
      <div className="newMobileOrPhone">
        {
          isPhone
            ? intl.formatMessage({ id: 'new_mobile_number' })
            : intl.formatMessage({ id: 'new_email_address' })
        }
        :
      </div>
      {
        isPhone
          ? (
            <PhoneInputReact
              country="ir"
              enableSearch
              searchClass="codeSearch"
              containerClass="select"
              inputClass="input phoneInput"
              buttonClass="selectCountry"
              dropdownClass="dropDown"
              value={inputs?.mobile?.value}
              onChange={inputHandler}
            />
          )
          : (
            <Form.Control
              type="email"
              className="input"
              placeholder={intl.formatMessage({ id: 'enter_the_email' })}
              name="email"
              value={inputs?.email.value}
              onChange={inputHandler}
            />
          )
      }

      <div className="flexClass flex-end feedBtnSec">
        <button
          className="btn cancelBtn"
          onClick={(event) => {
            event.preventDefault();
            if (isPhone) {
              if (user.mobile) {
                setInputs({
                  ...inputs,
                  mobile: { value: user.mobile?.slice(2) },
                });
              } else {
                setInputs({
                  ...inputs,
                  mobile: { value: null },
                });
              }
            } else if (user.email) {
              setInputs({
                ...inputs,
                email: { value: user.email },
              });
            } else {
              setInputs({
                ...inputs,
                email: { value: null },
              });
            }
            setShow(false);
          }}
        >
          {intl.formatMessage({ id: 'cancel' })}
        </button>
        <button

          disabled={isPhone ? (inputs?.mobile?.value?.length < 12 || user?.mobile?.slice(2) === inputs?.mobile?.value)
            : (user?.email === inputs?.email?.value || !emailRegex.test(inputs?.email?.value))}
          className="btn sendCodeBtn"
          onClick={sendVerificationCode}
        >
          {intl.formatMessage({ id: 'send_verification_code' })}
        </button>
      </div>
    </>
  );
}
