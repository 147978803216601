import React, { useEffect, useState, Suspense  } from 'react';
import '../../../assets/styles/SportHomePage/HomePage.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FirstViewport from '../../../components/sport/home/FirstViewport';
import '../../../assets/styles/SportHomePage/HomePage.scss';
import { useIntl } from 'react-intl';
import api from '../../../api.js';
import {
  selectIsLogin, selectUserData,
  setCreditCode, setShowLogin,
} from '../../../redux/slices/accountSlice';
import NotCoinGiftSection from '../../../components/sport/home/NotCoinGiftSection';

// import WhatToDOInMomentSport from '../../../components/sport/general/WhatToDOInMomentSport.jsx';

const HomeNFTList = React.lazy(() => import('../../../components/sport/home/HomeNFTList'));
const InfoGraphic = React.lazy(() => import('../../../components/sport/home/InfoGraphic'));

const UtilitiesSlider = React.lazy(() => import('../../../components/sport/home/UtilitiesSlider'));


export default function HomePage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const intl = useIntl();

  const isLogin = useSelector(selectIsLogin);
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);

  // const [momentsData, setMomentsData] = useState(momentsJsonData?.moments);

  const [momentsData, setMomentsData] = useState({
    moments: [],
    pages: 0,
  });

  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchParams.get('referral-code')) {
      if (localStorage.getItem('referral-code')) {
        localStorage.setItem('referral-code', searchParams.get('referral-code'));
      } else {
        localStorage.setItem('referral-code', searchParams.get('referral-code'));
      }
    }
    if (searchParams.get('creditCode')) {
      dispatch(setCreditCode(searchParams.get('creditCode')));
      if (!isLogin) {
        dispatch(setShowLogin(true));
      } else {
        console.log(userData);
        if (userData?.username) {
          navigate(`users/${userData?.username}`);
        }
      }
    }

    const rubikaUt = searchParams.get('ut');

    if (rubikaUt) {
      localStorage.setItem('rubika-ut', rubikaUt);
      window.location.replace(import.meta.env.VITE_BASE_URL);
    }

    // if (rubikaUt) {
    //   api.post('/auth/rubika', {
    //     ut: rubikaUt,
    //   }).then((r) => {
    //     const {
    //       accessToken,
    //       username,
    //     } = r.data;

    //     localStorage.removeItem('temporaryAccessToken');
    //     localStorage.setItem('accessToken', accessToken);
    //     localStorage.setItem('username', username);
    //     api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    //     localStorage.setItem('loginType', 'rubika');
    //     dispatch(setLoginType('rubika'));

    //     dispatch(setIsLogin(true));

    //     dispatch(setLoadingSummary(true));

    //     api.get('/profile/summary', {
    //       headers: {
    //         'content-type': 'application/json',
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //       // eslint-disable-next-line no-shadow
    //     }).then(({ data }) => {
    //       dispatch(setUserData(data.user));
    //       dispatch(setLoadingSummary(false));
    //     }).catch(({ error }) => {
    //       dispatch(setLoadingSummary(true));
    //     });
    //   });
    // }

    // const ids = [1527, 1515, 1525];
    // const ids = [7237, 6548, 1071];

    const ids = [1515, 1527, 1525];

    setPageLoading(true);
    api.get('/moments/home', {
      params: {
        category: 11,
        ids: ids.join(','),
      },
    })
      .then((r) => {
        setMomentsData(r.data);
        setPageLoading(false);
      });
  }, []);

  return (
      <Suspense fallback={<div>درحال بارگزاری...</div>}>
      <div className="homePage">
      <div className="wrapper container">
        {/* <WhatToDOInMomentSport page="homepage" /> */}
        <FirstViewport />
      </div>

      <div className="container">
        <NotCoinGiftSection />
      </div>

      <div className="whyMomentWrapper">

        <div className="container">

          <div className="whySecsTitleWrapper">

            <div className="whySecsTitleSec">

              <div className="titleStartLineWhite" />

              <div className="whyMomentTitle">
                {intl.formatMessage({ id: 'why_moment?' })}
              </div>
              <div className="titleEndLineWhite" />
            </div>

          </div>

          <div className="whySportReasonsSec">

            <div className="reasonItem">
              <img src="/icons/sportCategory/worldCup.svg" alt="" />

              <div className="reasonTitle">
                {intl.formatMessage({ id: 'fan_dream_come_true' })}
              </div>

              <div className="reasonText">
                {intl.formatMessage({ id: 'landing_why_reasons_1' })}
              </div>
            </div>

            <div className="reasonItem">
              <img src="/icons/sportCategory/invetsment.svg" alt="" />

              <div className="reasonTitle">
                {intl.formatMessage({ id: 'investment' })}
              </div>

              <div className="reasonText">
                {intl.formatMessage({ id: 'landing_why_reasons_2' })}
              </div>
            </div>

            <div className="reasonItem">
              <img src="/icons/sportCategory/supportTeam.svg" alt="" />

              <div className="reasonTitle">
                {intl.formatMessage({ id: 'support_favorite_team' })}
              </div>

              <div className="reasonText">
                {intl.formatMessage({ id: 'landing_why_reasons_3' })}
              </div>
            </div>

          </div>

        </div>

      </div>

      <div className="wrapper container">

        <UtilitiesSlider />

      </div>

     <InfoGraphic/>

      <HomeNFTList pageLoading={pageLoading} momentsData={momentsData}/>

    </div>
      </Suspense>
  );
}
