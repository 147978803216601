import React from 'react';

export const ShowUserName = ({ user }) => {
  if (user?.firstName || user?.lastName) {
    if (user?.firstName && !user?.lastName) {
      return `${user?.firstName}`;
    }
    if (!user?.firstName && user?.lastName) {
      return `${user?.lastName}`;
    }
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    }
  }
  if (user?.username) {
    return user?.username;
  }
};
