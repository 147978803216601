import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {useLocation} from 'react-router';
import '../../../assets/styles/Explore/Explore.scss';
import Pagination from '../../../components/sport/explore/NFTList.jsx';
import api from '../../../api.js';
import {getWindowSize} from '../../../helpers/getWindowSize.jsx';
import Skeleton from '../../../components/sport/general/Skeleton.jsx';

// import WhatToDOInMomentSport from '../../../components/sport/general/WhatToDOInMomentSport.jsx';
import NFTList from '../../../components/sport/explore/NFTList.jsx';

const Sort = React.lazy(() => import('../../../components/sport/explore/Sort.jsx'));
const ExploreFilterDrawer = React.lazy(() => import('../../../components/sport/explore/ExploreFilterDrawer/ExploreFilterDrawer.jsx'));

export default function Explore() {
    const intl = useIntl();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const pageLimit = 18;

    const [sort, setSort] = useState([intl.formatMessage({id: 'newest'})]);

    const [currentCategory, setCurrentCategory] = useState({
        id: 1,
        name: 'Moment-empathy',
    });

    const [openFilterModal, setOpenFilterModal] = useState(false);

    const [pageLoading, setPageLoading] = useState(true);

    const [nftsLoading, setNftsLoading] = useState(true);

    const [momentsData, setMomentsData] = useState({
        moments: [],
        pages: 0,
    });
    const [itemOffset, setItemOffset] = useState(1);
    const [cleared, setCleared] = useState(true);

    const [filters, setFilters] = useState({
        sort: ['createdAt', 'DESC'],
        campaign: undefined,
        chain: undefined,
        category: undefined,
        page: searchParams?.get('page') !== null ? searchParams?.get('page') : 1,
        active: true,
        created: true,
        endingSoon: false,
        tags: [],
    });

    // const [filterData, setFilterData] = useState({
    //   filterData: {
    //
    //   },
    // });
    const [openFilters, setOpenFilters] = useState({
        status: false,
        price: false,
        priceSymbol: false,
        campaign: false,
        category: false,
        chain: false,
        sort: false,
    });

    const [selectedTags, setSelectedTags] = useState(searchParams?.get('tag') !== null ? [searchParams?.get('tag')] : []);
    const [selectedTagsWholeData, setSelectedTagsWholeData] = useState([]);

    // const [selectedTags, setSelectedTags] = useState([]);

    const [filterData, setFilterData] = useState({
        filterData: {},
    });

    const [filteredCategories, setFilteredCategories] = useState(filterData);

    const [filterLoading, setFilterLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        api.get('/moments/filter-data')
            .then(({data}) => {
                setFilterData(data);
                setFilteredCategories(data);
                setFilterLoading(false);
                // console.log(data.filterData);
                // console.log(searchParams?.get('tag'));
                // let f = data.filterData((item) => item.id === )

                if (searchParams?.get('tag') !== null) {
                    const allTags = Object.values(data.filterData).flat();
                    setSelectedTagsWholeData(allTags.filter((tag) => searchParams?.get('tag').includes(tag.id)));
                    // const selectedTagsWholeData = allTags.filter((tag) => searchParams?.get('tag').includes(tag.id));
                    const selectedNames = selectedTagsWholeData.map((tag) => tag.name);
                    // console.log('selectedTags name ', selectedNames);
                    // setSelectedTagsLabelToShow(selectedNames);
                }

                setPageLoading(false);
            });
    }, []);

    useEffect(() => {
        if (location.pathname.includes('family')) {
            setCurrentCategory({
                id: 2,
                name: 'Moment_Family',
            });
        } else {
            setCurrentCategory({
                id: 1,
                name: 'Moment-empathy',
            });
        }
    }, []);

    useEffect(() => {
        location.pathname.includes('family');
        api.get('/moments', {
            params: {
                campaign: filters.campaign?.join(','),
                chain: filters.chain?.join(','),
                // category: filters.category?.join(','),
                page: filters.page,
                sort: filters.sort?.join(','),
                active: filters.active,
                created: filters.created,
                endingSoon: filters.endingSoon,
                category: 11,
                limit: pageLimit,
                tag: selectedTags.join(',') || null,
            },
        })
            .then((r) => {
                setMomentsData(r.data);
                setNftsLoading(false);
                const newOffset = filters.page !== 1 ? (filters.page - 1) * pageLimit + 1 : 1;
                setItemOffset(newOffset);
            });
    }, [filters, selectedTags]);

    const updateURLParams = (tags) => {
        const params = new URLSearchParams(location.search);
        if (tags.length > 0) {
            params.set('tag', tags.join(','));
        } else {
            params.delete('tag');
        }
        navigate(`${location.pathname}?${decodeURIComponent(params.toString())}`);
    };

    const deleteTag = (tag) => {
        setNftsLoading(true); // Set loading state to true

        const newSelectedTags = selectedTags.filter((id) => id !== tag?.id);

        const newSelectedWholeData = selectedTagsWholeData.filter((theTag) => theTag.id !== tag?.id);

        setSelectedTagsWholeData(newSelectedWholeData);
        setSelectedTags(newSelectedTags);
        updateURLParams(newSelectedTags);
    };

    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const matches1 = (windowSize?.innerWidth <= 576);
    const matches2 = (windowSize?.innerWidth <= 969);
    return (
        <div className={`explorePage ${currentCategory?.id === 1 ? 'empathyTheme' : 'familyTheme'}`}>
            <head>
                <title>Explore Page</title>
                <meta name="description" content="it's explore"/>
            </head>
            {
                (matches1 || matches2) && !filterLoading
                && (
                    <ExploreFilterDrawer
                        open={openFilterModal}
                        setOpen={setOpenFilterModal}
                        setFilters={setFilters}
                        filters={filters}
                        cleared={cleared}
                        setSelectedTags={setSelectedTags}
                        selectedTags={selectedTags}
                        filteredCategories={filteredCategories}
                        categories={filterData}
                        filterLoading={filterLoading}
                        setFilteredCategories={setFilteredCategories}
                        selectedTagsWholeData={selectedTagsWholeData}
                        setSelectedTagsWholeData={setSelectedTagsWholeData}
                        setNftsLoading={setNftsLoading}
                        nftsLoading={nftsLoading}
                    />
                )
            }

            {/* <div className="container"> */}
            {/*  <WhatToDOInMomentSport page="explore" /> */}
            {/* </div> */}

            <div className="wrapper custom-container">

                <div className="nftSec">
                    <div className="partsTopSec">
                        <div className="hr"/>
                        <strong className="campaignText">
                            {intl.formatMessage({id: 'nfts'})}
                        </strong>
                    </div>
                    <div className="pageTitle">
                        <div>
                            {intl.formatMessage({id: 'explore_nfts'})}
                        </div>
                        {/* todo uncomment filter in mobile */}
                        {/* <div className="filterSecMob"> */}
                        {/*  { */}
                        {/*    pageLoading */}
                        {/*      ? ( */}
                        {/*        <> */}
                        {/*          <Skeleton style={{}} className="skeletonFilterBtn" /> */}
                        {/*          <Skeleton style={{}} className="filterMobEmpty" /> */}
                        {/*        </> */}
                        {/*      ) */}
                        {/*      : ( */}
                        {/*        <Sort */}
                        {/*          setFilters={setFilters} */}
                        {/*          sort={sort} */}
                        {/*          setSort={setSort} */}
                        {/*          openFilters={openFilters} */}
                        {/*          setOpenFilters={setOpenFilters} */}
                        {/*        /> */}
                        {/*      ) */}
                        {/*  } */}
                        {/* </div> */}

                    </div>

                    <div className="explorePageAbout">
                        {intl.formatMessage({id: 'explore_about'})}
                    </div>

                    {/*<div className="clubFilterRow">*/}

                    {/*  <div className="selectClubTitle">*/}
                    {/*    {intl.formatMessage({ id: 'select_club' })}*/}
                    {/*    :*/}
                    {/*  </div>*/}

                    {/*  /!* <Link to="/clubs/5">*/}
                    {/*    <div className="clubsCapsule" style={{ marginInlineEnd: 6 }}>*/}

                    {/*      <img src="/icons/search.svg" alt="" />*/}

                    {/*      <div>*/}
                    {/*        باشگاه فرهنگی ورزشی گل گهر*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  </Link> *!/*/}

                    {/*  /!* todo uncommet alu... arak *!/*/}

                    {/*  /!* <Link to="/clubs/6"> *!/*/}
                    {/*  /!*  <div className="clubsCapsule"> *!/*/}
                    {/*  /!*    <img src="/icons/search.svg" alt="" /> *!/*/}

                    {/*  /!*    <div> *!/*/}
                    {/*  /!*      باشگاه فوتبال آلومینیوم اراک *!/*/}
                    {/*  /!*    </div> *!/*/}
                    {/*  /!*  </div> *!/*/}
                    {/*  /!* </Link> *!/*/}

                    {/*</div>*/}

                    <div className="mobileFilterButtonSec">
                        <button
                            onClick={() => {
                                setOpenFilterModal(true);
                            }}
                            style={{position: 'relative'}}
                        >
                            <img src="/icons/description.svg" alt=""/>
                            فیلترها

                        </button>
                    </div>

                    {
                        selectedTagsWholeData?.length > 0
                        && (
                            <div className="showFilter">

                                <div>
                                    {intl.formatMessage({id: 'filters'})}
                                    :
                                </div>

                                {
                                    selectedTagsWholeData.map((tag) => (
                                        <div className="selectedTagsLabel" onClick={() => deleteTag(tag)}>
                                            <img src="/icons/whiteClose.svg" alt=""/>
                                            {tag?.name}
                                        </div>
                                    ))
                                }

                            </div>
                        )

                    }

                    {/* todo uncoment sort */}
                    {/* <div className="filterSortRow"> */}
                    {/*  <div className="sortSec"> */}
                    {/*    { */}
                    {/*      pageLoading */}
                    {/*        ? ( */}
                    {/*          <> */}
                    {/*            <Skeleton style={{ height: 14, width: 38 }} className="" /> */}
                    {/*            <Skeleton style={{}} className="skeletonSortBtn" /> */}
                    {/*          </> */}
                    {/*        ) */}
                    {/*        : ( */}
                    {/*          <> */}
                    {/*            <span> */}
                    {/*              {intl.formatMessage({ id: 'sortBy' })} */}
                    {/*            </span> */}
                    {/*            <Sort */}
                    {/*              setFilters={setFilters} */}
                    {/*              sort={sort} */}
                    {/*              setSort={setSort} */}
                    {/*              openFilters={openFilters} */}
                    {/*              setOpenFilters={setOpenFilters} */}
                    {/*            /> */}
                    {/*          </> */}
                    {/*        ) */}
                    {/*    } */}
                    {/*  </div> */}
                    {/* </div> */}

                    <NFTList
                        momentsData={momentsData}
                        setFilters={setFilters}
                        itemOffset={itemOffset}
                        pageLoading={pageLoading}
                        setPageLoading={setPageLoading}
                        filters={filters}
                        setOpenFilterModal={setOpenFilterModal}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        filteredCategories={filteredCategories}
                        categories={filterData}
                        setFilteredCategories={setFilteredCategories}
                        selectedTagsWholeData={selectedTagsWholeData}
                        setSelectedTagsWholeData={setSelectedTagsWholeData}
                        setNftsLoading={setNftsLoading}
                        nftsLoading={nftsLoading}
                    />

                </div>
            </div>
        </div>
    );
}
