import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import ProfilePicture from '../../other/ProfilePicture.jsx';
import { ShowUserName } from '../ShowUserName.jsx';
import { selectLanguage } from '../../../../redux/slices/languageSlice';

export default function PendingCard({ moment }) {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);
  const location = useLocation();

  return (
    <div className="nftCard momentCard" key={moment.id}>
      {
                moment?.image
                  ? (
                    <div
                      className="failedImageWrapper"
                    >
                      <div
                        className="normalNftImage"
                        style={{ backgroundImage: `url(${moment?.image ? moment?.image : moment?.campaign?.image})` }}
                      />

                      <div className="pendingFilter" />
                      <div className="errorIconSec">
                        <img src="/icons/hourglass.svg" alt="" />
                      </div>
                    </div>
                  )
                  : (
                    <div className="preSaleImageWrapper">
                      <div
                        className="blurMomentImage"
                        style={{ backgroundImage: `url(${moment?.campaign?.image})` }}
                      />

                      <div className="grayFilter" />

                      <div className="nftInfo">
                        <strong className="nftName">
                          {languageRedux === 'fa' ? ToPersianNumber(moment.title) : moment.title}
                        </strong>
                        <div className="iconSec">
                          {moment.campaign
                                        && <img src="/icons/profileCampaign.svg" alt="" className="campaignSvg" />}
                          <img src={moment.chain.logo} alt="" className="chainImage" />
                        </div>
                      </div>

                      <div className="preSaleNumber">
                        #
                        {languageRedux === 'fa' ? ToPersianNumber(String(moment.posInCampaign)) : moment.posInCampaign}
                      </div>
                    </div>
                  )

            }

      <div className="cardMomentTitle">
        <img src="/icons/sportCategory/frame.svg" alt="" />
        {languageRedux === 'fa' ? ToPersianNumber(moment.title) : moment.title}
      </div>

      <div className="flexClass align-items-center justify-content-between ownerParent">
        {
                    !moment.owner && moment?.momentEditions?.length === 0
                    // doesnt have owner
                      ? (
                        <div className="ownerSec">
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="pendingMomentCharitiesDropDown1"
                                className="ownerIdMenuToggle"
                              >
                                <div className="ownerId flexClass align-items-center">
                                  <div
                                    className="charityImage"
                                    style={{ backgroundImage: 'url(/icons/charityIcon.svg)' }}
                                  />
                                  {
                                                    moment.campaign?.charities?.length > 1
                                                    // change this
                                                      ? `${moment.campaign?.charities.length} ${intl.formatMessage({ id: 'charities' })}`
                                                      : moment.campaign?.charities && moment.campaign?.charities[0]?.name

                                                }
                                </div>
                              </Dropdown.Toggle>

                              {
                                            moment.campaign?.charities?.length > 1
                                            // change this
                                            && (
                                            <Dropdown.Menu align="end">
                                              {
                                                        moment?.campaign?.charities.map((charity) => (
                                                          <div key={charity?.id}>
                                                            <Dropdown.Item>
                                                              <div className="charityItem">
                                                                <div
                                                                  className="charityImage"
                                                                  style={{ backgroundImage: 'url(/icons/charityIcon.svg)' }}
                                                                />
                                                                <div>
                                                                  {charity?.name}
                                                                </div>
                                                              </div>
                                                            </Dropdown.Item>
                                                          </div>
                                                        ))
                                                    }
                                            </Dropdown.Menu>
                                            )
                                        }
                            </Dropdown>
                          </div>
                        </div>
                      )
                      : (
                        <div className="ownerSec">
                          {
                                    moment?.momentEditions?.length > 0
                                      ? (
                                        <div className="multipleOwnerParent">
                                          <div className="symbol-group symbol-hover flex-nowrap">
                                            {moment?.momentEditions.map((edition, idx) => {
                                              if (moment?.momentEditions?.length >= 4) {
                                                if (idx < 4) {
                                                  return (
                                                  // todo link to where?
                                                    <div
                                                      className="symbol symbol-35px symbol-circle"
                                                      data-bs-toggle="tooltip"
                                                      key={edition?.id}
                                                    >
                                                      <div className="multipleOwnerImg">
                                                        {
                                                                                edition.owner.image
                                                                                  ? <img src={edition.owner.image} alt="" />
                                                                                  : (
                                                                                    <ProfilePicture
                                                                                      colors={edition.owner.profileColors}
                                                                                    />
                                                                                  )
                                                                            }
                                                      </div>
                                                    </div>

                                                  );
                                                }
                                              }
                                              if (moment?.momentEditions?.length < 4) {
                                                return (
                                                  <div
                                                    className="symbol symbol-35px symbol-circle"
                                                    data-bs-toggle="tooltip"
                                                  >
                                                    <div className="multipleOwnerImg">
                                                      {
                                                                            edition.owner.image
                                                                              ? <img src={edition.owner.image} alt="" />
                                                                              : (
                                                                                <ProfilePicture
                                                                                  colors={edition.owner.profileColors}
                                                                                />
                                                                              )
                                                                        }
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      )
                                      : moment.owner
                                        ? moment?.owner?.image
                                          ? (
                                            <Link to={`/users/${moment?.owner?.username}`}>
                                              <div
                                                className="ownerProfile"
                                                style={{ backgroundImage: `url(${moment.owner?.image})` }}
                                              />
                                            </Link>
                                          )
                                          : (
                                            <Link to={`/users/${moment?.owner?.username}`}>
                                              <div>
                                                <ProfilePicture
                                                  size={25}
                                                  colors={moment.owner.profileColors}
                                                />
                                              </div>
                                            </Link>
                                          )
                                        : (
                                          <div
                                            className="charityImage"
                                            style={{ backgroundImage: 'url(/icons/charityIcon.svg)' }}
                                          />
                                        )

                                }
                          {
                                    moment?.momentEditions?.length > 0
                                      ? (
                                        <div className="ownerId">

                                          {`${intl.formatMessage({ id: 'has_several' })} ${moment?.momentEditions?.length} ${intl.formatMessage({ id: 'version' })} `}

                                          {/* { */}
                                          {/*          languageRedux === 'fa' */}
                                          {/*            ? `(${ToPersianNumber(moment?.momentEditions?.length)}/${parseInt(moment?.campaign?.momentEditionsFraction, 10) === 0 ? ToPersianNumber(moment?.momentEditions?.length) : ToPersianNumber(parseInt(moment?.campaign?.momentEditionsFraction, 10))})` */}
                                          {/*            : `(${moment?.momentEditions?.length}/${parseInt(moment?.campaign?.momentEditionsFraction, 10) === 0 ? moment?.momentEditions?.length : parseInt(moment?.campaign?.momentEditionsFraction, 10)})` */}
                                          {/*      } */}
                                        </div>
                                      )

                                      : moment.owner
                                        ? (
                                          <Link to={`/users/${moment?.owner?.username}`}>
                                            <div
                                              className={`ownerId ${languageRedux === 'fa' ? 'ltrDirection' : 'rtlDirection'}`}
                                            >
                                              <ShowUserName user={moment.owner} />
                                            </div>
                                          </Link>
                                        )
                                        : (
                                          <div>
                                            <Dropdown>
                                              <Dropdown.Toggle id="pendingMomentCharitiesDropDown2" className="ownerIdMenuToggle">
                                                <div className="ownerId">
                                                  {
                                                                    moment.campaign?.charities.length > 1
                                                                    // change this
                                                                      ? `${moment.campaign?.charities.length} ${intl.formatMessage({ id: 'charities' })}`
                                                                      : moment.campaign?.charities[0]?.name
                                                                }
                                                </div>
                                              </Dropdown.Toggle>

                                              {
                                                            moment.campaign?.charities.length === 1
                                                            // change this
                                                            && (
                                                            <Dropdown.Menu>
                                                              {
                                                                        moment?.campaign?.charities.map((charity) => (
                                                                          <Dropdown.Item>
                                                                            <div className="charityItem">
                                                                              <div
                                                                                className="charityImage"
                                                                                style={{ backgroundImage: 'url(/icons/charityIcon.svg)' }}
                                                                              />
                                                                              <div>
                                                                                {charity?.name}
                                                                              </div>
                                                                            </div>
                                                                          </Dropdown.Item>
                                                                        ))
                                                                    }
                                                            </Dropdown.Menu>
                                                            )
                                                        }

                                            </Dropdown>
                                          </div>
                                        )
                                }
                        </div>
                      )
                }

      </div>
      <div className="mintingProcessing flexClass justify-content-between align-items-center">
        <div>
          {intl.formatMessage({ id: 'NFT_is_minting' })}
          ...
        </div>
      </div>

    </div>
  );
}
