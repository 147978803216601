import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
  selectIsLogin, selectLoadingSummary, selectUserData, setShowLogin,
} from '../../../../redux/slices/accountSlice';

import '../../../../assets/styles/layout/Header/Header.scss';
import { getWindowSize } from '../../../../helpers/getWindowSize';
import { setShowCreatedModalAfterLogin, setShowCreateModal } from '../../../../redux/slices/createNFTSlice';

import api from '../../../../api.js';
import getLocalStorage from '../../../../helpers/getLocalStorage.js';

const Skeleton = React.lazy(() => import('../../general/Skeleton'));
const HeaderPopover = React.lazy(() => import('./HeaderPopover.jsx'));
const Index = React.lazy(() => import('../../login/index'));

export default function HeaderContent({
  headerProps,
  momentTypeProps,
}) {
  const isLogin = useSelector(selectIsLogin);
  const intl = useIntl();
  const loading = useSelector(selectLoadingSummary);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const userData = useSelector(selectUserData);

  const { pathname } = useLocation();

  const location = useLocation();
  const storage = getLocalStorage();

  const navigate = useNavigate();

  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [hasNotificationLoading, setHasNotificationLoading] = useState(true);

  const [showNotification, setShowNotification] = useState(false);

  const [notifications, setNotifications] = useState(true);

  const [isShowAll, setIsShowAll] = useState(false);

  const [hasNotif, setHasNotif] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  const matches2 = (windowSize?.innerWidth <= 768);

  // const createNftBtnAction = () => {
  //   if (window.localStorage.getItem('accessToken')) {
  //     dispatch(setShowCreateModal(true));
  //   } else {
  //     dispatch(setShowLogin(true));
  //     dispatch(setShowCreatedModalAfterLogin(true));
  //   }
  // };

  const checkHasNotif = async () => {
    const accessToken = await storage.getItem('accessToken');
    if (accessToken) {
      setHasNotificationLoading(true);
      api.get('/profile/has-notif').then(({ data }) => {
        setHasNotificationLoading(false);
        setHasNotif(data?.hasNotif);
      }).catch(({ response }) => {
        setHasNotificationLoading(false);
      });
    } else {
      setHasNotificationLoading(false);
    }
  };

  useEffect(() => {
    checkHasNotif();
  }, [isLogin]);

  const getNotifications = useCallback(async () => {
    const accessToken = await storage.getItem('accessToken');
    if (showNotification && accessToken) {
      setIsNotificationLoading(true);
      api.get(`/profile/notifications?showAll=${isShowAll ? 'true' : 'false'}`).then(({ data }) => {
        setNotifications(data?.notifications);
        setIsNotificationLoading(false);
      }).catch(({ response }) => {
        setIsNotificationLoading(false);
      });
    }
  }, [showNotification, isShowAll]);

  useEffect(() => {
    if (showNotification) {
      getNotifications();
    }
  }, [showNotification, isShowAll]);

  const notifPopover = (
    <Popover id="popover-basic" className="notifPopover">
      <Popover.Body>
        <div className="notificationListHeader">
          <img
            src="/icons/whiteClose.svg"
            alt=""
            onClick={() => {
              setShowNotification(false);
            }}
          />
        </div>
        <div style={{ width: '100%' }}>
          {
                        isNotificationLoading
                          ? (
                            <>
                              <Skeleton className="notifItem" style={{ marginBottom: 20, height: 60 }} />
                              <Skeleton className="lastNotifItem" style={{ height: 60 }} />
                            </>
                          ) : (
                            <>
                              {
                                        notifications?.length > 0
                                          ? notifications?.map((notif, idx) => {
                                            const lastItemIndex = notifications.lastIndexOf(notifications[notifications?.length - 1]);
                                            const status = notif?.status;

                                            const statusClassNames = {
                                              neutral: 'neutralNotifColor',
                                              success: 'successNotifColor',
                                              error: 'errorNotifColor',
                                              warning: 'warningNotifColor',
                                            };

                                            const statusIcons = {
                                              neutral: '/icons/notification.svg',
                                              success: '/icons/tickSucces.svg',
                                              error: '/icons/failNotif.svg',
                                              warning: '/icons/warningNotif.svg',
                                            };
                                            const className = `${statusClassNames[status] || ''}`;
                                            const icon = `${statusIcons[status] || ''}`;

                                            if (lastItemIndex === idx) {
                                              return (
                                                <div
                                                  className={`lastNotifItem ${className} ${!notif?.isRead && 'unReadBackgroundColor'}`}
                                                  key={idx}
                                                  onClick={() => {
                                                    if (notif?.redirectLink) {
                                                      setShowNotification(false);
                                                      navigate(notif.redirectLink);
                                                    }
                                                  }}
                                                  style={notif?.redirectLink && { cursor: 'pointer' }}
                                                >
                                                  <img src={icon} alt="" className="notificationIcon" />
                                                  <div className={notif?.isRead ? 'readNotif' : 'unreadNotif'}>
                                                    {notif?.message}
                                                  </div>
                                                  {/* { */}
                                                  {/*              !notif?.isRead */}
                                                  {/*              && <div className="unReadBingili" /> */}
                                                  {/*          } */}
                                                </div>
                                              );
                                            }

                                            return (
                                              <div
                                                className={`notifItem ${className} ${!notif?.isRead && 'unReadBackgroundColor'}`}
                                                key={idx}
                                                onClick={() => {
                                                  if (notif?.redirectLink) {
                                                    setShowNotification(false);
                                                    navigate(notif.redirectLink);
                                                  }
                                                }}
                                                style={notif?.redirectLink && { cursor: 'pointer' }}
                                              >
                                                <img src={icon} alt="" className="notificationIcon" />
                                                <div className={notif?.isRead ? 'readNotif' : 'unreadNotif'}>
                                                  {notif?.message}
                                                </div>
                                                {/* { */}
                                                {/*            !notif?.isRead */}
                                                {/*            && <div className="unReadBingili" /> */}
                                                {/*        } */}
                                              </div>
                                            );
                                          })
                                          : (
                                            <div className="lastNotifItem">
                                              {intl.formatMessage({ id: 'no-notif-to-show' })}
                                            </div>
                                          )
                                    }
                            </>
                          )

                    }

          {
                        notifications?.length > 10
                        && (
                        <div className="showAllNotifSec">
                          <button
                            type="button"
                            onClick={() => {
                              setIsShowAll(!isShowAll);
                            }}
                          >
                            {intl.formatMessage({ id: `${isShowAll ? 'view_less' : 'view_more'}` })}
                          </button>
                        </div>
                        )
                    }
        </div>

      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {
                !isLogin
                && <Index momentTypeProps={momentTypeProps} />
            }

      {
                 matches2
                 && (
                 <div className="mobileHamburgerMenu">
                   <HeaderPopover />
                 </div>
                 )
       }
      <div className="headerLogoWrapper">
        <div className="searchSec">
          <Link
            to={momentTypeProps?.type?.id === 0 ? '/' : '/family'}
            aria-label="link to homePage"
          >
            {/* <img */}
            {/*  id="headerLogo" */}
            {/*  src={headerProps?.type?.id === 0 ? '/logos/momentEmpathy.svg' : '/logos/momentFamily.svg'} */}
            {/*  alt="" */}
            {/*  className="headerLogo" */}
            {/* /> */}

            {/* { */}
            {/*                matches2 */}
            {/*                  ? ( */}
            {/*                    <div */}
            {/*                      id="headerLogo" */}
            {/*                      style={{ backgroundImage: `url(${headerProps?.type?.id === 0 ? '/logos/momentEmpathy-justLogo.png' : '/logos/family-justLogo.png'})` }} */}
            {/*                      className="headerLogo" */}
            {/*                    /> */}
            {/*                  ) */}
            {/*                  : ( */}
            {/*                    <div */}
            {/*                      id="headerLogo" */}
            {/*                      style={{ backgroundImage: `url(${headerProps?.type?.id === 0 ? '/logos/momentEmpathy.svg' : '/logos/momentFamily.svg'})` }} */}
            {/*                      className="headerLogo" */}
            {/*                    /> */}
            {/*                  ) */}
            {/*     } */}

            <div
              id="headerLogo"
              style={{ backgroundImage: `url(${pathname.includes('/waitlist') ? '/logos/momentDarkMode.png' : '/logos/sport/headerLogo.png'})` }}
              className="headerLogo"
            />

          </Link>

        </div>
        <div className="navSec">
          {
                        headerProps?.links?.map((navItem) => {
                          if (navItem?.title === intl.formatMessage({ id: 'mag' })) {
                            return (
                              <a href="https://mag.mymoment.app" target="_blank" rel="noreferrer">
                                <div className="navItem" id={navItem.name} key={navItem?.id}>
                                  {intl.formatMessage({ id: 'Moment' })}
                                  {' '}
                                  <span style={{ color: '#B35D7F' }}>
                                    {intl.formatMessage({ id: 'mag_mobile' })}
                                  </span>
                                </div>
                              </a>
                            );
                          }

                          return (
                            <Link to={navItem?.url} key={navItem?.id}>
                              {
                                navItem?.id === 1 ?
                                    <div
                                        className={`navItem ${location.pathname === (navItem?.url) ? headerProps?.type?.id === 0 ? 'empathyHeaderItemUnderline' : 'familyHeaderItemUnderline' : ''}`}
                                        id={navItem.name}
                                    >
                                      {navItem?.title}
                                    </div>
                                    :
                                    <div
                                        className={`navItem ${location.pathname?.includes(navItem?.url) ? headerProps?.type?.id === 0 ? 'empathyHeaderItemUnderline' : 'familyHeaderItemUnderline' : ''}`}
                                        id={navItem.name}
                                    >
                                      {navItem?.title}
                                    </div>
                              }
                            </Link>
                          );
                        })
                    }

          {/* <div className={`etratNavItemWrapper ${location.pathname?.includes('/collab') ? headerProps?.type?.id === 0 ? 'empathyHeaderItemUnderline' : 'familyHeaderItemUnderline' : ''}`}> */}
          {/*  <Link to="/collab"> */}
          {/*    <div className="navItem"> */}
          {/*      {intl.formatMessage({ id: 'cooperation_request' })} */}
          {/*    </div> */}
          {/*  </Link> */}
          {/* </div> */}

        </div>

      </div>

      <div className="notifAndUserSec">
        {
                    hasNotificationLoading
                      ? (
                        <Skeleton
                          className="empathyHeaderNotifIconsSec"
                          style={{ width: 35, height: 35, marginInlineEnd: 16 }}
                        />
                      )
                      : isLogin
                        && (
                        <OverlayTrigger
                          trigger={['click']}
                          placement="bottom"
                          overlay={notifPopover}
                          onHide={() => setShowNotification(false)}
                          rootClose
                          show={showNotification}
                        >
                          <div
                            onClick={() => {
                              setShowNotification(!showNotification);
                            }}
                            className={hasNotif ? 'activeHeaderNotifIconsSec' : 'headerNotifIconsSec'}
                          >
                            <img
                              src={pathname?.includes('/waitlist') ? '/icons/sportCategory/darkNotification.svg' : '/icons/notification.svg'}
                              alt=""
                            />
                            {
                                        hasNotif
                                        && <span className="hasNotifCircle" />
                                    }
                          </div>
                        </OverlayTrigger>
                        )

                }
        {
                    loading
                      ? (
                        <Skeleton
                          style={{ height: 24, width: 24 }}
                        />
                      )
                      : (
                        <div className="menuSec">
                          {
                                    !isLogin
                                      ? (
                                        <button
                                          id="kt_drawer_login_toggle"
                                          onClick={() => {
                                            dispatch(setShowLogin(true));
                                          }}
                                          className={`headerLoginBtn ${headerProps?.type?.id === 0 ? 'momentBackColor' : 'momentFamilyBackColor'}`}
                                          type="submit"
                                        >
                                          {intl.formatMessage({ id: 'signUp' })}
                                        </button>
                                      )
                                      : matches2
                                        ? (
                                          <Link to="/profile">
                                            <div
                                              style={{ backgroundImage: `url(${pathname?.includes('waitlist') ? '/icons/sportCategory/darkHeaderAvatar.svg' : '/icons/headerAvatar.svg'})` }}
                                              className="avatarImg"
                                            />
                                          </Link>
                                        )
                                        : <HeaderPopover />

                                }
                        </div>
                      )

                }
      </div>
    </>
  );
}
