import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import api from '../../../../api';
import Transaction from './Transaction';
import Skeleton from '../../general/Skeleton';
import ToPersianNumber from '../../../../helpers/toPersianNumber';
import Pagination from '../Pagination';
import { selectLanguage } from '../../../../redux/slices/languageSlice';

export default function TransactionTab({
  sort, tabContentLoading, setTabContentLoading, userDatas,
}) {
  const location = useLocation();
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);

  const [page, setPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [endOffset, setEndOffset] = useState(itemOffset + transactions?.moments?.length - 1);

  useEffect(() => {
    setTabContentLoading(true);
    api.get('/profile/payments', {
      params: {
        page,
        sort,
        // sort: filters.sort?.join(','),
      },
    }).then(({ data }) => {
      setTabContentLoading(false);
      const newOffset = page !== 1 ? (page - 1) * 15 + 1 : 1;
      setItemOffset(newOffset);
      setTransactions(data);
      setEndOffset(newOffset + data.payments.length - 1);
    }).catch(({ response }) => {
    });
  }, [page, sort, location]);
  const handlePageClick = (event) => {
    window.scrollTo(0, 0);
    setPage(event.selected + 1);
  };
  if (tabContentLoading) {
    return (
      <div className="giftTableWrapper">
        <table className="giftTable">
          <thead>
            <tr className="transactionTableHead">
              <th className="hashtagTh">#</th>
              <th>{intl.formatMessage({ id: 'count' })}</th>
              <th>{intl.formatMessage({ id: 'price' })}</th>
              <th>{intl.formatMessage({ id: 'status' })}</th>
              <th className="referenceTh">{intl.formatMessage({ id: 'reference_code' })}</th>
              <th>
                <div className="gatewayTh">
                  {intl.formatMessage({ id: 'gateway' })}
                </div>
              </th>
              <th>
                <div className="flexClass align-items-center">
                  <div>
                    {' '}
                    {intl.formatMessage({ id: 'date' })}
                  </div>
                  <img src="/icons/giftArrowDown.svg" alt="" />
                </div>
              </th>
              <th className="receiptTh">{intl.formatMessage({ id: 'receipt' })}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Skeleton className="hashtagTd" style={{ width: 90, height: 17, borderRadius: 5 }} />
              </td>
              <td>
                <Skeleton className="hashtagTd" style={{ width: 217, height: 17, borderRadius: 5 }} />
              </td>
              <td className="secondTd">
                <Skeleton className="hashtagTd" style={{ width: 110, height: 17, borderRadius: 5 }} />
              </td>

              <td className="secondTd">
                <Skeleton className="hashtagTd" style={{ width: 130, height: 17, borderRadius: 5 }} />
              </td>

              <td className="forthTd">
                <Skeleton className="hashtagTd" style={{ width: 100, height: 17, borderRadius: 5 }} />
              </td>

              <td className="forthTd">
                <Skeleton className="hashtagTd" style={{ width: 169, height: 17, borderRadius: 5 }} />
              </td>

              <td className="forthTd">
                <Skeleton className="hashtagTd" style={{ width: 106, height: 17, borderRadius: 5 }} />
              </td>

              <td className="fifthTd">
                <Skeleton className="hashtagTd" style={{ width: 80, height: 17, borderRadius: 5 }} />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton className="hashtagTd" style={{ width: 90, height: 17, borderRadius: 5 }} />
              </td>
              <td>
                <Skeleton className="hashtagTd" style={{ width: 217, height: 17, borderRadius: 5 }} />
              </td>
              <td className="secondTd">
                <Skeleton className="hashtagTd" style={{ width: 110, height: 17, borderRadius: 5 }} />
              </td>

              <td className="secondTd">
                <Skeleton className="hashtagTd" style={{ width: 130, height: 17, borderRadius: 5 }} />
              </td>

              <td className="forthTd">
                <Skeleton className="hashtagTd" style={{ width: 100, height: 17, borderRadius: 5 }} />
              </td>

              <td className="forthTd">
                <Skeleton className="hashtagTd" style={{ width: 169, height: 17, borderRadius: 5 }} />
              </td>

              <td className="forthTd">
                <Skeleton className="hashtagTd" style={{ width: 106, height: 17, borderRadius: 5 }} />
              </td>

              <td className="fifthTd">
                <Skeleton className="hashtagTd" style={{ width: 80, height: 17, borderRadius: 5 }} />
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    );
  }
  if (transactions?.payments?.length === 0) {
    return (
      <div className="favoriteEmptySec">
        <div className="emptyCreatedSec">
          <div className="haveNotCreatedYet">
            {intl.formatMessage({ id: 'no_nft_to_show' })}
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <Transaction transactions={transactions} />
      {
                tabContentLoading
                  ? (
                    <div className="paginationSec">
                      <Skeleton style={{ height: 16, width: 160 }} className="showTitle" />
                      <Skeleton style={{ height: 28, width: 160 }} className="explorePagination" />
                    </div>
                  )
                  : transactions?.pages > 1
                    && (
                    <div className="paginationSec">
                      <div className="showTitle">
                        {`${intl.formatMessage({ id: 'showing' })} 
                             ${languageRedux === 'fa' ? ToPersianNumber(itemOffset) : itemOffset} 
                              ${intl.formatMessage({ id: 'to' })} 
                              ${languageRedux === 'fa' ? ToPersianNumber(endOffset) : endOffset} 
                               ${intl.formatMessage({ id: 'of' })} 
                               ${languageRedux === 'fa' ? ToPersianNumber(transactions.paymentsCount) : transactions.paymentsCount} 
                                ${intl.formatMessage({ id: 'entries' })}`}
                      </div>
                      <Pagination
                        handlePageClick={handlePageClick}
                        data={transactions}
                        page={page}
                      />
                    </div>
                    )
            }
    </>
  );
}
