import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import '../../../../assets/styles/Transfer/Transfer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ethers } from 'ethers';
import { selectLanguage } from '../../../../redux/slices/languageSlice';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import api from '../../../../api.js';
import addCommas from '../../../../helpers/addCommas.jsx';
import ToEnglishNumber from '../../../../helpers/toEnglishNumber.jsx';
import removeSeparator from '../../../../helpers/removeSeparator.jsx';
import { setShowToast, setToastMessage, setToastStatus } from '../../../../redux/slices/toastSlice';

export default function FixPriceModal({
  show,
  setShow,
  selectedMoment,
  setIsMomentStatusChanged,
  isTransferTokenLoading,
  setIsTransferTokenLoading,
  selectedEdition, setSelectedEdition,

}) {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  // useEffect(() => {
  //   console.log(selectedMoment?.listings[selectedMoment?.listings?.length - 1]?.amount);
  //
  // }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(selectedMoment?.listings?.length > 0 ? selectedMoment?.listings[selectedMoment?.listings?.length - 1]?.amount : 0);

  // const [selectedEdition, setSelectedEdition] = useState(selectedMoment?.momentEditions?.find((item) => item?.isOwner === true));
  const myEditionsList = selectedMoment?.momentEditions?.filter((item) => item?.isOwner === true);
  const handleClose = () => {
    setShow(false);
  };

  const inputHandler = (event) => {
    const value = ToEnglishNumber(removeSeparator(event.target.value));
    if (!(isNaN(value))) {
      setPrice(value);
    }
  };

  const POLYGON_MAINNET_PARAMS = {
    chainId: '0x89', // 137 in hexadecimal
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  };

  const switchToPolygon = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: POLYGON_MAINNET_PARAMS.chainId }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [POLYGON_MAINNET_PARAMS],
          });
        } catch (addError) {
          console.error('Failed to add the network', addError);
          throw new Error('Failed to add the Polygon network');
        }
      } else {
        console.error('Failed to switch the network', switchError);
        throw new Error('Failed to switch to the Polygon network');
      }
    }
  };

  const createFixPrice = async () => {
    setIsLoading(true);
    // console.log(selectedMoment);
    // dispatch(setShowToast(true));
    // dispatch(setToastStatus(1));
    // dispatch(setToastMessage('sM', selectedMoment?.id));

    if (selectedMoment?.type === 'single') {
      api.post(`/profile/moments/${selectedMoment?.id}/listings/create`, {
        amount: parseInt(price, 10),
      }).then(async (r) => {
        setIsLoading(false);
        setShow(false);

        // 202 status means it is NFT
        // 200 means moment
        if (r.status === 202) {
          setIsTransferTokenLoading(true);

          // Switch to Polygon network
          try {
            await switchToPolygon();
          } catch (error) {
            setIsLoading(false);
            console.error(error);
            // Handle error (e.g., show a message to the user)
            return;
          }

          const { nft } = r.data;
          const { data } = await api.get('/mint/get-abi');
          const { contract: nftContract, walletAddress } = data;
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          const tokenContract = new ethers.Contract(
            nftContract.address.address,
            nftContract.abi.abi,
            signer,
          );

          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

          const transaction = await tokenContract['safeTransferFrom(address,address,uint256)'](
            accounts[0],
            walletAddress,
            nft.tokenId,
          );

          await transaction.wait();
          await api.post(`/profile/moments/${selectedMoment?.id}/listings/verify`, {
            txHash: transaction.hash,
            fromAddress: accounts[0],
          });
          setIsTransferTokenLoading(false);
        }
        setIsMomentStatusChanged((prevState) => !prevState);
      }).catch((e) => {
        setIsLoading(false);
        setIsTransferTokenLoading(false);
        console.log('listing create error ', e);
        const errorValues = Object?.values(e);
        if (errorValues[3] && errorValues[3]?.includes('caller is not token owner or approved')) {
          dispatch(setToastStatus(0));
          dispatch(setShowToast(true));
          dispatch(setToastMessage(intl.formatMessage({ id: 'not_the_owner_nft' })));
        }
      });
    }
    if (selectedMoment?.type === 'edition') {
      api.post(`/profile/moment-editions/${selectedEdition?.id}/listings/create`, {
        amount: parseInt(price, 10),
      }).then(async (r) => {
        setIsLoading(false);
        setShow(false);

        // 202 status means it is NFT
        // 200 means moment
        if (r.status === 202) {
          setIsTransferTokenLoading(true);

          const { nft } = r.data;
          const { data } = await api.get('/mint/get-abi');
          const { contract: nftContract, walletAddress } = data;
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          const tokenContract = new ethers.Contract(
            nftContract.address.address,
            nftContract.abi.abi,
            signer,
          );

          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

          const transaction = await tokenContract['safeTransferFrom(address,address,uint256)'](
            accounts[0],
            walletAddress,
            nft.tokenId,
          );

          await transaction.wait();
          await api.post(`/profile/moment-editions/${selectedEdition?.id}/listings/verify`, {
            txHash: transaction.hash,
            fromAddress: accounts[0],
          });
          setIsTransferTokenLoading(false);
        }
        setIsMomentStatusChanged((prevState) => !prevState);
      }).catch((e) => {
        setIsLoading(false);
        setIsTransferTokenLoading(false);
        console.log('listing create error edition ', e);
        const errorValues = Object?.values(e);
        if (errorValues[3] && errorValues[3]?.includes('caller is not token owner or approved')) {
          dispatch(setToastStatus(0));
          dispatch(setShowToast(true));
          dispatch(setToastMessage(intl.formatMessage({ id: 'not_the_owner_nft' })));
        }
      });
    }
  };

  const editionHandler = (event) => {
    const edition = selectedMoment?.momentEditions.find((item) => item.id === parseInt(event.target.value, 10));
    setSelectedEdition(edition);
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="fixPriceModal modal-dialog-centered"
      show={show}
      backdrop
    >
      <div className="modalHeader">
        <img
          src="/icons/close.svg"
          alt=""
          onClick={handleClose}
          className="close"
        />
      </div>

      <div className="fixPriceImageSec">
        <div
          className="fixPriceImage"
          style={{ backgroundImage: `url(${selectedMoment?.image ? selectedMoment.image : selectedMoment?.campaign?.image})` }}
        />

        <div>

          {
                        selectedMoment?.type === 'edition'
                          ? (pathname?.includes('users/') && !isLoading)
                          // user can select the edition she/he wants
                            ? (
                              <select
                                className="form-select fixPriceEditionSelect"
                                data-control="select2"
                                onChange={editionHandler}
                                value={selectedEdition?.id}
                              >
                                {
                                            myEditionsList?.map((edition) => (
                                              <option key={edition?.id} value={edition?.id}>
                                                {intl.formatMessage({ id: 'edition' })}
                                                {' '}
                                                    &nbsp;
                                                {`${languageRedux === 'fa' ? ToPersianNumber(edition?.number) : edition?.number} `}
                                              </option>
                                            ))
                                        }
                              </select>
                            )
                            : (
                              <div className="lockedEditionSelect">
                                <div>
                                  {intl.formatMessage({ id: 'edition' })}
                                  {' '}
                                            &nbsp;
                                  {`${languageRedux === 'fa' ? ToPersianNumber(selectedEdition?.number) : selectedEdition?.number} `}
                                </div>
                                <img src="/icons/vectorDown.svg" alt="" />
                              </div>
                            )
                          : ''
                    }

          {/* <div className="purchasePrice"> */}
          {/*  {intl.formatMessage({ id: 'price' })} */}
          {/* </div> */}
          <div className="fixPrice">
            {selectedMoment?.title}
            {/* { */}
            {/*                selectedMoment?.price */}
            {/*                && languageRedux === 'fa' */}
            {/*                  ? ToPersianNumber(addCommas(selectedMoment?.price)) */}
            {/*                  : selectedMoment?.price */}
            {/*            } */}
            {/* {' '} */}
            {/* {selectedMoment?.currency */}
            {/*                && selectedMoment?.currency?.symbol} */}
          </div>
        </div>

      </div>

      <div className="fixPriceInputSec">
        <input
          type="text"
          name="price"
          className="priceInput"
          value={ToPersianNumber(addCommas(price))}
          placeholder={intl.formatMessage({ id: 'type_your_price' })}
          onChange={inputHandler}
        />
        <div className="defaultChain">
          تومان
        </div>
      </div>

      <button
        type="button"
        onClick={createFixPrice}
        disabled={parseInt(price, 10) < 2000 || price?.length === 0}
        className="btn setFixPriceButton"
      >
        {isLoading
          ? (
            <div>
              <Spinner size="sm" animation="border" />
            </div>
          ) : intl.formatMessage({ id: 'submit' })}
      </button>

    </Modal>
  );
}
