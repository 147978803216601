import React, { useEffect, useState } from 'react';
import '../../../../assets/styles/layout/Header/Header.scss';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import HeaderContent from './HeaderContent';
import { setShowSharePopover } from '../../../../redux/slices/accountSlice';

export default function Header({ headerProps, momentTypeProps }) {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    const header = document.getElementById('header');
    const sticky = header.offsetTop;
    const setScroll = (e) => {
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');

        // header.classList.remove('absolute');
      } else {
        header.classList.remove('sticky');

        // if (pathname === ('/')) {
        //   header.classList.add('absolute');
        // } else {
        //   header.classList.remove('absolute');
        // }
      }
    };
    window.addEventListener('scroll', setScroll);
    return function cleanup() {
      window.removeEventListener('scroll', setScroll);
    };
  });

  return (
    <div
      id="header"
      className={`headerParent ${pathname?.includes('/waitlist') ? 'darkModeHeader ' : ''}`}
      onClick={() => {
        dispatch(setShowSharePopover(false));
      }}
    >
      <div className="headerDiv container">
        <HeaderContent
          headerProps={headerProps}
          momentTypeProps={momentTypeProps}
        />
      </div>
    </div>
  );
}
