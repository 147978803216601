import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from '../general/Skeleton';
import { getWindowSize } from '../../../helpers/getWindowSize';
import { selectLanguage } from '../../../redux/slices/languageSlice';
import { selectUserData } from '../../../redux/slices/accountSlice';

export default function FilterAndSortSec({
  tabContentLoading,
  momentType,
  setMomentType,
  setSort,
  openSort,
  setOpenSort,
}) {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { username } = useParams();
  const userData = useSelector(selectUserData);
  const lsUsername = userData.username;
  const navigate = useNavigate();
  const location = useLocation();
  const languageRedux = useSelector(selectLanguage);
  const intl = useIntl();
  const loggedInUserData = useSelector(selectUserData);

  const [sortText, setSortText] = useState(intl.formatMessage({ id: 'newest' }));
  const matches = (windowSize?.innerWidth <= 972);
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    setOpenSort(false);
    if (location.pathname.includes('collected') || (!location.pathname.includes('collected') && !location.pathname.includes('created') && !location.pathname.includes('favorites') && !location.pathname.includes('transaction') && !location.pathname.includes('gifts'))) {
      setMomentType(0);
    }
    // if (location.pathname.includes('created')) {
    //   setMomentType(1);
    // }
    if (location.pathname.includes('favorites')) {
      setMomentType(2);
    }
    // if (location.pathname.includes('gifts')) {
    //   setMomentType(3);
    // }
    if (location.pathname.includes('transaction')) {
      setMomentType(4);
    }
  }, [location]);

  return (
    <div className="filterSortSec">
      {
                matches
                  ? (
                    <div className={momentType === 3 ? 'giftMobTabs' : 'mobTabs'}>
                      {
                                tabContentLoading
                                  ? (
                                    <Skeleton
                                      className="form-select form-select-solid form-select-lg mobSelect"
                                    />
                                  )
                                  : (
                                    <select
                                      className={`form-select form-select-solid form-select-lg ${languageRedux === 'fa' ? 'mobSelectPersian' : 'mobSelect'}`}
                                      onChange={(event) => {
                                        setMomentType(parseInt(event.target.value, 10));
                                        if (parseInt(event.target.value, 10) === 0) {
                                          navigate(username ? `/users/${username}/collected` : '/profile/collected');
                                          if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                            setSort('price,asc');
                                          }
                                        }
                                        // if (parseInt(event.target.value, 10) === 1) {
                                        //   navigate(`/users/${username}/created`);
                                        //   if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                        //     setSort('price,asc');
                                        //   }
                                        // }
                                        if (parseInt(event.target.value, 10) === 2) {
                                          navigate(username ? `/users/${username}/favorites` : '/profile/favorites');
                                          if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                            setSort('price,asc');
                                          }
                                        }
                                        // if (parseInt(event.target.value, 10) === 3) {
                                        //   navigate(`/users/${username}/gifts`);
                                        //   if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                        //     setSort('amount,asc');
                                        //   }
                                        // }
                                        if (parseInt(event.target.value, 10) === 4) {
                                          navigate(username ? `/users/${username}/transaction` : '/profile/transaction');
                                          if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                            setSort('amount,asc');
                                          }
                                        }
                                      }}
                                      value={momentType}
                                    >
                                      <option value={0} className="mobTab">
                                        <div style={{ paddingBottom: 12 }}>
                                          {intl.formatMessage({ id: 'collected' })}
                                          <hr />
                                        </div>
                                      </option>
                                      {/* <option value={1}> */}
                                      {/*  {intl.formatMessage({ id: 'created' })} */}
                                      {/* </option> */}
                                      <option value={2}>
                                        {intl.formatMessage({ id: 'favorites' })}
                                      </option>

                                      {
                                        username
                                          ? lsUsername === username
                                            && (
                                            <>
                                              {/* <option value={3}> */}
                                              {/*  {intl.formatMessage({ id: 'gifts' })} */}
                                              {/* </option> */}
                                              <option value={4}>
                                                {intl.formatMessage({ id: 'transaction' })}
                                              </option>
                                            </>
                                            )
                                          : loggedInUserData?.username === lsUsername
                                             && (
                                             <>
                                               {/* <option value={3}> */}
                                               {/*  {intl.formatMessage({ id: 'gifts' })} */}
                                               {/* </option> */}
                                               <option value={4}>
                                                 {intl.formatMessage({ id: 'transaction' })}
                                               </option>
                                             </>
                                             )

                                            }
                                    </select>
                                  )
                            }
                    </div>
                  )
                  : (
                    <div className="flexRow">
                      {
                                tabContentLoading
                                  ? (
                                    <>
                                      <Skeleton style={{ height: 36, width: 75 }} className="filter" />
                                      <Skeleton style={{ height: 36, width: 75 }} className="filter" />
                                      <Skeleton style={{ height: 36, width: 75 }} className="filter" />
                                    </>
                                  )
                                  : (
                                    <>
                                      <div
                                        className={(location.pathname.includes('collected') || (!location.pathname.includes('collected') && !location.pathname.includes('created') && !location.pathname.includes('favorites') && !location.pathname.includes('transaction') && !location.pathname.includes('gifts'))) ? 'selectedFilter' : 'filter'}
                                        onClick={() => {
                                          navigate(username ? `/users/${username}/collected` : '/profile/collected');
                                          if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                            setSort('price,asc');
                                          }
                                        }}
                                      >
                                        {intl.formatMessage({ id: 'collected' })}
                                      </div>
                                      {/* <div */}
                                      {/*  className={location?.pathname?.includes('created') ? 'selectedFilter' : 'filter'} */}
                                      {/*  onClick={() => { */}
                                      {/*    navigate(`/users/${username}/created`); */}

                                      {/*    if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) { */}
                                      {/*      setSort('price,asc'); */}
                                      {/*    } */}
                                      {/*  }} */}
                                      {/* > */}
                                      {/*  {intl.formatMessage({ id: 'created' })} */}
                                      {/* </div> */}
                                      <div
                                        className={location.pathname.includes('favorites') ? 'selectedFilter' : 'filter'}
                                        onClick={() => {
                                          navigate(username ? `/users/${username}/favorites` : '/profile/favorites');

                                          if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                            setSort('price,asc');
                                          }
                                        }}
                                      >
                                        {intl.formatMessage({ id: 'favorites' })}
                                      </div>
                                      {
                                        username
                                          ? lsUsername === username
                                                && (
                                                  <div
                                                    className={location?.pathname.includes('transaction') ? 'selectedFilter' : 'filter'}
                                                    onClick={() => {
                                                      navigate(username ? `/users/${username}/transaction` : '/profile/transaction');
                                                      if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                                        setSort('amount,asc');
                                                      }
                                                    }}
                                                  >
                                                    {intl.formatMessage({ id: 'transaction' })}
                                                  </div>

                                                )
                                          : loggedInUserData?.username === lsUsername
                                            && (
                                            <div
                                              className={location?.pathname.includes('transaction') ? 'selectedFilter' : 'filter'}
                                              onClick={() => {
                                                navigate(username ? `/users/${username}/transaction` : '/profile/transaction');
                                                if (sortText === intl.formatMessage({ id: 'priceLowToHigh' }) || sortText === intl.formatMessage({ id: 'priceHighToLow' })) {
                                                  setSort('amount,asc');
                                                }
                                              }}
                                            >
                                              {intl.formatMessage({ id: 'transaction' })}
                                            </div>
                                            )
                                            }
                                    </>
                                  )
                            }
                    </div>
                  )
            }
      <div>
        {
                    tabContentLoading
                      ? <Skeleton style={{}} className="skeletonSortBtn" />
                      : (
                        momentType !== 3
                            && (
                            <div style={{ position: 'relative' }}>
                              <button
                                className={openSort ? 'btn sortBtnOpen' : 'btn sortBtnClose'}
                                onClick={() => {
                                  setOpenSort(!openSort);
                                }}
                                type="button"
                              >
                                {sortText}
                              </button>
                              <div className={openSort ? 'basicPopover sortPopover' : 'hiddenPopover'}>

                                <div className="popoverBody">
                                  <div
                                    onClick={() => {
                                      setSortText(intl.formatMessage({ id: 'newest' }));
                                      setSort('createdAt,desc');
                                    }}
                                    key="newest"
                                    className="sortMenuItem"
                                  >
                                    {intl.formatMessage({ id: 'newest' })}
                                  </div>
                                  <div
                                    onClick={() => {
                                      setSortText(intl.formatMessage({ id: 'oldest' }));
                                      setSort('createdAt,asc');
                                    }}
                                    key="Oldest"
                                    className="sortMenuItem"
                                  >
                                    {intl.formatMessage({ id: 'oldest' })}
                                  </div>
                                  <div
                                    onClick={() => {
                                      setSortText(intl.formatMessage({ id: 'priceLowToHigh' }));
                                      if (momentType === 4 || location.pathname.includes('transaction')) {
                                        setSort('amount,asc');
                                      } else {
                                        setSort('price,asc');
                                      }
                                    }}
                                    key="Price low to high"
                                    className="sortMenuItem"
                                  >
                                    {intl.formatMessage({ id: 'priceLowToHigh' })}
                                  </div>
                                  <div
                                    onClick={() => {
                                      setSortText(intl.formatMessage({ id: 'priceHighToLow' }));
                                      if (momentType === 4 || location.pathname.includes('transaction')) {
                                        setSort('amount,desc');
                                      } else {
                                        setSort('price,desc');
                                      }
                                    }}
                                    key="Price high to low"
                                    className="lastMenuItem"
                                  >
                                    {intl.formatMessage({ id: 'priceHighToLow' })}
                                  </div>
                                </div>

                              </div>
                            </div>
                            )
                      )
                }

      </div>
    </div>
  );
}
