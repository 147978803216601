import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ProfilePicture from '../../other/ProfilePicture.jsx';
import { ShowUserName } from '../ShowUserName.jsx';
import ToPersianNumber from '../../../../helpers/toPersianNumber.jsx';
import addCommas from '../../../../helpers/addCommas.jsx';
import { selectLanguage } from '../../../../redux/slices/languageSlice';
import '../../../../assets/styles/general/General.scss';
import api from '../../../../api.js';
import ProfileMomentImage from './ProfileMomentImage.jsx';
import { setShowToast, setToastMessage, setToastStatus } from '../../../../redux/slices/toastSlice';
import { selectIsLogin } from '../../../../redux/slices/accountSlice';
import PendingCard from './PendingCard';
import FailedCard from './FailedCard.jsx';
import SellNFTAttentionModal from '../../nft/SellNFTAttentionModal.jsx';

const CancelAttentionPopup = React.lazy(() => import('../FixPrice/CancelAttentionPopup.jsx'));
const FixPriceModal = React.lazy(() => import('../FixPrice/FixPriceModal'));
const PublishWarning = React.lazy(() => import('./PublishWarning'));
const TransferWallet = React.lazy(() => import('./TransferWallet'));

function SellItem({
  moment, setShowTransfer, setShowSellModal,
  setShowCancelAttentionModal,
  showSellNftAttention,
  setShowSellNftAttention,
  selectedEdition,
  isInCentralWallet,
}) {
  const intl = useIntl();

  const sellBtnHandler = () => {
    if (moment?.type === 'edition') {
      // const selectedEdition = moment?.momentEditions?.find((item) => item?.id === parseInt(editionId, 10));

      // if it is NFT , user should see the attention
      if (selectedEdition?.nft?.status === 'success') {
        setShowSellNftAttention(true);
      }
      // if it is moment ( not NFT) , user can sett the price and sell it
      else {
        setShowSellModal(true);
      }
    } else {
      // if it is NFT , user should see the attention

      console.log(moment);

      if (moment?.nft?.status === 'success') {
        setShowSellNftAttention(true);
      }
      // if it is moment ( not NFT) , user can sett the price and sell it
      else {
        setShowSellModal(true);
      }
    }
  };

  if (moment?.type === 'single') {
    if (moment?.isForSale) {
      /* if it's not onSale you can't buy it and  when it's onSale you can Buy it */
      return (
        <>
          <div
            className="charityItem popupItem"
            onClick={() => {
              setShowCancelAttentionModal(true);
            }}
          >
            {intl.formatMessage({ id: 'cancel_fix_price' })}
          </div>
          {/*<div className="charityItem popupItem disabledItemColor">*/}
          {/*  {intl.formatMessage({ id: 'transfer_to_wallet' })}*/}
          {/*</div>*/}

          <Link to={`/moment-certificate/${moment?.id}`} target="_blank">
            <div className="charityItem popupItem">
              {intl.formatMessage({ id: 'ownership_document' })}
            </div>
          </Link>

        </>
      );
    }
    if (!moment?.nft || moment?.nft?.status === 'not-set') {
      // it's just a moment that could be both transfer and onSale
      return (
        <>
          <div
            className="charityItem popupItem"
            onClick={sellBtnHandler}
          >
            {intl.formatMessage({ id: 'sell' })}
          </div>
          {/*<div className="charityItem popupItem" onClick={() => setShowTransfer(true)}>*/}
          {/*  {intl.formatMessage({ id: 'transfer_to_wallet' })}*/}
          {/*</div>*/}

          <Link to={`/moment-certificate/${moment?.id}`} target="_blank">
            <div className="charityItem popupItem">
              {intl.formatMessage({ id: 'ownership_document' })}
            </div>
          </Link>

        </>
      );
    }
    if (moment?.nft?.status === 'failed' || moment?.nft.status === 'success') {
      // minting failed so it can be mint (retry minting) and also can not be for sale
      return (
        <>
          <div
            className="charityItem popupItem"
            onClick={sellBtnHandler}
          >
            {intl.formatMessage({ id: 'sell' })}
          </div>

          {/*<div*/}
          {/*  className={`charityItem popupItem ${isInCentralWallet ? '' : 'disabledItemColor'}`}*/}
          {/*  onClick={() => {*/}
          {/*    if (isInCentralWallet) {*/}
          {/*      setShowTransfer(true);*/}
          {/*    }*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {intl.formatMessage({ id: 'transfer_to_wallet' })}*/}
          {/*</div>*/}

          <Link to={`/moment-certificate/${moment?.id}`} target="_blank">
            <div className="charityItem popupItem">
              {intl.formatMessage({ id: 'ownership_document' })}
            </div>
          </Link>

        </>
      );
    }
    if (moment?.nft.status === 'pending') {
      // it's minting and can not be mint again , and also can not be for sale
      // it has been minted  and also can not be for sale
      return (
        <>
          <div className="charityItem popupItem disabledItemColor">
            {intl.formatMessage({ id: 'sell' })}
          </div>
          {/*<div className="charityItem popupItem disabledItemColor">*/}
          {/*  {intl.formatMessage({ id: 'transfer_to_wallet' })}*/}
          {/*</div>*/}

          <Link to={`/moment-certificate/${moment?.id}`} target="_blank">
            <div className="charityItem popupItem">
              {intl.formatMessage({ id: 'ownership_document' })}
            </div>
          </Link>

        </>
      );
    }
  }

  if (moment?.type === 'edition') {
    const myEdition = moment?.momentEditions.find((item) => item?.isOwner === true);
    if (myEdition?.isForSale) {
      /* if it's not onSale you can't buy it and  when it's onSale you can Buy it */
      return (
        <>
          <div
            className="charityItem popupItem"
            onClick={() => {
              setShowCancelAttentionModal(true);
            }}
          >
            {intl.formatMessage({ id: 'cancel_fix_price' })}
          </div>
          {/*<div className="charityItem popupItem disabledItemColor">*/}
          {/*  {intl.formatMessage({ id: 'transfer_to_wallet' })}*/}
          {/*</div>*/}

          <Link to={`/moment-certificate/${moment?.id}/editions/${myEdition?.id}`} target="_blank">
            <div className="charityItem popupItem">
              {intl.formatMessage({ id: 'ownership_document' })}
            </div>
          </Link>

        </>
      );
    }
    if (!myEdition?.nft || myEdition?.nft?.status === 'not-set') {
      // it's just a moment that could be both transfer and onSale
      return (
        <>
          <div
            className="charityItem popupItem"
            onClick={sellBtnHandler}
          >
            {intl.formatMessage({ id: 'sell' })}
          </div>
          {/*<div className="charityItem popupItem" onClick={() => setShowTransfer(true)}>*/}
          {/*  {intl.formatMessage({ id: 'transfer_to_wallet' })}*/}
          {/*</div>*/}

          <Link to={`/moment-certificate/${moment?.id}/editions/${myEdition?.id}`} target="_blank">
            <div className="charityItem popupItem">
              {intl.formatMessage({ id: 'ownership_document' })}
            </div>
          </Link>

        </>
      );
    }
    if (myEdition?.nft?.status === 'failed' || myEdition?.nft.status === 'success') {
      // minting failed so it can be mint (retry minting) and also can not be for sale
      return (
        <>
          <div
            className="charityItem popupItem"
            onClick={sellBtnHandler}
          >
            {intl.formatMessage({ id: 'sell' })}
          </div>
          {/*<div*/}
          {/*  className={`charityItem popupItem ${isInCentralWallet ? '' : 'disabledItemColor'}`}*/}
          {/*  onClick={() => {*/}
          {/*    if (isInCentralWallet) {*/}
          {/*      setShowTransfer(true);*/}
          {/*    }*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {intl.formatMessage({ id: 'transfer_to_wallet' })}*/}
          {/*</div>*/}

          <Link to={`/moment-certificate/${moment?.id}/editions/${myEdition?.id}`} target="_blank">
            <div className="charityItem popupItem">
              {intl.formatMessage({ id: 'ownership_document' })}
            </div>
          </Link>

        </>
      );
    }
    if (myEdition?.nft.status === 'pending') {
      // it's minting and can not be mint again , and also can not be for sale
      // it has been minted  and also can not be for sale
      return (
        <>
          <div className="charityItem popupItem disabledItemColor">
            {intl.formatMessage({ id: 'sell' })}
          </div>
          {/*<div className="charityItem popupItem disabledItemColor">*/}
          {/*  {intl.formatMessage({ id: 'transfer_to_wallet' })}*/}
          {/*</div>*/}

          <Link to={`/moment-certificate/${moment?.id}/editions/${myEdition?.id}`} target="_blank">
            <div className="charityItem popupItem">
              {intl.formatMessage({ id: 'ownership_document' })}
            </div>
          </Link>

        </>
      );
    }
  }
}

export default function ProfileCard({
  moment, username, setTransferToWallet, setPublishStatusChanged,
  tab, userDatas,
  setIsMomentStatusChanged,
}) {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);
  const isLogin = useSelector(selectIsLogin);
  const dispatch = useDispatch();
  const location = useLocation();

  const [showWarning, setShowWarning] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);
  const [showCancelAttentionModal, setShowCancelAttentionModal] = useState(false);

  const [showSellNftAttention, setShowSellNftAttention] = useState(false);
  const [transfers, setTransfers] = useState({
    pages: null,
    transferCount: null,
    transfers: [],
  });
  const [isTransferTokenLoading, setIsTransferTokenLoading] = useState(false);

  const [isPublic, setIsPublic] = useState(moment?.isPublic);

  const [retryLoading, setRetryLoading] = useState(false);

  const [playMode, setPlayMode] = useState(false);

  const [selectedEdition, setSelectedEdition] = useState(moment?.momentEditions?.find((item) => item?.isOwner === true));

  const [isCheckingTheNFTLocation, setIsCheckingTheNFTLocation] = useState(false);

  const [isInCentralWallet, setIsInCentralWallet] = useState(false);

  useEffect(() => {
    if (moment) {
      if (moment?.type === 'edition') {
        // eslint-disable-next-line no-shadow
        // const findSelectedEdition = moment?.momentEditions?.find((item) => item.id === parseInt(editionId, 10));

        if (selectedEdition?.nft) {
          api.get(`moments/transfers/${selectedEdition?.nft?.id}`).then(({ data }) => {
            if (data.transfers) {
              setTransfers((prevState) => ({
                pages: data?.pages,
                transfersCount: data?.transferCount,
                transfers: [
                  ...prevState.transfers,
                  ...data.transfers,
                ],
              }));
            }
          }).catch(({ response }) => {
            console.log(response);
          });
        }
      } else if (moment?.nft) {
        api.get(`moments/transfers/${moment?.nft?.id}`).then(({ data }) => {
          if (data?.transfers) {
            setTransfers((prevState) => ({
              ...prevState,
              transfers: [
                ...prevState.transfers,
                ...data.transfers,
              ],
            }));
          }
        }).catch(({ response }) => {
          console.log(response);
        });
      }

      if (moment?.type === 'edition') {
        if (selectedEdition?.nft?.status === 'success' && selectedEdition?.isOwner) {
          setIsCheckingTheNFTLocation(true);
          api.get(`moments/central-wallet/${selectedEdition?.nft?.id}`).then(({ data }) => {
            setIsInCentralWallet(data?.isInCentralWallet);

            setIsCheckingTheNFTLocation(false);
          }).catch(({ response }) => {
            setIsCheckingTheNFTLocation(false);
            console.log(response);
          });
        }
      } else if (moment?.nft?.status === 'success' && moment?.isOwner) {
        setIsCheckingTheNFTLocation(true);
        api.get(`moments/central-wallet/${moment?.nft?.id}`).then(({ data }) => {
          setIsInCentralWallet(data?.isInCentralWallet);

          setIsCheckingTheNFTLocation(false);
        }).catch(({ response }) => {
          setIsCheckingTheNFTLocation(false);
          console.log(response);
        });
      }
    }
  }, [moment, selectedEdition]);

  const urlHandler = () => {
    if (moment.isSold) {
      if (moment?.type === 'single') {
        return `/nfts/${moment.id}`;
      }
      if (moment?.type === 'edition') {
        if (isLogin) {
          // if user has edition in the moment go to user's first edition
          const userEdition = moment?.momentEditions?.find((item) => item.userId === userDatas?.user.id);
          if (userEdition) {
            if (Object?.keys(userEdition)?.length > 0) {
              return `/nfts/${moment.id}/editions/${userEdition?.id}`;
            }
          }

          return `/nfts/${moment.id}/editions/${moment?.momentEditions[0]?.id}`;
        }

        return `/nfts/${moment.id}/editions/${moment?.momentEditions[0]?.id}`;
      }
    } else {
      return `/campaigns/${moment?.campaign?.title}/${moment?.campaignId}`;
    }
  };

  const publishSwitchHandler = async (event) => {
    setShowWarning(true);
  };
  const popover = (
    <Popover placement="top-start" id="popover-basic" className="publishPopover">
      <Popover.Body>
        {/* <div className="charityItem popupItem"> */}
        {/*  <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid isPublicSwitch"> */}
        {/*    <label className="publishSwitchLabel">{intl.formatMessage({ id: 'public' })}</label> */}

        {/*    <input */}
        {/*      onChange={publishSwitchHandler} */}
        {/*      className="form-check-input profileCardPublishSwitch" */}
        {/*      type="checkbox" */}
        {/*      value={isPublic} */}
        {/*      checked={isPublic} */}
        {/*      name="notifications" */}
        {/*    /> */}
        {/*  </div> */}
        {/* </div> */}
        <SellItem
          moment={moment}
          setShowTransfer={setShowTransfer}
          setShowSellModal={setShowSellModal}
          setShowCancelAttentionModal={setShowCancelAttentionModal}
          showSellNftAttention={showSellNftAttention}
          setShowSellNftAttention={setShowSellNftAttention}
          selectedEdition={selectedEdition}
          isInCentralWallet={isInCentralWallet}
        />
      </Popover.Body>
    </Popover>
  );

  const retryMinting = () => {
    setRetryLoading(true);
    api.post(`/profile/transfer/${moment.id}/retry`).then(({ data }) => {
      setRetryLoading(false);
      setPublishStatusChanged((prevState) => (
        !prevState
      ));
    }).catch(({ response }) => {
      setRetryLoading(false);
      if (!response.data.message) {
        dispatch(setShowToast(true));
        dispatch(setToastStatus(0));
        dispatch(setToastMessage(intl.formatMessage({ id: 'try_again' })));
      }
    });
  };

  if ((moment?.nft?.status === 'pending' || retryLoading) && tab !== 'favorites') {
    return (
      <PendingCard moment={moment} />
    );
  }

  if (moment?.nft?.status === 'failed' && tab !== 'favorites') {
    return (
      <FailedCard
        moment={moment}
        urlHandler={urlHandler}
        popover={popover}
        retryMinting={retryMinting}
        setShowWarning={setShowWarning}
        showWarning={showWarning}
        setPublishStatusChanged={setPublishStatusChanged}
        isPublic={isPublic}
      />
    );
  }

  return (
    <div
      className="nftCard momentCard"
      key={moment.id}
    >
      <Link to={urlHandler()} aria-label={`link to ${moment.id}'s moment`}>
        <ProfileMomentImage
          moment={moment}
          playMode={playMode}
          setPlayMode={setPlayMode}
        />
      </Link>

      <div className="cardMomentTitle">
        <img src="/icons/sportCategory/frame.svg" alt="" />
        {languageRedux === 'fa' ? ToPersianNumber(moment.title) : moment.title}
      </div>

      <div
        className="flexClass align-items-center justify-content-between ownerParent"
      >

        {
                    !moment.owner && moment?.momentEditions?.length === 0
                    // doesnt have owner
                      ? (
                        <div className="ownerSec">
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="momentCharitiesDropDown5"
                                className="ownerIdMenuToggle"
                              >
                                <div className="ownerId flexClass align-items-center">
                                  <div
                                    className="charityImage"
                                    style={{ backgroundImage: 'url(/icons/charityIcon.svg)' }}
                                  />
                                  {
                                                    moment.campaign?.charities?.length > 1
                                                    // change this
                                                      ? `${moment.campaign?.charities.length} ${intl.formatMessage({ id: 'charities' })}`
                                                      : moment.campaign?.charities && moment.campaign?.charities[0]?.name

                                                }
                                </div>
                              </Dropdown.Toggle>

                              {
                                            moment.campaign?.charities?.length > 1
                                            // change this
                                            && (
                                            <Dropdown.Menu align="end">

                                              {
                                                        moment?.campaign?.charities.map((charity) => (
                                                          <div key={charity?.id}>
                                                            <Dropdown.Ite>
                                                              <div className="charityItem">
                                                                <div
                                                                  className="charityImage"
                                                                  style={{ backgroundImage: 'url(/icons/charityIcon.svg)' }}
                                                                />
                                                                <div>
                                                                  {charity?.name}
                                                                </div>
                                                              </div>
                                                            </Dropdown.Ite>
                                                          </div>
                                                        ))
                                                    }
                                            </Dropdown.Menu>
                                            )
                                        }
                            </Dropdown>

                          </div>

                        </div>
                      )
                      : (
                        <div className="ownerSec">
                          {
                                    moment?.momentEditions?.length > 0
                                      ? (
                                        <div className="multipleOwnerParent">
                                          <div className="symbol-group symbol-hover flex-nowrap">
                                            {moment?.momentEditions.map((edition, idx) => {
                                              if (moment?.momentEditions?.length >= 4) {
                                                if (idx < 4) {
                                                  return (
                                                  // todo link to where?
                                                    <div
                                                      className="symbol symbol-35px symbol-circle"
                                                      data-bs-toggle="tooltip"
                                                      key={edition?.id}
                                                    >
                                                      <div className="multipleOwnerImg">
                                                        {
                                                                                edition.owner.image
                                                                                  ? <img src={edition.owner.image} alt="" />
                                                                                  : (
                                                                                    <ProfilePicture
                                                                                      colors={edition.owner.profileColors}
                                                                                    />
                                                                                  )
                                                                            }
                                                      </div>
                                                    </div>

                                                  );
                                                }
                                              }
                                              if (moment?.momentEditions?.length < 4) {
                                                return (
                                                  <div
                                                    className="symbol symbol-35px symbol-circle"
                                                    data-bs-toggle="tooltip"
                                                  >
                                                    <div className="multipleOwnerImg">
                                                      {
                                                                            edition.owner.image
                                                                              ? <img src={edition.owner.image} alt="" />
                                                                              : (
                                                                                <ProfilePicture
                                                                                  colors={edition.owner.profileColors}
                                                                                />
                                                                              )
                                                                        }
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      )
                                      : moment.owner
                                        ? moment?.owner?.image
                                          ? (
                                            <Link to={`/profile`}
                                              //   to={localStorage.getItem('accessToken') && ownerOrBuyer === localStorage?.getItem("username")}
                                              aria-label={`link to ${moment?.owner?.username}'s profile`}
                                            >
                                              <div
                                                className="ownerProfile"
                                                style={{ backgroundImage: `url(${moment.owner?.image})` }}
                                              />
                                            </Link>
                                          )
                                          : (
                                            <Link
                                              to={`/profile`}
                                              aria-label={`link to ${moment?.owner?.username}'s profile`}
                                            >
                                              <div>
                                                <ProfilePicture
                                                  size={25}
                                                  colors={moment.owner.profileColors}
                                                />
                                              </div>
                                            </Link>
                                          )
                                        : (
                                          <div
                                            className="charityImage"
                                            style={{ backgroundImage: 'url(/icons/charityIcon.svg)' }}
                                          />
                                        )

                                }
                          {
                                    moment?.momentEditions?.length > 0
                                      ? (
                                        <div className="ownerId">
                                          {`${intl.formatMessage({ id: 'has_several' })} 
                              ${languageRedux === 'fa' ? ToPersianNumber(moment?.editionsCount) : moment?.editionsCount}
                               ${intl.formatMessage({ id: 'version' })} `}

                                          {/* { */}
                                          {/*          languageRedux === 'fa' */}
                                          {/*            ? `(${ToPersianNumber(moment?.momentEditions?.length)}/${parseInt(moment?.campaign?.momentEditionsFraction, 10) === 0 ? ToPersianNumber(moment?.momentEditions?.length) : ToPersianNumber(parseInt(moment?.campaign?.momentEditionsFraction, 10))})` */}
                                          {/*            : `(${moment?.momentEditions?.length}/${parseInt(moment?.campaign?.momentEditionsFraction, 10) === 0 ? moment?.momentEditions?.length : parseInt(moment?.campaign?.momentEditionsFraction, 10)})` */}
                                          {/*      } */}
                                        </div>
                                      )

                                      : moment.owner
                                        ? (
                                          <Link to={`/profile`} aria-label={`link to ${moment?.owner?.username}'s profile`}>
                                            <div className="ownerId">
                                              <ShowUserName user={moment.owner} />
                                            </div>
                                          </Link>
                                        )
                                        : (
                                          <div>
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                id="momentCharitiesDropDown6"
                                                className="ownerIdMenuToggle"
                                              >
                                                <div className="ownerId">
                                                  {
                                                                    moment.campaign?.charities.length > 1
                                                                      ? `${moment.campaign?.charities.length} ${intl.formatMessage({ id: 'charities' })}`
                                                                      : moment.campaign?.charities[0]?.name

                                                                }
                                                </div>
                                              </Dropdown.Toggle>

                                              {
                                                            moment.campaign?.charities.length > 1
                                                            && (
                                                            <Dropdown.Menu>
                                                              {
                                                                        moment?.campaign?.charities.map((charity) => (
                                                                          <Dropdown.Item>
                                                                            <div className="charityItem">
                                                                              <div
                                                                                className="charityImage"
                                                                                style={{ backgroundImage: 'url(/icons/charityIcon.svg)' }}
                                                                              />
                                                                              <div>
                                                                                {charity?.name}
                                                                              </div>
                                                                            </div>
                                                                          </Dropdown.Item>
                                                                        ))
                                                                    }
                                                            </Dropdown.Menu>
                                                            )
                                                        }

                                            </Dropdown>
                                          </div>
                                        )
                                }
                        </div>
                      )
                }

        {
                    !location.pathname.includes('favorites')
                    && (
                    <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover}>
                      <img
                        src="/icons/more.svg"
                        alt=""
                        className="moreSvg"
                      />
                    </OverlayTrigger>
                    )
                }

      </div>

      <div className="priceSec">
        <div className="priceRow">
          {
                        moment?.isForSale
                          ? (
                            <div className="price">
                              {moment?.listingPrice ? languageRedux === 'fa' ? ToPersianNumber(String(addCommas(moment?.listingPrice))) : String(addCommas(moment?.listingPrice)) : '-'}
                              {' '}
                              تومان
                            </div>
                          )
                          : moment.price > 0
                            ? (
                              <div className="price">
                                {moment?.momentEditions?.length > 0 && '>'}
                                {`${languageRedux === 'fa' ? ToPersianNumber(String(addCommas(moment?.price))) : String(addCommas(moment?.price))} ${moment?.currency?.symbol}`}
                              </div>
                            )
                            : (
                              <div className="price">
                                {intl.formatMessage({ id: 'its_not_for_sale' })}
                              </div>
                            )
                    }
        </div>
        <TransferWallet
          show={showTransfer}
          setShow={setShowTransfer}
          momentId={moment?.id}
          setTransferToWallet={setTransferToWallet}
          selectedEdition={selectedEdition}
          setSelectedEdition={setSelectedEdition}
          moment={moment}
        />

      </div>
      <PublishWarning
        setShow={setShowWarning}
        isPublic={isPublic}
        moment={moment}
        show={showWarning}
        setPublishStatusChanged={setPublishStatusChanged}
      />

      <SellNFTAttentionModal
        show={showSellNftAttention}
        setShow={setShowSellNftAttention}
        setShowSellModal={setShowSellModal}
        transfers={transfers?.transfers}
      />

      <FixPriceModal
        show={showSellModal}
        setShow={setShowSellModal}
        selectedMoment={moment}
        setIsMomentStatusChanged={setIsMomentStatusChanged}
        isTransferTokenLoading={isTransferTokenLoading}
        setIsTransferTokenLoading={setIsTransferTokenLoading}
        selectedEdition={selectedEdition}
        setSelectedEdition={setSelectedEdition}
      />

      <CancelAttentionPopup
        show={showCancelAttentionModal}
        setShow={setShowCancelAttentionModal}
        moment={moment}
        setIsMomentStatusChanged={setIsMomentStatusChanged}
      />

    </div>
  );
}
