import React from 'react';
import Skeleton from '../Skeleton.jsx';
import '../../../../assets/styles/general/General.scss';
import ToPersianNumber from "../../../../helpers/toPersianNumber.jsx";

export default function MomentCardSkeleton() {
  return (
    <div className="momentCard nftCard">
      <div
        className="preSaleImageWrapper"
      >
        <Skeleton
          style={{ width: '100%' }}
          className="normalNftImage"
        />
      </div>

        <Skeleton className="cardMomentTitle">
            <img src="/icons/sportCategory/frame.svg" alt="" />
        </Skeleton>

      <div className="ownerSec">

        <Skeleton
          style={{}}
          className="ownerProfile"
        />
        <Skeleton
          style={{ width: 39, height: 24 }}
          className="ownerId"
        />
      </div>
        <Skeleton className={"priceRow"} style={{height:21, marginTop:6}}>

        </Skeleton>
    </div>
  );
}
