import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../../../assets/styles/User/User.scss';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import api from '../../../../api.js';
import { setShowToast, setToastMessage, setToastStatus } from '../../../../redux/slices/toastSlice';

export default function PublishWarning({
  show,
  setShow,
  isPublic,
  moment,
  setPublishStatusChanged,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const changePublishStatus = () => {
    setIsLoading(true);
    api.patch(`/profile/moments/${moment?.id}/change-public-status`).then(({ data }) => {
      setIsLoading(false);
      setShow(false);
      setPublishStatusChanged((prevState) => (!prevState));
    }).catch(({ response }) => {
      setIsLoading(false);
      if (!response.data) {
        dispatch(setShowToast(true));
        dispatch(setToastStatus(0));
        dispatch(setToastMessage(intl.formatMessage({ id: 'try_again' })));
      }
      setShow(false);
    });
  };

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="warningModal  modal-dialog-centered"
      show={show}
            // onHide={handleClose}
      backdrop
    >
      <div className="unfollowModalBody">
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <div className="attentionTitle">
            Attention
          </div>

          <div className="warningDes">
            {
                            isPublic
                              ? intl.formatMessage({ id: 'privateWarning' })
                              : intl.formatMessage({ id: 'publicWarning' })
                        }
          </div>

          <div className="flexClass justify-content-between unfollowBtnSec">
            <button
              className="btn unfollowBtn"
              disabled={isLoading}
              onClick={changePublishStatus}
            >
              {intl.formatMessage({ id: 'ok' })}
            </button>
            <button
              className="btn warningModalCancelBtn"
              disabled={isLoading}
              onClick={() => setShow(false)}
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
