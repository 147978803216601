import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import '../../../../assets/styles/User/User.scss';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import api from '../../../../api.js';

export default function CancelAttentionPopup(props) {
  const {
    show, setShow, moment, setIsMomentStatusChanged,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const cancelListing = () => {
    setIsLoading(true);

    if (moment?.type === 'single') {
      api.delete(`/profile/moments/${moment?.id}/listings/cancel`).then(({ data: response }) => {
        setIsLoading(false);
        setIsMomentStatusChanged((prevState) => !prevState);
        setShow(false);
      }).catch(({ response }) => {
        setIsLoading(false);
      });
    }
    if (moment?.type === 'edition') {
      const myEdition = moment?.momentEditions.find((item) => item?.isOwner === true);
      api.delete(`/profile/moment-editions/${myEdition?.id}/listings/cancel`).then(({ data: response }) => {
        setIsLoading(false);
        setIsMomentStatusChanged((prevState) => !prevState);
        setShow(false);
      }).catch(({ response }) => {
        setIsLoading(false);
      });
    }
  };

  return (
    <Modal
      id="kt_modal_create_app"
      aria-hidden="true"
      dialogClassName="warningModal  modal-dialog-centered"
      show={show}
            // onHide={handleClose}
      backdrop
    >
      <div className="unfollowModalBody">
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <div className="attentionTitle">
            {intl.formatMessage({ id: 'attention' })}
          </div>

          <div className="warningDes">
            {intl.formatMessage({ id: 'cancel_fix_price_description' })}
          </div>

          <div className="flexClass justify-content-between unfollowBtnSec">
            <button
              className="btn unfollowBtn"
              disabled={isLoading}
              onClick={cancelListing}
            >
              {
                                isLoading
                                  ? <Spinner animation="border" className="cancelSpinner" />
                                  : intl.formatMessage({ id: 'ok' })
                            }
            </button>
            <button
              className="btn warningModalCancelBtn"
              disabled={isLoading}
              onClick={() => setShow(false)}
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
