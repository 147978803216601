import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {useLocation} from 'react-router';
import Accordion from 'react-bootstrap/Accordion';
import MomentCard from '../general/Card/MomentCard.jsx';
import MomentCardSkeleton from '../general/Card/MomentCardSkeleton.jsx';
import Skeleton from '../general/Skeleton';
import {selectLanguage} from '../../../redux/slices/languageSlice';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import {getWindowSize} from '../../../helpers/getWindowSize.jsx';
import FilterAccordion from './FilterAccordion';

export default function NFTList({
                                    momentsData,
                                    setFilters,
                                    itemOffset, pageLoading,
                                    setPageLoading,
                                    filters,
                                    setOpenFilterModal,
                                    // filterData,
                                    selectedTags,
                                    setSelectedTags,
                                    categories,
                                    filteredCategories,
                                    setFilteredCategories,
                                    selectedTagsWholeData,
                                    setSelectedTagsWholeData,
                                    setNftsLoading,
                                    nftsLoading
                                    // setSearchParams,
                                }) {
    const intl = useIntl();
    const languageRedux = useSelector(selectLanguage);
    const navigate = useNavigate();

    const location = useLocation();

    const endOffset = itemOffset + momentsData.moments?.length - 1;
    const [searchParams] = useSearchParams();

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const matches = (windowSize?.innerWidth <= 972);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const updateURLParams = (page) => {
        const params = new URLSearchParams(location.search);
        if (page) {
            params.set('page', page);
        } else {
            params.delete('page');
        }
        navigate(`${location.pathname}?${decodeURIComponent(params.toString())}`);
    };

    const handlePageClick = (event) => {
        // if (location.pathname.includes('family')) {
        //   navigate(`/family/explore?page=${(event.selected + 1)}`);
        // }
        // navigate(`/explore?page=${(event.selected + 1)}`);

        window.scrollTo(0, 0);
        setNftsLoading(true);
        setFilters((prevState) => ({
            ...prevState,
            page: event.selected + 1,
        }));
        updateURLParams(event.selected + 1);
    };

    function Items() {
        return momentsData.moments
            && momentsData.moments?.map((moment) => (
                <MomentCard key={moment.id} moment={moment}/>
            ));
    }

    return (
        <>
            {
                pageLoading
                    ? (
                        <div className="marketplacePageWrapper">
                            <div className="filterSideBar"/>
                            <div className="mobileFilterButtonSec">
                                <Skeleton/>
                            </div>

                            <div className="nftList">
                                {Array.from({length: 16}).map((_, index) => (
                                    <MomentCardSkeleton key={index}/>
                                ))}
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className="marketplacePageWrapper">

                            <div className="filterSideBar">

                                <FilterAccordion
                                    // categories={filterData}
                                    selectedTags={selectedTags}
                                    setSelectedTags={setSelectedTags}

                                    filteredCategories={filteredCategories}
                                    categories={categories}
                                    setFilteredCategories={setFilteredCategories}
                                    // selectedTagsLabelToShow={selectedTagsLabelToShow}
                                    // setSelectedTagsLabelToShow={setSelectedTagsLabelToShow}
                                    selectedTagsWholeData={selectedTagsWholeData}
                                    setSelectedTagsWholeData={setSelectedTagsWholeData}
                                    setPageLoading={setPageLoading}
                                    setNftsLoading={setNftsLoading}
                                    setFilters={setFilters}
                                />

                            </div>
                            {
                                nftsLoading ?
                                    <div className="nftList">
                                        {Array.from({length: 16}).map((_, index) => (
                                            <MomentCardSkeleton key={index}/>
                                        ))}
                                    </div>
                                    :
                                    momentsData?.moments?.length === 0 ?
                                        <div className="emptyMomentsList" style={{minHeight: window?.innerHeight - (matches ? 186 : 283)}}>
                                            {intl.formatMessage({id: 'there_is_no_onSale_moments'})}
                                        </div> :
                                        <div className="nftList">
                                            <Items/>
                                        </div>
                            }
                        </div>
                    )

            }

            {
                pageLoading
                    ? (
                        <div className="paginationSec">
                            <Skeleton style={{height: 16, width: 160}} className="showTitle"/>
                            <Skeleton style={{height: 28, width: 160}} className="explorePagination"/>
                        </div>
                    )
                    : momentsData?.pages > 1 && (
                    <div className="paginationSec">
                        <div className="showTitle">
                            {`${intl.formatMessage({id: 'showing'})} 
                      ${languageRedux === 'fa' ? ToPersianNumber(itemOffset) : itemOffset}
                       ${intl.formatMessage({id: 'to'})} 
                      ${languageRedux === 'fa' ? ToPersianNumber(endOffset) : endOffset} 
                      ${intl.formatMessage({id: 'of'})}
                       ${languageRedux === 'fa' ? ToPersianNumber(momentsData.momentsCount) : momentsData.momentsCount}
                        ${intl.formatMessage({id: 'entries'})}`}
                        </div>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<img src="/icons/arrowLeft.svg" alt=""/>}
                            onPageChange={handlePageClick}
                            pageLabelBuilder={((page) => {
                                if (languageRedux === 'fa') {
                                    return ToPersianNumber(page);
                                }
                                if (languageRedux !== 'fa') {
                                    return page;
                                }
                            })}
                            forcePage={filters.page - 1}
                            pageRangeDisplayed={5}
                            pageCount={momentsData.pages}
                            className="explorePagination"
                            activeClassName="activePage"
                            previousLabel={<img src="/icons/arrowRight.svg" alt=""/>}
                            renderOnZeroPageCount={null}
                        />
                    </div>

                )

            }

        </>
    );
}
