import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import LazyVideo from '../other/LazyVideo.jsx';
// import VideoComponent from '../other/VideoComponent.jsx';

export default function FirstViewport() {
  const intl = useIntl();

  return (
    <div className="landingFirstViewport">

      <div className="firstViewportTextWrapper">

        <div className="firstViewportTextSec">
          <div className="slogan">
            {intl.formatMessage({ id: 'moment_sport_slogan' })}
          </div>
          <div style={{ textAlign: 'justify' }}>
            {intl.formatMessage({ id: 'landing_first_txt' })}
          </div>
          <div className="testingTextInViewport">
            {intl.formatMessage({ id: 'before_call_to_action_info' })}
          </div>
          <div className="landingExploreBtnSec">
            <Link to="/explore">
              <button className="landingExploreBtn">

                {intl.formatMessage({ id: 'start_call_to_action' })}

                {/*                {intl.formatMessage({ id: 'landing_btn_1' })} */}
                {/*                <span style={{ fontWeight: 600 }}> */}
                {/*                  {intl.formatMessage({ id: 'landing_btn_2' })} */}
                {' '}
                {/*                </span> */}
                {/* &nbsp; */}
                {' '}
                {/*                {intl.formatMessage({ id: 'landing_btn_3' })} */}
              </button>
            </Link>
            <div className="moreInformation">
              <Link to="/learning">
                {intl.formatMessage({ id: 'click_for_more_information' })}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="landingMainImgWrapper">

        <div className="landingMainImgSec">

          <div className="landingMainRotatedImg" />

          {/* <div */}
          {/*  style={{ backgroundImage: 'url(/images/moment-cover-latest.jpg)' }} */}
          {/*  className="landingMainImg" */}
          {/* /> */}
          <VideoComponent />

          {/* <video className="landingMainVideo" autoPlay muted loop playsInline poster="/p4finalPoster.png"> */}
          {/*  <source src="https://moment-s3.s3.ir-thr-at1.arvanstorage.ir/public/static/p4final.mp4" type="video/mp4" /> */}
          {/* </video> */}

          {/* <LazyVideo */}
          {/*  className="landingMainVideo" */}
          {/*  src="https://moment-s3.s3.ir-thr-at1.arvanstorage.ir/public/static/p4final.mp4" */}
          {/*  poster="/p4finalPoster.png" */}
          {/*  autoPlay */}
          {/*  muted */}
          {/*  loop */}
          {/*  playsInline */}
          {/* /> */}

        </div>

      </div>

    </div>
  );
}

const VideoComponent = memo(() => {
  console.log('Greeting was rendered at', new Date().toLocaleTimeString());
  return (
    <video className="landingMainVideo" autoPlay muted loop playsInline poster="/p4finalPoster.png">
      <source src="https://moment-s3.s3.ir-thr-at1.arvanstorage.ir/public/static/p4final.mp4" type="video/mp4" />
    </video>
  );
});
