import React from 'react';
import '../../../assets/styles/general/General.scss';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectShowSharePopover } from '../../../redux/slices/accountSlice';

export default function SharePopover({
  // showPopover,
  twitterUrlHandler, telegramUrlHandler, shareOnInstagram,
  shareOnWhatsapp,
  copy,
}) {
  const intl = useIntl();
  const showPopover = useSelector(selectShowSharePopover);
  return (
    <div
      id="popover"
      className={showPopover ? 'sharePopover' : 'hiddenSharePopover'}
    >
      <div
        className="shareItem"
        onClick={copy}
      >
        <img src="/icons/copy.png" alt="" />
        <strong>
          {intl.formatMessage({ id: 'copy_link' })}
        </strong>
      </div>

      {/* <div className="shareItem" onClick={shareOnInstagram}> */}
      {/*  <img src="/icons/shareOnInstagram.svg" alt="" /> */}
      {/*  <strong> */}
      {/*    {intl.formatMessage({ id: 'share_on_instagram' })} */}

      {/*  </strong> */}
      {/* </div> */}
      <a
        href={telegramUrlHandler()}
        target="_blank"
        rel="noreferrer"
      >
        <div className="shareItem">
          <img src="/icons/profile/Telegram.svg" alt="" />
          <strong>
            {intl.formatMessage({ id: 'share_on_telegram' })}
          </strong>
        </div>
      </a>

      <a
        href={shareOnWhatsapp()}
        target="_blank"
        rel="noreferrer"
      >
        <div className="shareItem">
          <img src="/icons/whatsapp.svg" alt="" />
          <strong>
            {intl.formatMessage({ id: 'share_on_whatsapp' })}
          </strong>
        </div>
      </a>

      <a
        href={twitterUrlHandler()}
        target="_blank"
        rel="noreferrer"
      >
        <div className="lastShareItem">
          <img src="/icons/twitter.svg" alt="" />
          <strong>
            {intl.formatMessage({ id: 'share_on_twitter' })}
          </strong>
        </div>
      </a>

    </div>
  );
}
