import React, {useEffect, useState} from 'react';
import Fuse from 'fuse.js';
import {Accordion, FormCheck} from 'react-bootstrap';

import '../../../assets/styles/Explore/Explore.scss';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

function FilterAccordion({
                             categories, selectedTags, setSelectedTags, filteredCategories,
                             filterLoading,
                             setFilteredCategories,
                             selectedTagsWholeData,
                             setSelectedTagsWholeData,
                             setPageLoading,
                             setNftsLoading,
                             setFilters
                         }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchTerms, setSearchTerms] = useState({});
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tagIds = params.get('tag');
        if (tagIds) {
            setSelectedTags(tagIds.split(',').map((id) => parseInt(id, 10)));
        }
    }, [location.search]);

    const updateURLParams =async (tags, page) => {
        const params = new URLSearchParams(location.search);
        // console.log("parMSMSMS ", params.get("page"))
        if (tags.length > 0) {
            params.set('tag', tags.join(','));
        } else {
            params.delete('tag');
        }

        if (page) {
              params.set('page', page);
        } else {
            params.delete('page');
        }
        console.log("navigate in tag ", `${location.pathname}?${decodeURIComponent(params.toString())}`)
       await navigate(`${location.pathname}?${decodeURIComponent(params.toString())}`);
    };


    const handleSearchChange = (e, categoryKey) => {
        const searchTerm = e.target.value;

        const newSearchTerms = {...searchTerms, [categoryKey]: searchTerm};
        setSearchTerms(newSearchTerms);

        const newFilteredCategories = Object.keys(categories?.filterData).reduce((acc, key) => {
            const categoryTags = categories?.filterData[key];

            if (key === categoryKey) {
                if (searchTerm.trim() === '') {
                    acc[key] = categoryTags;
                } else {
                    const fuse = new Fuse(categoryTags, {
                        keys: ['name'],
                        threshold: 0.2,
                    });

                    // console.log(fuse.search(searchTerm).map((result) => result.item));

                    acc[key] = fuse.search(searchTerm).map((result) => result.item);
                }
            } else {
                acc[key] = categoryTags;
            }
            return acc;
        }, {});

        setFilteredCategories({
            filterData: newFilteredCategories,
        });

        // setFilteredCategories(newFilteredCategories);
    };

    //     const handleTagChange = (e, tagId) => {
    { /*        if (e.target.checked) { */
    }
    { /*            setSelectedTags([...selectedTags, tagId]); */
    }
    { /*            //   todo add tag to the url  search params */
    }
    { /* //             if (searchParams?.get('page') !== null) { */
    }
    { /* // // the page should invclude */
    }
    // //                 console.log(selectedTags)
    // //                 navigate(`/explore?page=${searchParams?.get('page')}&tag=${selectedTags.join(',')},${tagId}`);
    // //             } else {
    // //                 // there is no page to include
    // //                 navigate(`/explore?tag=${tagId}`);
    // //             }
    //
    //         } else {
    //             setSelectedTags(selectedTags.filter((id) => id !== tagId));
    //             //   todo delete tag from the url  search params
    //         }
    //     };


    const handleTagChange = async (checked, tagId, tag) => {
        setNftsLoading(true); // Set loading state to true

        let newSelectedTags;
        let newSelectedTagsName;

        if (checked) {
            newSelectedTags = [...selectedTags, tagId];
            newSelectedTagsName = [...selectedTagsWholeData, tag];
        } else {
            newSelectedTags = selectedTags.filter((id) => id !== tagId);
            newSelectedTagsName = selectedTagsWholeData.filter((id) => id !== tag);
        }

        // Update URL parameters with new selected tags and reset page to 1
        // await updateURLPageParams(1);

        console.log("newSelectedTags " , newSelectedTags)


        await updateURLParams(newSelectedTags, 1);

        setSelectedTags(newSelectedTags);
        setSelectedTagsWholeData(newSelectedTagsName);

        // Reset page to 1 in state first
        setFilters((prevState) => ({
            ...prevState,
            page: 1,
        }));

        // setLoading(false); // Set loading state to false
    };


    return (
        <div>
            <Accordion defaultActiveKey={['0']} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div className="filterAccordionHeader">
                            <div>
                                فیلترها

                            </div>
                            {/* <img src="/icons/arrowDown.svg" alt="" /> */}
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>

                        {
                            Object.keys(filteredCategories?.filterData)?.length > 0
                            && Object.keys(filteredCategories?.filterData).map((categoryKey) =>
                                // console.log('filteredCategories ', filteredCategories);
                                // console.log('categoryKey ', categoryKey);
                                // console.log('filteredCategories[categoryKey] ', filteredCategories?.filterData[categoryKey]);
                                (
                                    <div key={categoryKey}>
                                        <div className="categoryTitle">
                                            {categoryKey}
                                        </div>

                                        <div style={{position: 'relative'}}>
                                            <input
                                                className="form-control form-control-lg form-control-solid filterSearchInput"
                                                value={searchTerms[categoryKey] || ''}
                                                onChange={(e) => handleSearchChange(e, categoryKey)}
                                            />
                                            <img className="filterSearchIcon" src="/icons/blackSearch.svg" alt=""/>
                                        </div>

                                        {
                                            (!filterLoading && Object.keys(filteredCategories?.filterData).length > 0)
                                            && filteredCategories?.filterData[categoryKey]?.map((tag, key) => (
                                                <div className="tagRow" key={key}>
                                                    <FormCheck
                                                        aria-label="Checkbox for sell your NFT"
                                                        checked={selectedTags.includes(tag?.id)}
                                                        // onChange={(e) => handleTagChange(e, tag.id, tag)}
                                                        onChange={(e) => handleTagChange(e.target.checked, tag.id, tag)}
                                                    />

                                                    <div className="tagName" style={{fontWeight: 500}}
                                                         onClick={() => handleTagChange(!selectedTags.includes(tag?.id), tag.id, tag)}
                                                    >
                                                        {tag.name}
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                ))
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default FilterAccordion;
