import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import ToPersianNumber from '../../../helpers/toPersianNumber.jsx';
import addCommas from '../../../helpers/addCommas.jsx';
import { selectLanguage } from '../../../redux/slices/languageSlice';
import FixPriceModal from '../general/FixPrice/FixPriceModal.jsx';
import TransferWallet from '../general/ProfileCard/TransferWallet.jsx';
import CancelAttentionPopup from '../general/FixPrice/CancelAttentionPopup';
import FixPriceBuyModal from '../general/FixPrice/FixPriceBuyModal.jsx';
import { setShowLogin } from '../../../redux/slices/accountSlice';
import SellNFTAttentionModal from './SellNFTAttentionModal';
import SelectWalletForBuyingNFT from './SelectWalletForBuyingNFT';
import { setCheckIsUserCapableToBuy } from '../../../redux/slices/buySlice';
import Skeleton from '../general/Skeleton.jsx';
import api from '../../../api.js';
import getLocalStorage from '../../../helpers/getLocalStorage.js';

function ShowButtonsHandler({
  moment,
  setShowSellModal,
  setShowTransferModal,
  setShowCancelAttentionModal,
  setShowBuyModal,
  // setShowBuyModalAfterLogin,
  setShowSellNftAttention,
  setShowSelectWalletBuyingNFT,
  isInCentralWallet,
  editions,
                              selectedEdition
}) {
  const intl = useIntl();
  const languageRedux = useSelector(selectLanguage);
  const { id, editionId } = useParams();
  const [editionBuyLimits, setEditionBuyLimits] = useState(0);
  const dispatch = useDispatch();

  const storage = getLocalStorage();

  const buyBtnHandler = async () => {
    const accessToken = await storage.getItem('accessToken');
    if (accessToken) {
      if (moment?.type === 'single') {
        if (moment?.nft?.status === 'success' && !moment?.isOwner) {
          setShowSelectWalletBuyingNFT(true);
        }
        // if it is Moment(not NFT), we show user buy moment immediately
        else {
          setShowBuyModal(true);
        }
      } else {
        // const selectedEdition = editions?.find((item) => item?.id === parseInt(editionId, 10));

        if (selectedEdition?.nft?.status === 'success' && !selectedEdition?.isOwner) {
          setShowSelectWalletBuyingNFT(true);
        }
        // if it is NFT, we let user choose her wallet if she wants
        // if it is Moment(not NFT), we show user buy moment immediately
        else {
          setShowBuyModal(true);
        }
      }
    } else {
      dispatch(setShowLogin(true));
      // if (moment?.nft.status === 'success') {
      // setShowSelectWalletBuyingNFTAfterLogin(true);

      if (moment?.type === 'edition') {
        // const selectedEdition = editions?.find((item) => item?.id === parseInt(editionId, 10));
        dispatch(setCheckIsUserCapableToBuy({
          shouldCheckLater: true,
          theNftOwnerShouldBeChecked: selectedEdition,
          // type: moment?.type,
        }));
      } else {
        dispatch(setCheckIsUserCapableToBuy({
          shouldCheckLater: true,
          theNftOwnerShouldBeChecked: moment,
          // type: moment?.type,
        }));
      }

      // }
      // if it is Moment(not NFT), we show user buy moment immediately
      // else {
      //   setShowBuyModalAfterLogin(true);
      // }
    }
  };

  const sellBtnHandler = () => {
    if (moment?.type === 'edition') {
      // const selectedEdition = editions?.find((item) => item?.id === parseInt(editionId, 10));

      // if it is NFT , user should see the attention
      if (selectedEdition?.nft?.status === 'success') {
        setShowSellNftAttention(true);
      }
      // if it is moment ( not NFT) , user can sett the price and sell it
      else {
        setShowSellModal(true);
      }
    } else {
      // if it is NFT , user should see the attention
      if (moment?.nft?.status === 'success') {
        setShowSellNftAttention(true);
      }
      // if it is moment ( not NFT) , user can sett the price and sell it
      else {
        setShowSellModal(true);
      }
    }
  };

  if (moment?.type === 'single') {
    if (moment?.isOwner) {
      if (moment?.isForSale) {
        return (
          <>
            <div className="priceColumn">
              <strong className="currentPriceTitle">{intl.formatMessage({ id: 'current_price' })}</strong>
              <strong className="currentPrice">
                {moment?.listingPrice ? languageRedux === 'fa' ? ToPersianNumber(String(addCommas(moment?.listingPrice))) : String(addCommas(moment?.listingPrice)) : '-'}
                {' '}
                تومان
              </strong>
            </div>
            <div className="nftPageBtnSec">
              {/* if it's not onSale you can't buy it and  when it's onSale you can Buy it */}
              <button
                className="cancelBtn"
                type="submit"
                onClick={() => setShowCancelAttentionModal(true)}
              >
                {intl.formatMessage({ id: 'cancel_fix_price' })}
              </button>

            </div>
          </>
        );
      }
      if (!moment?.nft || moment?.nft?.status === 'not-set') {
        // it's just a moment that could be both transfer and onSale
        return (
          <>
            <button
              type="button"
              className="transferToWalletBtn"
              onClick={() => setShowTransferModal(true)}
            >
              <img src="/icons/sportCategory/wallet.svg" alt="" />
              {intl.formatMessage({ id: 'transfer_to_wallet' })}
            </button>

            <button type="button" className="sellBtn" onClick={sellBtnHandler}>
              {intl.formatMessage({ id: 'sell' })}
            </button>
          </>
        );
      }
      if (moment?.nft?.status === 'failed' || moment?.nft?.status === 'success') {
        // minting failed so it can be mint (retry minting) and also can be for sale
        return (
          <>
            {
                            isInCentralWallet
                            && (
                            <button
                              type="button"
                              className="transferToWalletBtn"
                              onClick={() => setShowTransferModal(true)}
                            >
                              <img src="/icons/sportCategory/wallet.svg" alt="" />
                              {intl.formatMessage({ id: 'transfer_to_wallet' })}
                            </button>
                            )
                        }

            <button
              type="button"
              className={isInCentralWallet ? 'sellBtn' : 'maxWidthSellBtn'}
              onClick={sellBtnHandler}
            >
              {intl.formatMessage({ id: 'sell' })}
            </button>
          </>
        );
      }
      if (moment?.nft?.status === 'pending') {
        // it's minting and can not be mint again , and also can not be for sale
        return (
          <>
            <button type="button" className="transferToWalletBtn" disabled>
              <img src="/icons/disabledTransferWallet.svg" alt="" />
              {intl.formatMessage({ id: 'transfer_to_wallet' })}
            </button>

            <button type="button" className="sellBtn" disabled>
              {intl.formatMessage({ id: 'sell' })}
            </button>
          </>
        );
      }
    } else {
      // I'm not owner of this moment and i can buy it whenever the owner set is For sale
      if (moment?.isForSale) {
        // i can buy this moment
        return (
          <>
            <div className="priceColumn">
              <strong className="currentPriceTitle">{intl.formatMessage({ id: 'current_price' })}</strong>
              <strong className="currentPrice">

                {
                                    moment?.isForSale
                                      ? moment?.listingPrice
                                        ? languageRedux === 'fa'
                                          ? ToPersianNumber(String(addCommas(moment?.listingPrice)))
                                          : String(addCommas(moment?.listingPrice)) : '-'
                                      : moment?.price
                                        ? languageRedux === 'fa'
                                          ? ToPersianNumber(String(addCommas(moment?.price)))
                                          : String(addCommas(moment?.price)) : '-'
                                }
                {' '}
                {moment?.currency?.symbol}
              </strong>
            </div>
            <div className="nftPageBtnSec">
              {/* if tr's not onSale you can't buy it and  when it's onSale you can Buy it */}
              <button
                disabled={!moment?.isForSale}
                className="buyBtn"
                type="submit"
                onClick={buyBtnHandler}
              >
                {/* {intl.formatMessage({ id: 'buy' })} */}
                از تیمت حمایت کن!
              </button>

            </div>
          </>
        );
      }
      return (
      //  i can not buy this moment because it's not for sale
        <div className="notForSaleInNFTPage">
          {intl.formatMessage({ id: 'its_not_for_sale' })}
        </div>
      );
    }
  }

  if (moment?.type === 'edition') {
    // const selectedEdition = editions?.find((item) => item?.id === parseInt(editionId, 10));
    if (selectedEdition?.isOwner) {
      if (selectedEdition?.isForSale) {
        return (
          <>
            <div className="priceColumn">
              <strong className="currentPriceTitle">{intl.formatMessage({ id: 'current_price' })}</strong>
              <strong className="currentPrice">
                {selectedEdition?.listingPrice ? languageRedux === 'fa' ? ToPersianNumber(String(addCommas(selectedEdition?.listingPrice))) : String(addCommas(selectedEdition?.listingPrice)) : '-'}
                {' '}
                تومان
              </strong>
            </div>
            <div className="nftPageBtnSec">
              {/* if it's not onSale you can't buy it and  when it's onSale you can Buy it */}
              <button
                className="cancelBtn"
                type="submit"
                onClick={() => setShowCancelAttentionModal(true)}
              >
                {intl.formatMessage({ id: 'cancel_fix_price' })}
              </button>

            </div>
          </>
        );
      }
      if (!selectedEdition?.nft || selectedEdition?.nft?.status === 'not-set') {
        // it's just a moment that could be both transfer and onSale
        return (
          <>
            <button
              type="button"
              className="transferToWalletBtn"
              onClick={() => setShowTransferModal(true)}
            >
              <img src="/icons/sportCategory/wallet.svg" alt="" />
              {intl.formatMessage({ id: 'transfer_to_wallet' })}
            </button>

            <button type="button" className="sellBtn" onClick={sellBtnHandler}>
              {intl.formatMessage({ id: 'sell' })}
            </button>
          </>
        );
      }
      if (selectedEdition?.nft?.status === 'failed' || selectedEdition?.nft?.status === 'success') {
        // minting failed so it can be mint (retry minting) and also can not be for sale
        return (
          <>
            {
                            isInCentralWallet
                            && (
                            <button
                              type="button"
                              className="transferToWalletBtn"
                              onClick={() => setShowTransferModal(true)}
                            >
                              <img src="/icons/sportCategory/wallet.svg" alt="" />
                              {intl.formatMessage({ id: 'transfer_to_wallet' })}
                            </button>
                            )
                        }

            <button
              type="button"
              className={isInCentralWallet ? 'sellBtn' : 'maxWidthSellBtn'}
              onClick={sellBtnHandler}
            >
              {intl.formatMessage({ id: 'sell' })}
            </button>
          </>
        );
      }
      if (selectedEdition?.nft?.status === 'pending') {
        // it's minting and can not be mint again , and also can not be for sale
        // it has been minted  and also can not be for sale
        return (
          <>
            <button type="button" className="transferToWalletBtn" disabled>
              <img src="/icons/disabledTransferWallet.svg" alt="" />
              {intl.formatMessage({ id: 'transfer_to_wallet' })}
            </button>

            <button type="button" className="sellBtn" disabled>
              {intl.formatMessage({ id: 'sell' })}
            </button>
          </>
        );
      }
    } else {
      // I'm not owner of this moment and i can buy it whenever the owner set is For sale
      if (selectedEdition?.isForSale) {
        // user can buy it
        return (
          <>
            <div className="priceColumn">
              <strong className="currentPriceTitle">{intl.formatMessage({ id: 'current_price' })}</strong>
              <strong className="currentPrice">
                {
                                     selectedEdition?.listingPrice
                                       ? languageRedux === 'fa'
                                         ? ToPersianNumber(String(addCommas(selectedEdition?.listingPrice)))
                                         : String(addCommas(selectedEdition?.listingPrice)) : '-'
                  }
                {/* {languageRedux === 'fa' ? ToPersianNumber(String(addCommas((campaignIntroduction.price / (editionBuyLimits + 1))))) */}
                {/*    : String(addCommas((campaignIntroduction.price / (editionBuyLimits + 1))))} */}
                {' '}
                {moment?.currency?.symbol}
              </strong>
            </div>
            <div className="nftPageBtnSec">
              {/* if tr's not onSale you can't buy it and  when it's onSale you can Buy it */}
              <button
                disabled={!selectedEdition?.isForSale}
                className="buyBtn"
                type="submit"
                onClick={buyBtnHandler}
              >
                {/* {intl.formatMessage({ id: 'buy' })} */}
                از تیمت حمایت کن!
              </button>

            </div>
          </>
        );
      }

      return (
        <div className="notForSaleInNFTPage">
          {intl.formatMessage({ id: 'its_not_for_sale' })}
        </div>
      );
    }
  }
}

function ShowMessageHandler({ moment, editions, selectedEdition }) {
  const { id, editionId } = useParams();
  const intl = useIntl();

  // const selectedEdition = editions?.find((item) => item?.id === parseInt(editionId, 10));

  if (selectedEdition ? selectedEdition?.isOwner : moment?.isOwner) {
    if (moment?.nft?.status === 'pending' || selectedEdition?.nft?.status === 'pending') {
      return (
        <div className="pendingText">
          {intl.formatMessage({ id: 'its_pending_txt' })}
          ...
        </div>
      );
    }
    if (moment?.nft?.status === 'failed' || selectedEdition?.nft?.status === 'failed') {
      return (
        <div className="failedText">
          {intl.formatMessage({ id: 'its_failed_txt' })}
          ...
        </div>
      );
    }
  }
}

export default function ActionsButtons(props) {
  const {
    moment, setIsMomentStatusChanged, isTransferTokenLoading, setIsTransferTokenLoading,
    transfers,
    editions,
      selectedEdition,
      setSelectedEdition
  } = props;
  const intl = useIntl();
  const { id, editionId } = useParams();

  const [showSellModal, setShowSellModal] = useState(false);

  const [showSellNftAttention, setShowSellNftAttention] = useState(false);

  const [showSelectWalletBuyingNFT, setShowSelectWalletBuyingNFT] = useState(false);

  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showCancelAttentionModal, setShowCancelAttentionModal] = useState(false);

  const [showBuyModal, setShowBuyModal] = useState(false);
  // const [showBuyModalAfterLogin, setShowBuyModalAfterLogin] = useState(false);

  const [selectedWallet, setSelectedWallet] = useState({});

  // const [selectedEdition, setSelectedEdition] = useState({});

  const [isCheckingTheNFTLocation, setIsCheckingTheNFTLocation] = useState(false);

  const [isInCentralWallet, setIsInCentralWallet] = useState(false);

  useEffect(() => {
    if (moment) {
      // setSelectedEdition(editions?.find((item) => item?.id === parseInt(editionId, 10)));
      // const selectedEditionVariable = editions?.find((item) => item?.id === parseInt(editionId, 10));

      if (moment?.type === 'edition') {
        if (selectedEdition?.nft?.status === 'success' && selectedEdition?.isOwner) {
          setIsCheckingTheNFTLocation(true);
          api.get(`moments/central-wallet/${selectedEdition?.nft?.id}`).then(({ data }) => {
            setIsInCentralWallet(data?.isInCentralWallet);

            setIsCheckingTheNFTLocation(false);
          }).catch(({ response }) => {
            setIsCheckingTheNFTLocation(false);
            console.log(response);
          });
        }
      } else if (moment?.nft?.status === 'success' && moment?.isOwner) {
        setIsCheckingTheNFTLocation(true);
        api.get(`moments/central-wallet/${moment?.nft?.id}`).then(({ data }) => {
          setIsInCentralWallet(data?.isInCentralWallet);

          setIsCheckingTheNFTLocation(false);
        }).catch(({ response }) => {
          setIsCheckingTheNFTLocation(false);
          console.log(response);
        });
      }
    }
  }, [moment]);

  return (
    <div className="mb-6">
      <div className="priceSec">

        {
                    isCheckingTheNFTLocation
                      ? (
                        <>
                          <Skeleton className="transferToWalletBtn" />
                          <Skeleton className="sellBtn" />
                        </>
                      )
                      : (
                        <ShowButtonsHandler
                          moment={moment}
                          setShowSellModal={setShowSellModal}
                          setShowTransferModal={setShowTransferModal}
                          setShowCancelAttentionModal={setShowCancelAttentionModal}
                          setShowBuyModal={setShowBuyModal}
                                // setShowBuyModalAfterLogin={setShowBuyModalAfterLogin}
                          setShowSellNftAttention={setShowSellNftAttention}
                          setShowSelectWalletBuyingNFT={setShowSelectWalletBuyingNFT}
                          isInCentralWallet={isInCentralWallet}
                          editions={editions}
                          selectedEdition={selectedEdition}
                        />
                      )

                }

        <FixPriceModal
          show={showSellModal}
          setShow={setShowSellModal}
          selectedMoment={moment}
          setIsMomentStatusChanged={setIsMomentStatusChanged}
          isTransferTokenLoading={isTransferTokenLoading}
          setIsTransferTokenLoading={setIsTransferTokenLoading}
          selectedEdition={selectedEdition}
          setSelectedEdition={setSelectedEdition}
        />

        <TransferWallet
          show={showTransferModal}
          setShow={setShowTransferModal}
          momentId={moment?.type === 'edition' ? selectedEdition?.id : moment?.id}
          setIsMomentStatusChanged={setIsMomentStatusChanged}
          moment={moment}
          selectedEdition={selectedEdition}
          setSelectedEdition={setSelectedEdition}
        />

        <CancelAttentionPopup
          show={showCancelAttentionModal}
          setShow={setShowCancelAttentionModal}
          moment={moment}
                    // momentId={moment?.type === 'edition' ? selectedEdition?.id : moment?.id}
          setIsMomentStatusChanged={setIsMomentStatusChanged}
        />

        <SellNFTAttentionModal
          show={showSellNftAttention}
          setShow={setShowSellNftAttention}
          setShowSellModal={setShowSellModal}
          transfers={transfers?.transfers}
        />

        <SelectWalletForBuyingNFT
          setShow={setShowSelectWalletBuyingNFT}
          show={showSelectWalletBuyingNFT}
          setSelectedWallet={setSelectedWallet}
          selectedWallet={selectedWallet}
          setShowBuyModal={setShowBuyModal}
        />

        <FixPriceBuyModal
          show={showBuyModal}
                    // showAfterLogin={showBuyModalAfterLogin}
                    // setShowBuyModalAfterLogin={setShowBuyModalAfterLogin}
          setShow={setShowBuyModal}
          setIsMomentStatusChanged={setIsMomentStatusChanged}
          selectedMoment={moment}
          selectedWallet={selectedWallet}
          selectedEdition={selectedEdition}
        />

      </div>

      <ShowMessageHandler moment={moment} editions={editions} selectedEdition={selectedEdition} />

    </div>
  );
}
